import React, { useState, useEffect } from "react";
// import { subWeeks, format, parseISO, isWithinInterval } from "date-fns";
import {
  CTable,
  CTableBody,
  CTableHead,
  CTableRow,
  CTableHeaderCell,
  CTableDataCell,
} from "@coreui/react";
import { supabase } from "../client";
import Lottie from "react-lottie";
import Loading from "../public/lottie/Loading.json";

const AdminDashboard = () => {
  const [assetData, setAssetData] = useState([]);
  // const [moodboardData, setMoodboardData] = useState([]);
  // const [assetIndex, setAssetIndex] = useState(0);
  // const [moodboardIndex, setMoodboardIndex] = useState(0);
  // const [visible, setVisible] = useState(false);

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: Loading,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  // const formatDate = (date) => {
  //   const year = date.getFullYear();
  //   const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Adding 1 because getMonth returns 0-indexed month
  //   const day = date.getDate().toString().padStart(2, "0");
  //   return `${year}-${month}-${day}`;
  // };
  // const [fromDate, setFromDate] = useState(formatDate(new Date()));
  // const [toDate, setToDate] = useState(formatDate(new Date()));

  const user = localStorage.getItem("user");

  useEffect(() => {
    const assetCount = async () => {
      const { data } = await supabase
        .from("asset_data")
        .select(
          "modelStatus, modellerName, created_at, blender_files, difficultyLevel, modellerID",
          { count: "exact" }
        );
      console.log(data);

      const modelerData = data.reduce((accData, item) => {
        if(item.modellerID !== null){
          const modelerName = item.modellerName;
          const modelStatus = item.modelStatus;
          if (!accData[modelerName]) {
            accData[modelerName] = {};
          }
          if (!accData[modelerName][modelStatus]) {
            accData[modelerName][modelStatus] = 0;
          }
          accData[modelerName][modelStatus]++;
        }
        return accData;
      }, {});
      const modelerArray = Object.keys(modelerData).map((key) => ({
        name: key,
        data: modelerData[key],
      }));

      setAssetData(modelerArray);
    };
    assetCount();
  }, [user]);
  console.log(assetData);

  // const COLORS = [
  //   "#BBC0B1",
  //   "#D89DA4",
  //   "#D9EFF5",
  //   "#F2D399",
  //   "#33C7FF",
  //   "#D89DA4",
  //   "#D9EFF5",
  // ];

  return (
    <>
      {assetData && assetData.length !== 0 ? (
        <div className="flex flex-col p-4">
          <div className="flex flex-row gap-4">
            <CTable bordered className="text-white text-center rounded">
              <CTableHead>
                <CTableRow color="secondary">
                  <CTableHeaderCell scope="col">Name</CTableHeaderCell>
                  <CTableHeaderCell scope="col">AR Pass</CTableHeaderCell>
                  <CTableHeaderCell scope="col">AR Ready</CTableHeaderCell>
                  <CTableHeaderCell scope="col">AR Fail</CTableHeaderCell>
                  <CTableHeaderCell scope="col">
                    Submitted for QC
                  </CTableHeaderCell>
                  <CTableHeaderCell scope="col">
                    Conversion Fail
                  </CTableHeaderCell>
                  <CTableHeaderCell scope="col">Assigned</CTableHeaderCell>
                </CTableRow>
              </CTableHead>
              <CTableBody>
                {assetData.map((user, index) => (
                  <CTableRow>
                    <CTableHeaderCell className="capitalize" scope="row">
                      {user.name}
                    </CTableHeaderCell>
                    <CTableDataCell>{user.data?.ar_pass || 0}</CTableDataCell>
                    <CTableDataCell>{user.data?.ar_ready || 0}</CTableDataCell>
                    <CTableDataCell>{user.data?.ar_fail || 0}</CTableDataCell>
                    <CTableDataCell>
                      {user.data?.submitted_for_qc || 0}
                    </CTableDataCell>
                    <CTableDataCell>
                      {user.data?.conversion_fail || 0}
                    </CTableDataCell>
                    <CTableDataCell>{user.data?.assigned || 0}</CTableDataCell>
                  </CTableRow>
                ))}
              </CTableBody>
            </CTable>
          </div>
        </div>
      ) : (
        <div className="flex w-full h-auto justify-center items-center">
          <Lottie
            width={300}
            height={200}
            style={{ height: "400px" }}
            options={defaultOptions}
          />
        </div>
      )}
    </>
  );
};

export default AdminDashboard;
