import React from "react";
import { supabase } from "../client";
import { Button } from "react-bootstrap";
import { v4 as uuidv4 } from "uuid";

function QcPass(props) {
  const fileID = props.fileId;
  const files = props.files;
  const id = props.id;
  const modellerID = props.modellerid
  const pixelid = props.pixelid;
  const address = JSON.parse(localStorage.getItem('address'))

  const pass = async () => {
    const modifiedfile = files.find((file) => file.id === fileID);
    if (modifiedfile) {
      modifiedfile["status"] = "ar_pass";

      const key = modifiedfile["glbLink"].replace(
        `https://${process.env.REACT_APP_BLENDER_BUCKET}.s3.ap-south-1.amazonaws.com/`,
        ""
      );
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/movemodel/${pixelid}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ s3key: key }),
        }
      );
      await supabase
        .from("asset_data")
        .update({
          blender_files: files,
          modelStatus: "ar_pass",
          glbLink: `https://${process.env.REACT_APP_PRODUCTION_BUCKET}.s3.ap-south-1.amazonaws.com/${pixelid}.glb`,
        })
        .match({ id: id });

      // const add = await fetch("https://geolocation-db.com/json/", {
      //   method: "GET",
      // });
      // const resp = await add.json();
      // console.log(resp.IPv4 , resp.city)

      await supabase.from("logs").insert({
        id: uuidv4(),
        modellerId: modellerID,
        productId: id,
        action: "ar_pass",
        ipAdd: address.IPv4,
        location: address.city,
      });
      window.location.reload(false);
    }
  };

  return (
    <Button
      onClick={pass}
      style={{
        width: "120px",
        height: "30px",
        fontWeight: "700",
        color: "black",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        borderRadius: "50vh",
        backgroundColor: "#BBC0B1",
        borderColor: "#7B8569",
        borderWidth: "2px",
      }}
    >
      QC PASS
    </Button>
  );
}

export default QcPass;
