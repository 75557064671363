import React, { useState, useEffect } from "react";
import Delete from "../public/lottie/delete.svg";
import { supabase, supabaseAdmin } from "../client";
import { Modal } from "react-bootstrap";
import { Toast, ToastContainer } from "react-bootstrap";

const UserCard = ({ data, setData }) => {
  const [modal, setModal] = useState(false);
  const [selectedId, setSelectedId] = useState(null);
  const [toast, setToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("")
  const [toastTimeout, setToastTimeout] = useState(null)

  const handleDelete = async () => {
    try{
        if(selectedId){
          console.log(selectedId);
          try{
            const { data, error } = await supabase.from("users").update({account_status:"suspended"}).eq("id", selectedId);
            if (error) {
              console.error(error.message);
            } else {
              console.log("User Suspended successfully");
              setModal(false);
              setToast(true)
              setToastMessage("User Suspended Successfully")
            }
          } catch(error){
            console.error(error)
          }
          const {data: updatedData} = await supabase.from("users").select("*")
          setData(updatedData)
        }
    } catch(error){
        console.error(error);
    }
  };

  useEffect(() => {
    if(toast){
      const timeout = setTimeout(() => {
        setToast(false)
      }, 2500)
      setToastTimeout(timeout)
    }
    return () => {
      if(toastTimeout){
        clearTimeout(toastTimeout)
      }
    }
  },[toast])

  return (
    <>
      <ToastContainer position="top-center" style={{zIndex:1}} className="p-2">
        <Toast show={toast} delay={2000} animation={true}>
          <Toast.Body className="text-white bg-neutral-800 font-medium rounded-sm">{toastMessage}</Toast.Body>
        </Toast>
      </ToastContainer>
      <div className="grid grid-flow-row grid-cols-4 gap-6 py-8">
        {data &&
          data.map((user, index) => (
            <div
              key={index}
              className="flex flex-col bg-neutral-700 px-4 pb-8 pt-2 rounded-md gap-3 justify-center items-center"
            >
              <div className="flex w-full p-1 justify-between items-center">
                {user && user.account_status ==="created" && (
                  <div className="flex px-2 py-0.5 rounded-lg border-2 border-amber-400 text-amber-400 capitalize font-semibold">{user.account_status}</div>
                )}
                {user && user.account_status ==="active" && (
                  <div className="flex px-2 py-0.5 rounded-lg border-2 border-emerald-400 text-emerald-400 capitalize font-semibold">{user.account_status}</div>
                )}
                {user && user.account_status ==="suspended" && (
                  <div className="flex px-2 py-0.5 rounded-lg border-2 border-red-400 text-red-400 capitalize font-semibold">{user.account_status}</div>
                )}
                <img
                  className="w-8 h-8 hover:bg-neutral-800 rounded-md p-1 cursor-pointer"
                  onClick={() => {
                    setModal(true)
                    setSelectedId(user?.id)
                  }}
                  src={Delete}
                  alt=""
                />
              </div>
              <img
                className="w-36 h-36 bg-white rounded-full object-cover"
                src={user?.profile_image}
                alt=""
              />
              <div className="capitalize">{user.name}</div>
              <div>{user.email}</div>
              <div className="capitalize">{user.role}</div>
            </div>
          ))}
      </div>
      <Modal centered show={modal} size="md">
        <Modal.Body className="flex flex-col gap-2 rounded-md bg-neutral-800 text-white w-full p-2 justify-center items-center">
          <div className=" flex flex-row w-full justify-end">
            <img
              className="flex z-20 cursor-pointer"
              onClick={() => setModal(false)}
              src="https://dev-monkxperience.s3.ap-south-1.amazonaws.com/eliya/close.svg"
              alt=""
            />
          </div>
          <div className="flex text-2xl p-2">
            Are you sure to delete the user?
          </div>
          <div className="flex flex-row w-full p-4 justify-around">
            <button onClick={handleDelete} className="flex px-4 py-2 rounded-full bg-neutral-900 hover:bg-green-700">
              Confirm
            </button>
            <button onClick={() => setModal(false)} className="flex px-4 py-2 rounded-full bg-neutral-900 hover:bg-red-700">
              Cancel
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default UserCard;
