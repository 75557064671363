import React, { useState, useEffect } from "react";
import Sidebar from "../../layouts/sidebar";
import Topbar from "../../layouts/topbar";
import Search from "../../public/lottie/chevron_right.svg";
import { supabase } from "../../client";
import Pagination from "../../layouts/pagination";
import { Select, Option } from "@material-tailwind/react";
import { v4 as uuidv4 } from "uuid";

const Texture_Artist = ({history}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [count, setCount] = useState(null);
  const [textures, setTextures] = useState(null);
  // const [show, setShow] = useState("NO REQUEST");
  const [selected, setSelected] = useState("New");
  const [temp, setTemp] = useState(null);
  const objectsPerPage = 9;
  const startIndex = (currentPage - 1) * objectsPerPage;
  const user = localStorage.getItem("user");
  const role = localStorage.getItem("role");
  const username = localStorage.getItem("username");
  const endIndex = startIndex + objectsPerPage;
  const totalPages = Math.ceil(count / objectsPerPage);
  //   console.log(startIndex, endIndex);

  const options = [
    { value: "New", label: "New" },
    { value: "My Jobs", label: "My Jobs" },
    { value: "Update", label: "Update" },
    { value: "Archive", label: "Archive" },
  ];

  const [selectedFiles, setSelectedFiles] = useState([]);
  const [selectedFiles2, setSelectedFiles2] = useState([]);

  const handleFileChange = (event) => {
    const files = Array.from(event.target.files);
    setSelectedFiles(files);
  };
  const handleFileChange2 = (event) => {
    const files2 = Array.from(event.target.files);
    setSelectedFiles2(files2);
  };

  useEffect(() => {
    if (user && (role === "Texture-Artist" || role === "Visualizer")) {
      const expiry = localStorage.getItem("expires_at");
      const now = new Date().getTime() / 1000;
      if (now > expiry) {
        alert("Session timed Out");
        localStorage.clear();
        history.push("/login");
      }
    } else {
      alert("You are not authorized");
      history.push("/login");
    }
  }, [history]);

  const handleUpload = async (id, name, temp) => {
    const data = temp;
    // console.log(temp)
    const formData = new FormData();
    formData.append("name", name);
    formData.append("id", id);
    // console.log(id)
    selectedFiles.forEach((file) => {
      formData.append("file", file);
      // console.log(file);
    });
    selectedFiles2.forEach((file) => {
      formData.append("file2", file);
      // console.log(file);
    });
    const response = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/assetupload`,
      {
        method: "POST",
        body: formData,
      }
    );
    const result = await response.json();
    if (result["message"] === "Model uploaded successfully") {
      if (data.status !== "update") {
        const newId = uuidv4();
        await supabase.from("textureAssets").insert({
          id: newId,
          textureName: data.name,
          textureImage: result["filenames"],
          textureLink: result["asset_link"],
          textureArtist: user,
          tags: [data.material],
          assetType: "Texture",
          // material: data.material,
        });
        await supabase
          .from("textureRequests")
          .update({
            textureID: newId,
            status: "done",
            completed_at: new Date().toISOString(),
          })
          .eq("id", id);
      } else {
        // const exis_data = await supabase.from("textureAssets").select("*").eq("id",data.textureID)
        // console.log(exis_data.data)
        await supabase
          .from("textureAssets")
          .update({
            textureImage: result["filenames"],
            textureLink: result["asset_link"],
          })
          .eq("id", data.textureID);
        await supabase
          .from("textureRequests")
          .update({
            status: "done",
            completed_at: new Date().toISOString(),
          })
          .eq("id", id);
      }
      alert("Asset submitted successfully");
      // window.location.reload(false);
    }
  };

  const fetchTextures = async (selected) => {
    if (selected == "New") {
      const data = await supabase
        .from("textureRequests")
        .select("*")
        .eq("status", "requested");
      // .range(0, endIndex - 1);
      setTextures(data.data);
    } else if (selected === "Update") {
      const data = await supabase
        .from("textureRequests")
        .select("*")
        .eq("status", "update")
        .eq("textureArtistID", user);
      // .range(0, endIndex - 1);
      setTextures(data.data);
    } else if (selected === "My Jobs") {
      const data = await supabase
        .from("textureRequests")
        .select("*")
        .eq("status", "wip")
        .eq("textureArtistID", user);
      // .range(0, endIndex - 1);
      setTextures(data.data);
    } else if (selected === "Archive") {
      const data = await supabase
        .from("textureRequests")
        .select("*")
        .eq("status", "done")
        .eq("textureArtistID", user);
      // .range(0, endIndex - 1);
      setTextures(data.data);
    }
  };
  // console.log(textures);
  useEffect(() => {
    fetchTextures(selected);
  }, [selected]);
  const handleToggle = (options) => {
    setSelected(options);
    setTemp(null);
  };
  const [comment, setComment] = useState("");
  const handleCommentChange = (event) => {
    setComment(event.target.value);
  };
  // const handleRequest = async () => {
  //   setShow("REQUEST");
  // };
  // const handlePageChange = async (pageNumber) => {
  //   setCurrentPage(pageNumber);
  //   const data = await supabase
  //     .from("textureRequests")
  //     .select("*")
  //     .range(
  //       (pageNumber - 1) * objectsPerPage,
  //       pageNumber * objectsPerPage - 1
  //     );
  //   setTextures(data.data);
  // };
  // console.log(show);
  // useEffect(() => {
  //   (async () => {
  //     const count = await supabase
  //       .from("textureRequests")
  //       .select("*", { count: "exact" });
  //     setCount(count.count);
  //     await fetchTextures();
  //   })();
  // }, []);

  const [chat,setChat] = useState(null)
  const details = async (texture) => {
    // setShow("NO REQUEST");
    setTemp(texture);
    setSelectedFiles([]);
    setSelectedFiles2([]);
    setComment("");
    setChat(texture.chat)
  };
  const sendComments = async (id) => {
    const { data, error } = await supabase
      .from("textureRequests")
      .select("chat")
      .eq("id", id);
    if (data) {
      const prev = data[0].chat || [];
      const next = {
        [username]: comment,
      };
      const update = [...prev, next];
      await supabase
        .from("textureRequests")
        .update({
          chat: update,
        })
        .eq("id", id);
      setComment("");
      setChat(update)
    }
  };
  const handleClick = async (id) => {
    const data1 = await supabase
      .from("textureRequests")
      .select("*")
      .eq("id", id);
    if (data1) {
      if (data1.data[0].status === "requested") {
        const { data, error } = await supabase
          .from("textureRequests")
          .update({
            status: "wip",
            textureArtistID: user,
          })
          .eq("id", id);
        fetchTextures("New");
      } else {
        alert("Sorry texture has been picked");
        window.location.reload(false);
      }
    }
  };

  return (
    <div className="flex flex-row bg-neutral-900 xl:overflow-hidden lg:overflow-hidden md:overflow-hidden ">
      <div className="flex flex-row 2xl:w-screen 2xl:h-screen xl:h-screen lg:w-screen lg:h-screen md:w-screen md:h-screen xl:w-screen">
        <Sidebar />
        <div className="w-screen h-screen overflow-auto scrollbar-hide">
          <Topbar />
          <div className="flex flex-row gap-2">
            <div className="flex flex-col gap-4 w-full pl-4">
              <div className="flex flex-row px-4 gap-8 bg-neutral-500 rounded-full w-max">
                {options.map((option) => (
                  <button
                    key={option.value}
                    className={`${
                      option.value === selected
                        ? "text-teal-500 text-xl"
                        : "text-black"
                    }`}
                    onClick={() => handleToggle(option.value)}
                  >
                    {option.label}
                  </button>
                ))}
              </div>
              <div className="flex flex-row w-full gap-2 px-1 py-2 justify-around">
                <div className="flex flex-row 2xl:w-3/4 xl:2/3">
                  <div className="grid grid-flow-row gap-y-8 grid-cols-1 w-full h-max">
                    {textures?.map((texture) => (
                      <div className="flex flex-row h-max gap-4 text-white px-2 py-2 items-center bg-neutral-700">
                        <div className="flex flex-row gap-5 2xl:w-2/3 xl:w-1/2 2xl:justify-center items-center">
                          <div className="2xl:w-36 h-20" onClick={() => details(texture)}>
                            <img
                              className="2xl:w-36 xl:w-28 h-20"
                              src={texture.reffImages[0]}
                              alt=""
                            />
                          </div>
                          <div
                            className="flex 2xl:w-max justify-center text-white text-xl"
                            onClick={() => details(texture)}
                          >
                            {texture.name}
                          </div>
                          <div
                            className="flex font-thin flex-wrap 2xl:w-full tracking-wide"
                            onClick={() => details(texture)}
                          >
                            {texture.description}
                          </div>
                        </div>
                        <div className="flex flex-row gap-4 2xl:w-1/3 xl:w-1/2 justify-around items-center">
                          <div className="flex underline text-lg">{texture.created_at.slice(0,10)}</div>
                          <div className="flex rounded-full">
                            <img
                              className="flex rounded-full w-10 h-10"
                              src=""
                              alt=""
                            />
                          </div>
                          {texture.status === "requested" ? (
                            <div className="w-36 items-center">
                              <button
                                style={{ zIndex: "10" }}
                                onClick={() => handleClick(texture.id)}
                                id="countries"
                                class="w-28 text-black justify-center bg-green-700 items-center text-lg rounded-full flex px-1 py-1"
                              >
                                Assign
                                {/* <option selected>Status</option>
                              <option value="Done">Done</option>
                              <option value="Wip">WIP</option>
                              <option value="Error">Error</option> */}
                              </button>
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
                <div className="flex 2xl:w-1/4 xl:w-1/3">
                  <div className="w-full h-max pb-8 text-white rounded-md">
                    <div className="flex flex-col gap-2 pr-2">
                      <div className="flex flex-row w-auto gap-2 px-3 py-2 rounded-md bg-neutral-600">
                        <div className="flex w-1/2">
                          <img
                            className="w-44 h-44"
                            src={temp?.reffImages[0]}
                            alt=""
                          />
                        </div>
                        <div className="flex flex-col gap-2 w-1/2">
                          <div className="flex flex-col">
                            <div className="font-thin">Name :</div>
                            <div className="text-xl">{temp?.name}</div>
                          </div>
                          <div className="flex flex-col w-full">
                            <div className="font-thin">Modeler Name :</div>
                            <div className="text-xl">Akash</div>
                          </div>
                          <div className="flex flex-col">
                            <div className="font-thin">Date :</div>
                            <div className="text-xl">{temp?.created_at.slice(0,10)}</div>
                          </div>
                        </div>
                      </div>
                      <div className="flex flex-col gap-2 text-lg">
                        <div>Description :</div>
                        <div className="bg-white text-black h-16 w-full">
                          {" "}
                          {temp?.description}
                        </div>
                        {/* <textarea
                            className="bg-white text-black h-16 w-full"
                            type="text"
                            placeholder="Add a Description"
                          /> */}
                      </div>
                      {(selected === "My Jobs" || selected === "Update") &&
                      temp ? (
                        <>
                          <div className="flex flex-col text-lg gap-10">
                            <div className="flex flex-col gap-2">
                              <div className="flex text-white">Comment :</div>
                              <div className="flex flex-row relative gap-1">
                                <input
                                  className="text-white w-full bg-zinc-700 focus:outline-none py-3"
                                  placeholder="Add a Comment"
                                  onChange={handleCommentChange}
                                  value={comment}
                                />
                                <button
                                  className="absolute inset-y-0 right-0 font-semibold text-white px-4 flex items-center pr-2"
                                  onClick={() => sendComments(temp?.id)}
                                >
                                  Send
                                </button>
                              </div>
                              <div  className="flex flex-col h-[200px] overflow-auto ">

                              {chat && chat.length > 0
                              ?chat.map((ct) => (
                                  <>
                                    <div className="flex flex-row gap-2 items-center font-thin text-md">
                                      <div>
                                        <img
                                          className="w-10 h-10"
                                          src=""
                                          alt=""
                                        />
                                      </div>
                                      <div className="flex flex-col gap-1">
                                        <div className="flex flex-row gap-2">
                                          <div>{Object.keys(ct)}</div>
                                          {/* <div>5 hours ago</div> */}
                                        </div>
                                        <div className="flex flex-row gap-2">
                                          <div className="bg-neutral-700 px-2 rounded-full">
                                            {/* @nelson */}
                                          </div>
                                          <div>{ct[Object.keys(ct)]}</div>
                                        </div>
                                      </div>
                                    </div>
                                  </>
                                ))
                                : ""}
                                </div>
                            </div>
                          </div>
                          <div className="flex flex-col gap-2 px-2 py-2 bg-neutral-700 rounded-lg">
                            <div className="flex flex-col gap-2 h-max">
                              <div className="w-full border-1 bg-neutral-800 border-dashed rounded-lg">
                                <input
                                  class="w-full h-max text-md rounded-lg cursor-pointer"
                                  id="multiple_files"
                                  type="file"
                                  multiple
                                  onChange={handleFileChange}
                                />
                              </div>
                              {(selectedFiles2.length === 0)?(<div class="flex items-center justify-center w-full">
                                <label
                                  for="dropzone-file"
                                  class="flex flex-col items-center justify-center w-full h-16 border-1 border-dashed rounded-lg cursor-pointer"
                                >
                                  <div class="flex flex-col items-center justify-center pt-5 pb-6">
                                    <p class="mb-2 text-md">
                                      <span class="font-semibold">
                                        Drag and drop
                                      </span>
                                    </p>
                                  </div>
                                  <input
                                    id="dropzone-file"
                                    type="file"
                                    class="hidden"
                                    onChange={handleFileChange2}
                                  />
                                </label>
                              </div>
                            ):(<div className="flex flex-col gap-2 px-2 py-2 bg-green-500 rounded-lg">File Submitted </div>)}
                            </div>
                            <div className="flex flex-col gap-1">
                              <div>Material :</div>
                              <textarea className="flex rounded-md focus:outline-none  text-black items-center"></textarea>
                            </div>
                            <div className="flex flex-row w-full items-center">
                              {/* <div className="flex flex-col gap-1">
                                <div className="text-md">Date :</div>
                                <input
                                  className="w-36 bg-white text-black items-center"
                                  type="date" />
                              </div> */}
                              <div className="flex w-full items-center justify-end">
                                <button
                                  className="px-4 text-xl text-black font-semibold rounded-full bg-assignBorder border-2 border-submitBorder"
                                  onClick={() =>
                                    handleUpload(temp?.id, temp?.name, temp)
                                  }
                                >
                                  Submit
                                </button>
                              </div>
                            </div>
                          </div>
                        </>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Texture_Artist;
