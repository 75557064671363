import React, { useState } from "react";
import {BrowserRouter, Switch, Route, Redirect} from 'react-router-dom';
import './App.css';
import  Login  from "./pages/login";
import ForgotPassword from "./pages/forgotPassword";
import Dashboard from "./pages/home";
import ModelsGrab from "./pages/modelsGrab";
import Mymodals from "./pages/mymodals";
import ProductView from "./pages/productView";
import MyModalProductView from "./pages/myModalProductView";
import RoomView from "./pages/roomView";
import RoomViewDetail from "./pages/roomViewDetail";
import Assets from "./pages/assets";
import AssetsView from "./pages/assetsView";
import QCcheckPage from "./pages/qcCheckPage";
import MyStudioRoomDetails from "./pages/myStudioRoomDetails";
import Texture_request from "./pages/texture_artist/texture_request";
import Texture_Artist from "./pages/texture_artist/texture_artist";
import MyRooms from "./pages/myrooms";
import Profile from './components/Profile';
import Members from './pages/members'
import Users from "./pages/users";
import ErrorPage from "./pages/404";

const App = () => {
  const role = localStorage.getItem('role')
  return (
    <>
    <BrowserRouter>
      <Switch>
        <Route exact path='/'><Redirect to="/login"/></Route>
        <Route path="/login" component={Login}/>
        <Route path="/forgot-password" component={ForgotPassword}/>
        <Route path="/home" component={Dashboard}/>
        <Route path='/users' component={Users}/>
        <Route path="/modelsgrab" component={ModelsGrab}/>
        <Route path="/mymodels" component={Mymodals}/>
        <Route path="/product/:id" component={ProductView}/>
        <Route path="/myproduct/:id" component={MyModalProductView}/>
        <Route path="/room-view" component={RoomView}/>
        <Route path="/myrooms" component={MyRooms}/>
        <Route path="/room-viewdetail/:id" component={RoomViewDetail}/>
        <Route path="/assets" component={Assets}/>
        <Route path="/assetsview" component={AssetsView}/>
        <Route path="/qc-check" component={QCcheckPage}/>
        <Route path="/mystudio/roomview/:id" component={MyStudioRoomDetails}/>
        <Route path="/texture_request" component={Texture_request}/>
        <Route path="/texture_artist" component={Texture_Artist}/>
        <Route path="/profile/:name" component={Profile}/>
        <Route path="/members" component={Members}/>
      </Switch>
      </BrowserRouter>
    </>
  );
}

export default App;
