import React, { useEffect, useState } from "react";
import Carousel from "react-bootstrap/Carousel";
import Sidebar from "../layouts/sidebar";
import Topbar from "../layouts/topbar";
import style from "./productView.module.css";
import { Button } from "@material-ui/core";
import ThreeDot from "../public/lottie/ThreeDot.svg";
import { useParams, Link } from "react-router-dom";
import { supabase } from "../client";

const ProductView = ({ history }) => {
  const [user, setUser] = useState(null);
  const Id = useParams();
  const [model, setModel] = useState(null);
  const [images, setImages] = useState([]);
  const [product, setProduct] = useState([]);
  const username = localStorage.getItem("username");
  const userID = localStorage.getItem("user");

  useEffect(() => {
    const userData = localStorage.getItem("user");
    if (userData) {
      const expiry = localStorage.getItem("expires_at");
      const now = new Date().getTime() / 1000;
      if (now < expiry) {
        setUser(userData);
        // console.log(now,expiry)
      } else {
        alert("Session timed Out");
        localStorage.clear();
        history.push("/login");
      }
    } else {
      alert("Login to Continue");
      history.push("/login");
    }
  }, [history]);

  const [variant, setVariant] = useState(null);
  useEffect(() => {
    supabase
      .from("asset_data")
      .select("*")
      .eq("id", Id.id)
      .then(({ data }) => {
        setModel(JSON.stringify(data));
        const product1 = JSON.parse(model);
        // console.log(product1[0]["productImage"])
        const images1 = product1[0]["productImage"];
        setImages(images1);
        setProduct(product1[0]);
        supabase
          .from("asset_data")
          .select(
            "id,modelStatus,productName,productBrand,productImage,pixelID"
          )
          .eq("productParentID", product1[0]["productParentID"])
          .then(({ data }) => {
            if (data.length > 1) {
              setVariant(data);
            } else {
              setVariant(null);
            }
          });
      });
  }, [model]);

  const handleClick = async (item) => {
    const data1 = await supabase
      .from("asset_data")
      .select("modellerID,modelStatus")
      .eq("id", item);
    // console.log(data1);
    if (data1) {
      if (data1.data[0].modelStatus === "un_assigned") {
        const { data: updatedData, error } = await supabase
          .from("asset_data")
          .update({
            modellerName: username,
            modellerID: userID,
            modelStatus: "assigned",
          })
          .eq("id", item);

        if (error) {
          console.log(error);
        } else {
          alert("Successfully Assigned");
          history.push(`/myproduct/${Id.id}`);
        }
      } else {
        alert("Sorry model has been picked");
        window.location.reload(false);
      }
    }
  };
  const role = localStorage.getItem("role");

  return (
    <div className="flex flex-row bg-neutral-900 xl:overflow-hidden lg:overflow-hidden md:overflow-hidden">
      <div className="flex flex-row 2xl:w-screen 2xl:h-screen xl:h-screen lg:w-screen lg:h-screen md:w-screen md:h-screen xl:w-screen">
        <Sidebar />
        <div className="w-screen h-screen overflow-auto">
          <Topbar />
          <div className="flex flex-col">
            <div className="flex flex-row gap-12 w-full">
              <div className="w-1/2 items-center justify-center px-6 py-8">
                <Carousel>
                  {images.map((image) => (
                    <Carousel.Item className="flex object-contain">
                      <div className="flex object-contain 2xl:h-[38rem] h-[23rem]">
                        <img
                          src={image}
                          style={{
                            width: "100%",
                            height: "100%",
                            objectFit: "contain",
                          }}
                          alt=""
                        />
                          <a className="absolute bottom-0 right-10 z-20" href={image} download>
                            <img src={`${process.env.REACT_APP_SUPABASE_URL}/storage/v1/object/public/profileImages/download.svg`} alt=""></img>
                          </a>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          zIndex: "2",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                      </div>
                    </Carousel.Item>
                  ))}
                </Carousel>
              </div>
              <div className="flex flex-col 2xl:gap-6 gap-2">
                <div className="flex flex-row text-white 2xl:gap-16 gap-6 px-8 pt-4 items-center">
                  <div className="flex flex-row gap-2">
                    <div className={`${style.badge} flex justify-center items-center`}>
                      <img
                        style={{ scale: "1.5" }}
                        src={`https://gurtwepkenhsrmlkypvw.supabase.co/storage/v1/object/public/profileImages/${product.difficultyLevel}.svg`}
                        alt="difficultyLevel"
                      />
                    </div>
                    <div className={style.level}>{product.difficultyLevel}</div>
                  </div>
                  <div className="flex flex-row gap-2">
                    <div className={style.variantimage}>D</div>
                    <div className={style.varianttype}>Unique Model</div>
                  </div>
                  <div className="flex flex-row gap-2 items-center">
                    <div className={style.priorityimage}>
                      <img
                        style={{ scale: "0.65" }}
                        src={`https://gurtwepkenhsrmlkypvw.supabase.co/storage/v1/object/public/profileImages/${product.productPriority}.svg`}
                        alt="productPriority"
                      />
                    </div>
                    <div className={style.prioritytype}>
                      {product.productPriority}
                    </div>
                  </div>
                  {(role !== "Admin") ? (
                    <div className="flex float-right relative left-8">
                      {product.modelStatus === "un_assigned" ? (
                        <Button
                          onClick={() => handleClick(product.id)}
                          // onClick={() => alert("Temporarily disabled")}
                          style={{
                            position: "relative",
                            width: "150px",
                            height: "30px",
                            backgroundColor: "tomato",
                            borderRadius: "50vh",
                          }}
                        >
                          + Add to Studio
                        </Button>
                      ) : (
                        `Assigned to ${product.modellerName}`
                      )}
                    </div>
                  ) : (
                    <Button
                      style={{
                        fontWeight: "600",
                        position: "relative",
                        width: "150px",
                        height: "30px",
                        backgroundColor: "tomato",
                        borderRadius: "50vh",
                      }}
                    >
                      + Assign To
                    </Button>
                  )}
                </div>
                <div className="flex flex-row px-4 py-4 text-white">
                  <div className="flex flex-row gap-2 items-center">
                    <div className="flex w-4 h-4 bg-white">
                      <img src="" alt="" />
                    </div>
                    <div className={style.varianttext}>Varient Model</div>
                  </div>
                </div>
                <div className="flex flex-col h-[500px] overflow-auto gap-16 py-4 2xl:pl-5">
                  {variant?.map((variant, index) => (
                    <Link
                      style={{ textDecoration: "none" }}
                      to={`/product/${variant.id}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <div
                        key={index}
                        className="flex flex-row bg-neutral-700 pl-4 items-center"
                      >
                        <div className="flex flex-row w-full gap-6 text-white items-center">
                          <div className="flex relative -top-5">
                            <img
                              style={{
                                height: "100px",
                                width: "100px",
                                borderRadius: "5px",
                              }}
                              src={variant.productImage[0]}
                              alt="variant"
                            ></img>
                          </div>
                          <div className="flex flex-col 2xl:gap-4 gap-2">
                            <div className="w-[140px] whitespace-nowrap overflow-hidden text-ellipsis">
                              {variant.productName}
                            </div>
                            <div className={style.BrandName}>
                              {variant.productBrand}
                            </div>
                          </div>
                        </div>
                        <div className="w-full items-center flex flex-row gap-3 justify-end pr-4">
                          <div className="border-4 px-4 py-2 text-black font-semibold capitalize rounded-full bg-oran border-buttonOran">
                            {variant.modelStatus}
                          </div>
                          <div className="flex hover:bg-buttonGrey w-3 h-12 rounded-t-full rounded-b-full items-center justify-center">
                            <img src={ThreeDot} alt="" />
                          </div>
                        </div>
                      </div>
                    </Link>
                  ))}
                </div>
              </div>
            </div>
            <div className="flex px-8 pb-4">
              <div
                className="w-full rounded-lg pl-4 py-4 gap-8 flex flex-col text-white bg-grey"
                style={{ marginTop: "10px" }}
              >
                <div className="flex flex-row items-center">
                  <div className="flex 2xl:text-xl text-lg">Product Name :</div>
                  <span className="flex 2xl:text-2xl text-xl">
                    &nbsp;{product.productName}
                  </span>
                </div>
                <div className="flex flex-row items-center">
                  <div className="flex 2xl:text-xl text-lg">Brand name :</div>
                  <span className="flex 2xl:text-2xl text-xl capitalize">
                    &nbsp;{product.productBrand}
                  </span>
                </div>
                <div className="flex flex-row items-center">
                  <div className="flex 2xl:text-xl text-lg">pixel ID :</div>
                  <span className="flex 2xl:text-2xl text-xl capitalize">
                    &nbsp;{product.pixelID}
                  </span>
                </div>
                <div className="flex flex-row items-center">
                  <div className="flex 2xl:text-xl text-lg">ProductId :</div>
                  <span className="flex 2xl:text-2xl text-xl">
                    &nbsp;{product.productSku}
                  </span>
                </div>
                <div className="flex flex-row items-center">
                  <div className="flex 2xl:text-xl text-lg">Dimension :</div>
                  <div className="flex 2xl:text-2xl text-xl">
                    {product.productDimensions !== undefined
                      ? "L " +
                        product.productDimensions["l"] +
                        " " +
                        "X" +
                        " b " +
                        product.productDimensions["b"] +
                        " " +
                        "X" +
                        " h " +
                        product.productDimensions["h"] +
                        " " +
                        product.productDimensions["unit"]
                      : ""}
                  </div>
                </div>
                <div className="flex flex-row items-center">
                  <div className="flex 2xl:text-xl text-lg">Color :</div>
                  <div className="flex 2xl:text-2xl text-xl">
                    &nbsp;{product.productColor}
                  </div>
                </div>
                <div className="flex flex-row items-center">
                  <div className="flex 2xl:text-xl text-lg">Reference :</div>
                  <div className="text-ellipsis overflow-hidden text-xl 2xl:text-2xl">
                    <a target="_blank" rel="noreferrer" href={product.reffLink}>
                      &nbsp;{product.reffLink}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductView;
