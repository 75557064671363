import React, { useEffect, useState, useRef } from "react";
import style from "./home.module.css";
import { supabase } from "../client";
import Sidebar from "../layouts/sidebar";
import Topbar from "../layouts/topbar";
import { subWeeks, format, parseISO, isWithinInterval } from "date-fns";
import { score, scorePoints } from "../utils/score";
import {
  Cell,
  BarChart,
  Bar,
  PieChart,
  Pie,
  Sector,
  Line,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import AdminDashboard from "../components/AdminDashboard";

const Dashboard = () => {
  const role = localStorage.getItem("role");
  const user = localStorage.getItem("user");
  const userName = localStorage.getItem("username")
  const [moodboardData, setMoodboardData] = useState([]);
  const [assetData, setAssetData] = useState({ modelstatus: {}, monthly: [], custom: [] });
  const [assetIndex, setAssetIndex] = useState(0);
  const [moodboardIndex, setMoodboardIndex] = useState(0);
  const [userScores, setUserScores] = useState([]);
  
  const formatDate = (date) => {
    const year = date.getFullYear()
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Adding 1 because getMonth returns 0-indexed month
    const day = date.getDate().toString().padStart(2, '0');
    return `${year}-${month}-${day}`;
  }
  const [fromDate, setFromDate] = useState(formatDate(new Date()))
  const [toDate, setToDate] = useState(formatDate(new Date()))

  useEffect(() => {
    if(role !== "Admin"){
      const assetCount = async () => {
        const { data } = await supabase
          .from("asset_data")
          .select("modelStatus, created_at, blender_files, difficultyLevel", { count: "exact" })
          .eq("modellerID", user);
        const groupedData = data.reduce((acc, item) => {
          const modelStatus = item.modelStatus;
          acc[modelStatus] = (acc[modelStatus] || 0) + 1;
          return acc;
        }, {});
  
        const scoreData = data.reduce((score, item) => {
          if (item.modelStatus === "ar_pass") {
            const modelCount = item.difficultyLevel;
            score[modelCount] = (score[modelCount] || 0) + 1;
          }
          return score;
        }, {});
  
        const groupMonthlyData = data.reduce((accMonth, item) => {
          if (item.modelStatus === "ar_pass") {
            const blenderFiles = item.blender_files.filter(file => file.status === 'ar_pass' || file.status === "bundle_ready")
            const difficultyLevel = item.difficultyLevel;
            blenderFiles.forEach(file => {
              const monthYear = format(parseISO(file.modifiedDate), "MMM yy");
              if (!accMonth[monthYear]) {
                accMonth[monthYear] = { month: monthYear };
              }
              if (!accMonth[monthYear][difficultyLevel]) {
                accMonth[monthYear][difficultyLevel] = 0;
              }
              accMonth[monthYear][difficultyLevel]++;
            })
          }
          return accMonth;
        }, {});
        const monthlyArray = Object.values(groupMonthlyData);
  
        const custom_data = data.reduce((customData, item) => {
          if(item.modelStatus === "ar_pass"){
            const blenderFiles = item.blender_files.filter(file => file.status === "ar_pass" || file.status === "bundle_ready")
            const difficultyLevel = item.difficultyLevel
            blenderFiles.forEach(file => {
              const modifiedDate = format(parseISO(file.modifiedDate),"yyyy-MM-dd")
              if(isWithinInterval(modifiedDate, {start: fromDate, end: toDate})){
                const week = format(parseISO(modifiedDate),"w")
                console.log(week);
                if(!customData[week]){
                  customData[week] = {week: week}
                }
                if(!customData[week][difficultyLevel]){
                  customData[week][difficultyLevel] = 0
                }
                customData[week][difficultyLevel]++
              }
            })
          }
          console.log(customData);
          return customData
        },{})
  
        const customArray = Object.values(custom_data)
  
        const total_score = Object.entries(scoreData).reduce(
          (total, [level, count]) => {
            const levelScore = scorePoints.find(
              (scoreEntry) => scoreEntry[level]
            );
            if (levelScore) {
              total += count * levelScore[level];
            }
            return total;
          },
          0
          );
        setAssetData({
          modelstatus: groupedData,
          monthly: monthlyArray,
          custom: customArray,
          totalScore: total_score,
        });
      };
      assetCount();
    }
  }, [user, fromDate, toDate, role]);

  const handleChangeFromDate = (value) => {
    setFromDate(value)
  }

  const handleChangeToDate = (value) => {
    setToDate(value)
  }

  useEffect(() => {
    const scoreCount = async () => {
      const { data } = await supabase
        .from("asset_data")
        .select("modelStatus, difficultyLevel, modellerID", { count: "exact" });

      const userScoresData = data.reduce((scores, item) => {
        if (item.modelStatus === "ar_pass" && item.modellerID !== null) {
          const modellerID = item.modellerID;
          const difficultyLevel = item.difficultyLevel;
          if (!scores[modellerID]) {
            scores[modellerID] = {};
          }
          scores[modellerID][difficultyLevel] =
            (scores[modellerID][difficultyLevel] || 0) + 1;
        }
        return scores;
      }, {});
      const totalScoreData = await Promise.all(
        Object.entries(userScoresData).map(async ([modellerID, counts]) => {
          const TotalScore = Object.entries(counts).reduce(
            (total, [difficultyLevel, count]) => {
              const levelScore = scorePoints.find(
                (scoreEntry) => scoreEntry[difficultyLevel]
              );
              if (levelScore) {
                total += count * levelScore[difficultyLevel];
              }
              return total;
            },
            0
          );
          const { data: modelerData, error } = await supabase.from("users").select("name").eq("id", modellerID)
          const modelerName = error ? "" : modelerData && modelerData.length > 0 ? modelerData[0].name : ""
          return { modellerID, TotalScore, modelerName };
        })
      );
      const sortedUserScores = totalScoreData.sort((a, b) => b.TotalScore - a.TotalScore);
      setUserScores(sortedUserScores);
    };
    scoreCount();
  }, []);
  console.log(userScores);

  const onAssetPieEnter = (_, index) => {
    setAssetIndex(index);
  };

  const onMoodboardPieEnter = (_, index) => {
    setMoodboardIndex(index);
  };

  const renderActiveAssetShape = (props) => {
    const RADIAN = Math.PI / 180;
    const {
      cx,
      cy,
      midAngle,
      innerRadius,
      outerRadius,
      startAngle,
      endAngle,
      fill,
      payload,
      percent,
      value,
    } = props;
    const sin = Math.sin(-RADIAN * midAngle);
    const cos = Math.cos(-RADIAN * midAngle);
    const sx = cx + (outerRadius + 10) * cos;
    const sy = cy + (outerRadius + 10) * sin;
    const mx = cx + (outerRadius + 30) * cos;
    const my = cy + (outerRadius + 30) * sin;
    const ex = mx + (cos >= 0 ? 1 : -1) * 22;
    const ey = my;
    const textAnchor = cos >= 0 ? "start" : "end";
    return (
      <g>
        <text
          className="text-5xl font-semibold"
          x={cx}
          y={cy}
          dy={16}
          textAnchor="middle"
          fill="white"
        >{`${value}`}</text>
        <Sector
          cx={cx}
          cy={cy}
          innerRadius={innerRadius}
          outerRadius={outerRadius}
          startAngle={startAngle}
          endAngle={endAngle}
          fill={fill}
        />
        <Sector
          cx={cx}
          cy={cy}
          startAngle={startAngle}
          endAngle={endAngle}
          innerRadius={outerRadius + 6}
          outerRadius={outerRadius + 10}
          fill={fill}
        />
      </g>
    );
  };

  const renderMoodboardShape = (props) => {
    const RADIAN = Math.PI / 180;
    const {
      cx,
      cy,
      midAngle,
      innerRadius,
      outerRadius,
      startAngle,
      endAngle,
      fill,
      payload,
      percent,
      value,
    } = props;
    const sin = Math.sin(-RADIAN * midAngle);
    const cos = Math.cos(-RADIAN * midAngle);
    const sx = cx + (outerRadius + 10) * cos;
    const sy = cy + (outerRadius + 10) * sin;
    const mx = cx + (outerRadius + 30) * cos;
    const my = cy + (outerRadius + 30) * sin;
    const ex = mx + (cos >= 0 ? 1 : -1) * 22;
    const ey = my;
    const textAnchor = cos >= 0 ? "start" : "end";
    return (
      <g>
        <text
          className="text-5xl font-semibold"
          x={cx}
          y={cy}
          dy={16}
          textAnchor="middle"
          fill="white"
        >{`${value}`}</text>
        <Sector
          cx={cx}
          cy={cy}
          innerRadius={innerRadius}
          outerRadius={outerRadius}
          startAngle={startAngle}
          endAngle={endAngle}
          fill={fill}
        />
        <Sector
          cx={cx}
          cy={cy}
          startAngle={startAngle}
          endAngle={endAngle}
          innerRadius={outerRadius + 6}
          outerRadius={outerRadius + 10}
          fill={fill}
        />
      </g>
    );
  };

  useEffect(() => {
    if(role !== "Admin"){
      const moodboardCount = async () => {
        const { data } = await supabase
          .from("moodboards")
          .select("roomstatus", { count: "exact" })
          .eq("modellerid", user);
        const groupedData = data.reduce((acc, item) => {
          const moodboardStatus = item.roomstatus;
          acc[moodboardStatus] = (acc[moodboardStatus] || 0) + 1;
          return acc;
        }, {});
        setMoodboardData(groupedData);
      };
      moodboardCount();
    }
  }, [user, role]);

  const COLORS = ["#BBC0B1", "#D89DA4", "#D9EFF5", "#F2D399", "#33C7FF"];

  return (role && role !== "Admin") ? (
    <div className="flex flex-row bg-neutral-900 xl:overflow-hidden lg:overflow-hidden md:overflow-hidden">
      <div className="flex flex-row 2xl:w-screen 2xl:h-screen xl:h-screen lg:w-screen lg:h-screen md:w-screen md:h-screen sm:w-screen sm:h-screen xl:w-screen">
        <Sidebar />
        <div className="flex flex-col w-[100%] overflow-y-auto">
          <div className="flex w-full items-end justify-end">
            <Topbar />
          </div>
          <div className="flex w-full h-0.5 bg-neutral-400 rounded-full opacity-10"></div>
          <div className="flex w-full flex-col gap-4 px-4 py-4">
            <div className="flex flex-row w-full justify-between gap-4">
              <div className="flex flex-col w-1/3 gap-4">
                <div className="flex flex-col w-64 items-center gap-4 px-2 py-4 justify-center bg-[#343434] rounded-md">
                  <div className="flex w-full justify-center text-white text-3xl">
                    My Score
                  </div>
                  <div className="text-white text-3xl">
                    {assetData.totalScore}
                  </div>
                </div>
                <div className="flex flex-col gap-4 p-2 justify-center bg-[#343434] rounded-md">
                  <div className="flex w-full justify-center text-white text-3xl">
                    Ranking
                  </div>
                  <table className="w-full text-md text-center">
                    <thead className="text-md w-full font-thin text-center text-white uppercase">
                      <tr>
                        <th scope="col" className="py-2">
                          Name
                        </th>
                        <th scope="col" className="py-2">
                          Score
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {userScores && userScores.slice(0, 5).map((userScore, index) => (
                        <tr index={index} className="text-center text-white">
                          <td>{userScore.modelerName}</td>
                          <td>{userScore.TotalScore}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="flex flex-col w-1/3 gap-2 px-2 py-2 bg-gradient-to-r from-neutral-800 via-neutral-700 to-neutral-600 rounded-md">
                <div className="flex w-full justify-center text-white text-3xl">
                  Asset Count
                </div>
                <div className="flex flex-col gap-4 w-full items-center rounded-md">
                  {/* <ResponsiveContainer minWidth={360} minHeight={240}> */}
                  <PieChart width={300} height={280}>
                    <Pie
                      activeIndex={assetIndex}
                      activeShape={renderActiveAssetShape}
                      data={Object.entries(assetData?.modelstatus)}
                      cx="50%"
                      cy="50%"
                      innerRadius={90}
                      outerRadius={110}
                      paddingAngle={5}
                      // fill="#8884d8"
                      dataKey="1"
                      nameKey="0"
                      onMouseEnter={onAssetPieEnter}
                    >
                      {Object.entries(assetData?.modelstatus).map(
                        (entry, index) => (
                          <Cell
                            key={`cell-${index}`}
                            fill={COLORS[index % COLORS.length]}
                          />
                        )
                      )}
                    </Pie>
                  </PieChart>
                  {/* </ResponsiveContainer> */}
                </div>
                <div className="grid grid-flow-row 2xl:grid-cols-3 grid-cols-2 gap-x-2 gap-y-2 2xl:p-4 pl-8">
                  {Object.entries(assetData?.modelstatus).map(
                    (entry, index) => (
                      <div
                        className="flex flex-row gap-1 items-center text-white"
                        key={index}
                      >
                        <div
                          className={`w-4 h-4 rounded-full`}
                          style={{
                            backgroundColor: COLORS[index % COLORS.length],
                          }}
                        ></div>
                        <div className="capitalize text-base">{entry[0]}</div>
                      </div>
                    )
                  )}
                </div>
              </div>
              <div className="flex flex-col w-1/3 gap-2 px-2 py-2 bg-gradient-to-r from-neutral-800 via-neutral-700 to-neutral-600 rounded-md">
                <div className="flex w-full justify-center text-white text-3xl">
                  Moodboard Count
                </div>
                <div className="flex flex-col gap-4 w-full items-center rounded-md">
                  {/* <ResponsiveContainer minWidth={360} minHeight={220}> */}
                  <PieChart width={300} height={280}>
                    <Pie
                      activeIndex={moodboardIndex}
                      activeShape={renderMoodboardShape}
                      data={Object.entries(moodboardData)}
                      cx="50%"
                      cy="50%"
                      innerRadius={90}
                      outerRadius={110}
                      paddingAngle={5}
                      // fill="#8884d8"
                      dataKey="1"
                      nameKey="0"
                      onMouseEnter={onMoodboardPieEnter}
                    >

                      {Object.entries(moodboardData).map((entry, index) => (
                        <Cell
                          key={`cell-${index}`}
                          fill={COLORS[index % COLORS.length]}
                        />
                      ))}
                    </Pie>
                  </PieChart>
                  {/* </ResponsiveContainer> */}
                </div>
                <div className="grid grid-flow-row 2xl:grid-cols-3 xl:grid-cols-2 gap-2 pl-10">
                  {Object.entries(moodboardData).map((entry, index) => (
                    <div
                      className="flex flex-row gap-1 items-center text-white"
                      key={index}
                    >
                      <div
                        className={`w-4 h-4 rounded-full`}
                        style={{
                          backgroundColor: COLORS[index % COLORS.length],
                        }}
                      ></div>
                      <div className="capitalize text-base">{entry[0]}</div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div className="flex flex-row gap-4">
              <div className="flex flex-col w-1/2 gap-4 px-2 py-4 bg-gradient-to-r from-neutral-800 via-neutral-700 to-neutral-600 rounded-md">
                <div className="flex w-full justify-center text-white text-3xl">
                  Level Count (Monthly)
                </div>
                <div className="flex flex-col gap-4 w-full rounded-md">
                  <ResponsiveContainer minWidth={500} height={350}>
                    <BarChart
                      // width={1070}
                      height={300}
                      data={assetData["monthly"]}
                      margin={{ top: 10, right: 10, left: -10, bottom: 5 }}
                      barSize={30}
                    >
                      <CartesianGrid />
                      <XAxis
                        dataKey="month"
                        tick={{ fill: "white" }}
                        axisLine={{ stroke: "white" }}
                        tickFormatter={(value) => {
                          const parts = value.split(" ");
                          return parts.join("\n");
                        }}
                      />
                      <YAxis
                        tick={{ fill: "white" }}
                        axisLine={{ stroke: "white" }}
                      />
                      <Tooltip />
                      <Legend />
                      <Bar dataKey="L1" stackId="a" fill="#8884d8" />
                      <Bar dataKey="L2" stackId="a" fill="#82ca9d" />
                      <Bar dataKey="L3" stackId="a" fill="#86ad65" />
                    </BarChart>
                  </ResponsiveContainer>
                </div>
              </div>
              <div className="flex flex-col w-1/2 gap-4 px-2 py-4 bg-gradient-to-r from-neutral-800 via-neutral-700 to-neutral-600 rounded-md">
                <div className="flex w-full justify-center text-white text-3xl">
                  Level Count (Weekly)
                </div>
                <div className="flex flex-row gap-2 px-4 justify-center items-center">
                  <div className="flex w-1/2 text-white justify-center items-center">
                    From :&nbsp;
                    <input onChange={(e) => handleChangeFromDate(e.target.value)} type="date" value={fromDate} className="bg-white w-40 focus:outline-none text-black" />
                  </div>
                  <div className="flex w-1/2 text-white justify-center items-center">
                    To :&nbsp;
                    <input type="date" value={toDate} onChange={(e) => handleChangeToDate(e.target.value)} className="bg-white w-40 focus:outline-none text-black" />
                  </div>
                </div>
                <div className="flex flex-col gap-4 w-full rounded-md">
                  <ResponsiveContainer minWidth={500} height={350}>
                    <BarChart
                      // width={1070}
                      height={300}
                      data={assetData["custom"]}
                      margin={{ top: 10, right: 10, left: -10, bottom: 5 }}
                      barSize={30}
                    >
                      <CartesianGrid />
                      <XAxis
                        dataKey="month"
                        tick={{ fill: "white" }}
                        axisLine={{ stroke: "white" }}
                        tickFormatter={(value) => {
                          const parts = value.split(" ");
                          return parts.join("\n");
                        }}
                      />
                      <YAxis
                        tick={{ fill: "white" }}
                        axisLine={{ stroke: "white" }}
                      />
                      <Tooltip />
                      <Legend />
                      <Bar dataKey="L1" stackId="a" fill="#8884d8" />
                      <Bar dataKey="L2" stackId="a" fill="#82ca9d" />
                      <Bar dataKey="L3" stackId="a" fill="#86ad65" />
                    </BarChart>
                  </ResponsiveContainer>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <div className="flex flex-row bg-neutral-900 xl:overflow-hidden lg:overflow-hidden md:overflow-hidden">
      <div className="flex flex-row 2xl:w-screen 2xl:h-screen xl:h-screen lg:w-screen lg:h-screen md:w-screen md:h-screen sm:w-screen sm:h-screen xl:w-screen">
        <Sidebar />
        <div className="flex flex-col float-right overflow-x-hidden overflow-y-auto h-auto w-full">
          <div className="flex items-center w-full whitespace-nowrap text-white 2xl:pl-20 pl-12">
            <div className="flex flex-col">
              <div className="flex 2xl:text-4xl text-2xl tracking-wider">
                {`Welcome Back ${userName},`}
              </div>
              <div className="flex 2xl:text-xl text-lg tracking-wider">
                Here is the Progress
              </div>
            </div>
            <div className="flex float-right w-full items-end justify-end">
              <Topbar className={style.top} />
            </div>
          </div>
          <div className="flex w-full h-0.5 bg-neutral-400 rounded-full opacity-10"></div>
            <AdminDashboard/>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
