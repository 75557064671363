import React, { useEffect, useState } from "react";
import style from "./assets.module.css";
import Sidebar from "../layouts/sidebar";
import Topbar from "../layouts/topbar";
import Search from "../public/lottie/chevron_right.svg";
import Dropdown from "react-bootstrap/Dropdown";
import { supabase } from "../client";
import Pagination from "../layouts/pagination";
import { Modal } from "react-bootstrap";
import { v4 as uuidv4 } from "uuid";

const Assets = ({ history }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [count, setCount] = useState(null);
  const [textures, setTextures] = useState(null);
  const [show, setShow] = useState(false);
  const [view, setView] = useState(false);
  const [temp, setTemp] = useState(null);
  const objectsPerPage = 40;
  const startIndex = (currentPage - 1) * objectsPerPage;
  const endIndex = startIndex + objectsPerPage;
  const totalPages = Math.ceil(count / objectsPerPage);
  const user = localStorage.getItem("user");
  const [selectedFiles, setSelectedFiles] = useState(null);
  const [selectedFiles2, setSelectedFiles2] = useState(null);
  const address = JSON.parse(localStorage.getItem("address"));

  const [tag, setTag] = useState("");
  const handleTagChange = (event) => {
    setTag(event.target.value);
  };
  const [name, setName] = useState("");
  const handleNameChange = (event) => {
    setName(event.target.value);
  };

  const handleFileChange = (event) => {
    const files = Array.from(event.target.files);
    setSelectedFiles(files);
  };
  const handleFileChange2 = (event) => {
    const files2 = Array.from(event.target.files);
    setSelectedFiles2(files2);
  };

  const handleUpload = async () => {
    const formData = new FormData();
    selectedFiles.forEach((file) => {
      formData.append("file", file);
    });
    selectedFiles2.forEach((file) => {
      formData.append("file2", file);
    });
    formData.append("name", name);
    // formData.append("tag", tag)
    // formData.append("");
    const response = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/modeller-assetupload`,
      {
        method: "POST",
        body: formData,
      }
    );
    const result = await response.json();
    // console.log(result)
    if (result["message"] === "Model uploaded successfully") {
      const newId = uuidv4();
      await supabase.from("textureAssets").insert({
        id: newId,
        textureName: name,
        textureImage: [result["Reffimage"]],
        textureLink: result["asset_link"],
        textureArtist: user,
        tags: [tag],
        assetType: Option2,
      });
      await supabase.from("logs").insert({
        id: uuidv4(),
        modellerId: user,
        action: "new asset added",
        productId: newId,
        ipAdd: address.IPv4,
        location: address.city,
      });
      alert("uploaded succesfully");
      setView(false);
    }
  };
  useEffect(() => {
    if (user) {
      const expiry = localStorage.getItem("expires_at");
      const now = new Date().getTime() / 1000;
      if (now > expiry) {
        alert("Session timed Out");
        localStorage.clear();
        history.push("/login");
      }
    } else {
      alert("Login to Continue");
      history.push("/login");
    }
  });

  const [searchTerm, setSearchTerm] = useState("");
  const handleSearchTermChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const fetchTextures = async () => {
    if (!searchTerm) {
      const data = await supabase
        .from("textureAssets")
        .select("*")
        .range(0, endIndex - 1)
        .order("created_at", {ascending: false});
      setTextures(data.data);
    } else if (searchTerm) {
      const data = await supabase
        .from("textureAssets")
        .select("*")
        .ilike("textureName", `%${searchTerm}%`)
        .order("created_at", {ascending: false});
      setTextures(data.data);
    }
  };
  console.log(textures);

  const handlePageChange = async (pageNumber) => {
    setCurrentPage(pageNumber);
    const data = await supabase
      .from("textureAssets")
      .select("*")
      .range(
        (pageNumber - 1) * objectsPerPage,
        pageNumber * objectsPerPage - 1
      );
    setTextures(data.data);
  };
  // console.log(show);
  useEffect(() => {
    (async () => {
      const count = await supabase
        .from("textureAssets")
        .select("*", { count: "exact" });
      setCount(count.count);
      await fetchTextures();
    })();
  }, [searchTerm]);

  const details = async (texture) => {
    setShow(!show);
    setTemp(texture);
    // console.log(texture)
  };

  const handleClose = () => {
    setView(false);
  };

  const downloadfile = async (link) => {
    // console.log(link);
    const key = link.textureLink.replace(
      `https://${process.env.REACT_APP_3D_BUCKET}.s3.ap-south-1.amazonaws.com/`,
      ""
    );
    const response = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/assetdownload`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ s3key: key }),
      }
    );
    const result = await response.json();
    await supabase.from("logs").insert({
      id: uuidv4(),
      modellerId: user,
      action: "asset downloaded",
      productId: temp.id,
      ipAdd: address.IPv4,
      location: address.city,
    });
    window.open(result["model_link"], "_blank");
  };

  const [Option, setOption] = useState("pixelID");
  const handleOption = (event) => {
    setOption(event.target.value);
  };
  const [Option2, setOption2] = useState("3DModel");
  const handleOption2 = (event) => {
    setOption2(event.target.value);
  };
  return (
    <>
      <div className="flex flex-row bg-neutral-900 xl:overflow-hidden lg:overflow-hidden md:overflow-hidden ">
        <div className="flex flex-row 2xl:w-screen 2xl:h-screen xl:h-screen lg:w-screen lg:h-screen md:w-screen md:h-screen xl:w-screen">
          <Sidebar />
          <div className="w-screen h-screen overflow-auto scrollbar-hide">
            <Topbar />
            <div className="flex flex-row gap-2 w-[100%]">
              <div className="flex flex-col gap-4 w-[100%]">
                <div className="flex flex-row px-4">
                  <div className="flex float-right pr-8">
                    <div className="flex w-full rounded-md bg-gradient-to-r from-[#3dc1fb] via-[#ff00f5] to-[#0feffd] pb-0.5">
                    <input
                      className="focus:outline-none h-5 rounded-sm"
                      type="text"
                      value={searchTerm}
                      onChange={handleSearchTermChange}
                      placeholder="Search"
                      name="search"
                      id="search"
                      style={{
                        color: "white",
                        backgroundColor: "#343434",
                      }}
                      />
                    </div>
                    {/* <div className="relative">
                      <select
                        className="absolute right-2 focus:outline-none top-1 rounded-sm"
                        style={{ color: "black" }}
                        value={Option || "pixelID"}
                        onChange={handleOption}
                      >
                        <option style={{ color: "black" }} value={"pixelID"}>
                          PixelID
                        </option>
                        <option
                          style={{ color: "black" }}
                          value={"productName"}
                        >
                          Name
                        </option>
                      </select>
                    </div> */}
                    {/* <img className="cursor-pointer" src={Search} alt="" /> */}
                  </div>
                  <div className="flex float-right gap-16 justify-center text-white">
                    <button
                      className="bg-neutral-700 px-4 rounded-sm"
                      onClick={() => setView(true)}
                    >
                      Add to Assets
                    </button>
                    <button
                      className="bg-neutral-700 px-4 rounded-sm"
                      onClick={() => history.push("/texture_request")}
                    >
                      Request Assets
                    </button>
                  </div>
                </div>
                <div className="flex flex-row gap-3 pl-2 pr-4">
                  <div className="flex w-[100%] pt-4 text-white text-md px-4 rounded-md">
                    <div className={`w-full m-auto grid 2xl:grid-flow-row ${show ? "2xl:grid-cols-5 xl:grid-cols-3" : "2xl:grid-cols-5 xl:grid-cols-4"} 2xl:gap-x-12 2xl:gap-y-10 xl:grid-flow-row xl:gap-x-12 lg:gap-y-8 lg:grid-cols-4 lg:grid-flow-row lg:gap-10 md:grid-flow-row md:grid-cols-3 md:gap-x-16 md:gap-y-10`}>
                      {textures?.map((texture, index) => (
                        <div key={index} className="flex flex-col gap-2">
                          <div
                            onClick={() => details(texture)}
                            className="rounded-md w-[224px] h-[224px]"
                          >
                            <img
                              className="w-[224px] h-[224px] object-fit hover:scale-90 rounded-md"
                              src={texture.textureImage[0]}
                              alt=""
                            />
                          </div>
                          <div className="text-lg capitalize tracking-wider">
                            {texture.textureName}
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                  {show && (
                    <div className="w-96 h-auto bg-neutral-800 text-white rounded-md">
                      <div className="flex flex-col gap-8 pr-2">
                        <div className="flex justify-center pt-4 items-center">
                          <img
                            src={temp?.textureImage[0]}
                            style={{ width: "200px", height: "200px" }}
                            alt=""
                          />
                        </div>
                        <div className="flex flex-col text-lg gap-10 pl-4">
                          <div className="flex flex-col gap-1 tracking-wider">
                            <div className="font-thin">Texture_name :</div>
                            <div className="text-xl">{temp?.textureName}</div>
                          </div>
                          <div className="flex flex-col gap-1 tracking-wider">
                            <div className="font-thin">Assets :</div>
                            <div className="text-xl">{temp?.assetType}</div>
                          </div>
                          <div className="flex flex-col gap-1 tracking-wider">
                            <div className="font-thin">Tags :</div>
                            <div className="text-xl">{temp?.tags}</div>
                          </div>
                        </div>
                        <div className="flex items-center justify-center">
                          <button
                            className="flex px-4 py-1 font-semibold rounded-full bg-downloadBg border-downloadBorder border-2 text-black"
                            onClick={() => downloadfile(temp)}
                          >
                            Download
                          </button>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>

            {/* <div className="flex flex-row pl-6 items-center 2xl:gap-[950px] xl:gap-[400px]">
            <div className={style.filtersbox}>
              <Dropdown className={style.filterbox}>
                <Dropdown.Toggle
                  variant="success"
                  style={{ backgroundColor: "#343434" }}
                  className={style.filtername}
                >
                  Textures
                </Dropdown.Toggle>
                <Dropdown.Menu
                  style={{
                    position: "absolute",
                    width: "165px",
                    top: "-5px",
                    background:
                      "radial-gradient(50% 50% at 50% 50%, #333333 0%, rgba(51, 51, 51, 0.5) 91.15%)",
                  }}
                >
                  <Dropdown.Item className={style.dropdownitem}>
                    Gold
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
              <Dropdown className={style.filterbox}>
                <Dropdown.Toggle
                  variant="success"
                  style={{ backgroundColor: "#343434" }}
                  className={style.filtername}
                >
                  Material
                </Dropdown.Toggle>
                <Dropdown.Menu
                  style={{
                    position: "absolute",
                    width: "165px",
                    top: "-5px",
                    background:
                      "radial-gradient(50% 50% at 50% 50%, #333333 0%, rgba(51, 51, 51, 0.5) 91.15%)",
                  }}
                >
                  <Dropdown.Item className={style.dropdownitem}>
                    Gold
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div> */}
            <div>
              <Pagination
                currentPage={currentPage}
                totalPages={totalPages}
                onPageChange={handlePageChange}
              />
            </div>
          </div>
        </div>
      </div>
      <Modal size="lg" show={view} onHide={handleClose}>
        <Modal.Header closeButton className="text-xl bg-neutral-800 text-white">
          Add to Assets
        </Modal.Header>
        <Modal.Body className="bg-neutral-800 text-white">
          <div className="flex flex-col gap-4 p-2">
            <div className="flex flex-row gap-4">
              <div className="flex">
                <input
                  type="file"
                  id="image"
                  accept="image/png, image/jpeg"
                  onChange={handleFileChange}
                  className="w-72 h-72 bg-neutral-800 text-sm border items-center justify-center underline flex rounded-md"
                />
              </div>
              <div className="flex flex-col gap-4">
                <div className="flex flex-row gap-2">
                  <label className="text-lg">Asset Name*</label>
                  <div className="flex border-b">
                    <input
                      type="text"
                      className="bg-transparent appearance-none border-none leading-tight focus:outline-none"
                      onChange={handleNameChange}
                    />
                  </div>
                </div>
                <div className="flex flex-row gap-2">
                  <label className="text-lg">Asset Tag*</label>
                  <div className="flex border-b">
                    <input
                      type="text"
                      className="bg-transparent appearance-none border-none leading-tight focus:outline-none"
                      onChange={handleTagChange}
                    />
                  </div>
                </div>
                <div className="flex flex-row gap-2">
                  <label className="text-lg">Asset Type*</label>
                  <div className="flex border-b">
                    <select
                      className="focus:outline-none rounded-sm w-[100px]"
                      // className="absolute right-2 top-1 rounded-lg"
                      style={{ color: "black" }}
                      value={Option2 || "3DModel"}
                      onChange={handleOption2}
                    >
                      <option style={{ color: "black" }} value={"3DModel"}>
                        3DModel
                      </option>
                      <option style={{ color: "black" }} value={"Texture"}>
                        Texture
                      </option>
                      <option style={{ color: "black" }} value={"HDRI"}>
                        HDRI
                      </option>
                      <option style={{ color: "black" }} value={"IES"}>
                        IES
                      </option>
                      <option style={{ color: "black" }} value={"Material"}>
                        Material
                      </option>
                      <option style={{ color: "black" }} value={"Scripts"}>
                        Scripts
                      </option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex flex-col w-full gap-3">
              <div className="flex p-3 flex-col">
                Only zip files
                <input
                  type="file"
                  className="bg-transparent appearance-none h-max"
                  onChange={handleFileChange2}
                  accept=".zip"
                />
              </div>
              <div className="flex w-full justify-end">
                <button
                  onClick={() => handleUpload()}
                  className="flex px-4 py-1 rounded-full bg-oran text-black"
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Assets;
