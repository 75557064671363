import React, { useEffect, useState } from "react";
import { Button, Modal, Form } from "react-bootstrap";
import { supabase } from "../client";

function AddProduct(props) {
  const roomid = props.roomid;
  const products = props.products;
  const [show, setShow] = useState(false);
  const handleClose = () => {
    setShow(false);
    setModels(null);
    setSelected([]);
    setSearchTerm("");
  };
  const handleShow = () => setShow(true);

  const [searchTerm, setSearchTerm] = useState("");

  const handleSearchTermChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const [Option, setOption] = useState("pixelID");
  const handleOption = (event) => {
    setOption(event.target.value);
  };
  const [models, setModels] = useState(null);

  const getData = async () => {
    const data = await supabase
      .from("asset_data")
      .select(
        "id,productImage,product_db_id,pixelID,modelStatus,productName,productBrand"
      )
      .eq("project", "Internal")
      .ilike(`${Option}`, `%${searchTerm}%`)
      .limit(5);
    setModels(data.data);
  };

  useEffect(() => {
    if (searchTerm !== "") {
      getData();
    }
  }, [searchTerm]);

  const [selected, setSelected] = useState([]);
  const productChange = (model) => {
    setSelected([...selected, model]);
  };

  const gd = props.getData;
  const clickAdd = async () => {
    if (selected.length !== 0) {
      const newprods = [];
      selected.map((sel) => {
        newprods.push(sel.product_db_id);
      });
      if (products){
        var allprods = [...newprods, ...products];
      }else{
        var allprods = [...newprods]
      }
      allprods = [...new Set(allprods)];
      await supabase
        .from("moodboards")
        .update({ products: allprods })
        .eq("id", roomid);
      handleClose();
      gd();
    } else {
      alert("no new products added");
    }
  };
  return (
    <>
      <div
        className="w-full flex cursor-pointer flex-row rounded-md bg-neutral-700 justify-center items-center 2xl:pl-5 xl:pl-3 lg:pl-3 md:pl-3 pr-6"
        onClick={handleShow}
      >
        <div className="flex justify-center h-20 flex-row 2xl:gap-10 xl:gap-4 lg:gap-4 md:gap-4 items-center">
          ADD PRODUCTS
        </div>
      </div>
      <Modal size="xl" show={show} onHide={handleClose}>
        <Modal.Header className="bg-[#A4A4A4] text-white" closeButton>
          <Modal.Title>Add Products</Modal.Title>
        </Modal.Header>
        <Modal.Body className="bg-neutral-800 text-white">
          <div className="w-[100%] flex flex-row">
            {/* add products */}
            <div className="w-[50%]">
              <div className="flex flex-col">
                <div className="flex  pr-8">
                  <div className="flex flex-row gap-2">
                    <input
                      type="text"
                      value={searchTerm}
                      onChange={handleSearchTermChange}
                      placeholder="Search"
                      name="search"
                      id="search"
                      style={{
                        color: "white",
                        backgroundColor: "#343434",
                      }}
                    />
                    <select
                      className=" pt-2 text-center rounded-lg"
                      style={{ color: "black" }}
                      value={Option || "pixelID"}
                      onChange={handleOption}
                    >
                      <option style={{ color: "black" }} value={"pixelID"}>
                        PixelID
                      </option>
                      <option style={{ color: "black" }} value={"productName"}>
                        Name
                      </option>
                    </select>
                  </div>
                </div>
              </div>
              {/* search results */}
              <div className="flex flex-row">
                <div className=" flex w-[90%] flex-col gap-2 pt-4">
                  {models &&
                    models.map((model) => (
                      <div className="flex flex-row gap-4 w-full bg-[#6d6d6d] hover:bg-[#A4A4A4] rounded-md">
                        <img
                          className=" w-28 h-28 rounded-md  "
                          src={model.productImage[0]}
                        />
                        <div
                          className="  cursor-pointer w-52 flex flex-col  text-ellipsis justify-between  "
                          onClick={() => productChange(model)}
                        >
                          <div className="pt-2">{model.productName}</div>
                          <div className="text-[#F2D399]">
                            {model.productBrand}
                          </div>
                          <div className="text-[#A0CDDA] pb-2">
                            {model.pixelID}
                          </div>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            </div>
            {/* add products ends */}
            {/* selected products */}
            <div className="w-[50%] relative">
              <div className="pb-12">Selected products</div>
              {selected.map((sel) => (
                <>
                  <div className="relative pt-8">
                    <div
                      className="absolute top-1 right-1 cursor-pointer"
                      onClick={() => {
                        selected.splice(selected.indexOf(sel), 1);
                        setSelected([...selected]);
                      }}
                    >
                      X
                    </div>

                    <div className="w-full flex flex-row rounded-md bg-neutral-700 items-center 2xl:pl-5 xl:pl-3 lg:pl-3 md:pl-3 pr-6">
                      <div className="flex flex-row 2xl:gap-10 xl:gap-4 lg:gap-4 md:gap-4 items-center">
                        <div className="flex w-[100px] h-[100px] relative top-[-20px] rounded-sm bg-white">
                          <img
                            className="rounded-sm"
                            src={sel.productImage[0]}
                            alt=""
                          />
                        </div>

                        <div className="w-max flex flex-col gap-4 justify-center items-center">
                          <div> {sel.productName}</div>
                          <div>{sel.productBrand}</div>
                        </div>
                      </div>
                      <div className="w-full flex flex-row 2xl:gap-8 xl:gap-6 items-center justify-end">
                        <div className="flex text-lg border-4 px-12 py-1 rounded-full text-black font-bold border-orange-400 items-center justify-center bg-oran">
                          {sel.modelStatus}
                        </div>
                        <div>{/* <img src={ThreeDot} alt="" /> */}</div>
                      </div>
                    </div>
                  </div>
                </>
              ))}
              <div
                className=" bg-blue-500 absolute bottom-1 cursor-pointer right-3 h-8 rounded-full w-16 flex justify-center items-center"
                onClick={() => clickAdd()}
              >
                ADD
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default AddProduct;
