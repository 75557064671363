import React from 'react'
import style from './assetsView.module.css'
import Sidebar from '../layouts/sidebar'
import Topbar from '../layouts/topbar'
import Search from '../public/lottie/chevron_right.svg';

const AssetsView = () => {
  return (
    <div className={style.assetsviewpage}>
        <Sidebar/>
        <div className={style.pagebody}>
            <Topbar/>
            <div className={style.assetview}>
                <div className={style.assetimageview}>
                    <img style={{width:'500px', height:'500px',objectFit:'contain'}} src={Search} alt='' />
                </div>
                <div className={style.assetsimilar}>
                    <div className={style.assetsimilarlist}>
                        <div className={style.similarimage}>
                            <img className={style.similarview} src={Search} alt=''/>
                        </div>
                        <div className={style.assetsimilarname}>Leather_glass</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default AssetsView
