import React from 'react'
import { useHistory } from "react-router-dom";

const MemberCard = ({name, about, role, image, index, assetCount, moodboardCount, status, id}) => {
  console.log(status)
  const history = useHistory()
  const defaultImage = "https://gurtwepkenhsrmlkypvw.supabase.co/storage/v1/object/public/profileImages/profile.png"
  return (
    <div 
        onClick={() => history.push } 
        key={index} 
        className='flex flex-col gap-3 w-80 h-full justify-center items-center rounded-md bg-neutral-600 pt-2 pb-4 px-4'>
      <div className='flex flex-row w-full justify-between'>
        <div className='flex text-lg capitalize text-white font-semibold'>{role || "None"}</div>
        <div className='flex flex-row gap-2 text-white justify-center items-center'>
          <div className={`flex w-2 h-2 rounded-full ${status === 'online' ? 'bg-green-400' : 'bg-red-400'}`}></div>
          {status === 'online' ? (
            <div className='flex text-sm tracking-wider'>Online</div>
          ):(
            <div className='flex text-sm tracking-wider'>Offline</div>
          )}
        </div>
      </div>
      <div className='p-1 bg-white rounded-xl'>
        <img className='w-44 h-52 rounded-xl object-cover' src={image ? image : defaultImage} alt=''/>
        </div>
      <div className='flex text-lg text-white capitalize font-semibold'>{name}</div>
      <div className='flex flex-row w-full justify-around text-white'>
        <div className='flex flex-col gap-2 justify-center items-center'>
            <div>Models</div>
            <div>{assetCount}</div>
        </div>
         <div className='h-[100%] w-0.5 bg-neutral-400 rounded-full'></div>
         <div className='flex flex-col gap-2 justify-center items-center'>
            <div>Boards</div>
            <div>{moodboardCount}</div>
        </div>
      </div>
    </div>
  )
}

export default MemberCard
