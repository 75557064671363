import React, { Fragment } from 'react';
import style from './Topbar.module.css';
import List from '../public/lottie/list.svg';
import Notification from '../public/lottie/notification.svg';
import Bell from '../public/lottie/Bell.svg';
import ProfileIcon from '../public/lottie/ProfileIcon.svg';
import Arrow from '../public/lottie/DownArrow.svg';
import { Menu, Transition } from '@headlessui/react'
import { supabase } from '../client';
import { useHistory } from 'react-router-dom';


const Topbar = () => {
  const username = localStorage.getItem("username")
  const user = localStorage.getItem("user")
  const history = useHistory()

  const Signout = async () => {
    supabase.auth.signOut().then(() => {
      localStorage.clear()
      history.push('/')
    })
    await supabase.from("users").update({status:"offline", last_seen: new Date().toISOString()}).eq("id", user)
  }
  return (
    <div className='w-max sm:w-auto bg-neutral-900 pr-8'>
      <div className='flex h-36 justify-between items-center'>
        <div className='pl-96 sm:pl-0'></div>
        <div>
          <div className={`${style.icon} p-1`}>
            <img src={List} alt='' />
            <img src={Notification} alt='' />
            <img src={Bell} alt='' />
            <div className='flex p-1 rounded-full'>
              <Menu as="div" className="relative inline-block">
                <Menu.Button className="inline-flex w-full rounded-full px-0.5 py-0.5 bg-gradient-to-r from-[#3dc1fb] via-[#ff00f5] to-[#0feffd]">
                  <div className='flex bg-gradient-to-r rounded-xl from-[#3dc1fb] via-[#ff00f5] to-[#0feffd]' style={{ width: '160px' }} >
                    <div style={{borderRadius:"50vh"}} className={style.profiledrop}>
                      <img src={ProfileIcon} alt='' />
                      <div className='capitalize w-auto text-white'>{username}</div>
                      <img src={Arrow} alt='' />
                    </div>
                  </div>
                </Menu.Button>
                <Transition
                  as={Fragment}
                  className="relative rounded-md z-10"
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <div className='rounded-md' style={{
                    fontSize: 'medium',
                    width: 'max-content',
                    background: 'radial-gradient(50% 50% at 50% 50%, #333333 0%, rgba(51, 51, 51, 1) 91.15%)',
                  }}>
                    <Menu.Items className="absolute mt-1 w-40 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black/5 focus:outline-none">
                      <div className="px-1 py-1 " style={{
                          fontSize: 'medium',
                          background: 'radial-gradient(50% 50% at 50% 50%, #333333 0%, rgba(51, 51, 51, 1) 91.15%)',
                        }}>
                        <Menu.Item>
                          {({ active }) => (
                            <button
                              onClick={() => history.push(`/profile/${username}`)}
                              className={`${!active ? 'text-white' : "text-[#36A9DB]"
                                } group flex w-full items-center rounded-md px-1 py-2 text-base`}
                            >
                              My Account
                            </button>
                          )}
                        </Menu.Item>
                        <Menu.Item>
                          {({ active }) => (
                            <button
                              className={`${!active ? 'text-white' : "text-[#36A9DB]"
                                } group flex w-full items-center rounded-md px-1 py-2 text-base`}
                            >
                              Settings
                            </button>
                          )}
                        </Menu.Item>
                      </div>
                      <div className="px-1 py-1" style={{
                          fontSize: 'medium',
                          background: 'radial-gradient(50% 50% at 50% 50%, #333333 0%, rgba(51, 51, 51, 1) 91.15%)',
                        }}>
                        <Menu.Item>
                          {({ active }) => (
                            <button
                              className={`${!active ? 'text-white' : "text-[#36A9DB]"
                                } group flex w-full items-center rounded-md px-1 py-2 text-base`}
                            >
                              My Escalations
                            </button>
                          )}
                        </Menu.Item>
                        <Menu.Item>
                          {({ active }) => (
                            <button
                              className={`${!active ? 'text-white' : "text-[#36A9DB]"
                                } group flex w-full items-center rounded-md px-1 py-2 text-base`}
                            >
                              Help
                            </button>
                          )}
                        </Menu.Item>
                        <Menu.Item>
                          {({ active }) => (
                            <button
                              className={`${!active ? 'text-white' : "text-[#36A9DB]"
                                } group flex w-full rounded-md px-1 py-2 text-base`}
                            >
                              Terms & Conditions
                            </button>
                          )}
                        </Menu.Item>
                        <Menu.Item>
                          {({ active }) => (
                            <button
                              className={`${!active ? 'text-white' : "text-[#36A9DB]"
                                } group flex w-full items-center rounded-md px-1 py-2 text-base`}
                            >
                              About
                            </button>
                          )}
                        </Menu.Item>
                      </div>
                      <div className="px-1 py-1" style={{
                          fontSize: 'medium',
                          background: 'radial-gradient(50% 50% at 50% 50%, #333333 0%, rgba(51, 51, 51, 1) 91.15%)',
                        }}>
                        <Menu.Item>
                          {({ active }) => (
                            <button
                              onClick={() => Signout()}
                              className={`${!active ? 'text-white' : "text-[#F69EAA]"
                                } group flex w-full items-center rounded-md px-1 py-2 text-base`}
                            >
                              Logout
                            </button>
                          )}
                        </Menu.Item>
                      </div>
                    </Menu.Items>
                  </div>
                </Transition>
              </Menu>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Topbar
