import Logo from './public/lottie/Logo.svg';
import Dashboard from './public/lottie/Dashboard.svg'
import Assets from './public/lottie/Assets.svg'
import Finances from './public/lottie/Finance.svg'
import Modelsgrab from './public/lottie/ModelsforGrab.svg'
import mymodels from './public/lottie/Mymodels.svg'
import notes from './public/lottie/notes.svg'
import roomview from './public/lottie/Roomview.svg'
import studymaterial from './public/lottie/StudyMaterial.svg'
import Users from './public/lottie/Users.svg'

export const modelerLinks = [
    {
        title: "Dashboard",
        link: "/home",
        Icon: Dashboard
    },
    {
        title: "Models for Grab",
        link: "/modelsgrab",
        Icon: Modelsgrab
    },
    {
        title: "My Models",
        link: "/mymodels",
        Icon: mymodels
    },
    {
        title: "Room Views",
        link: "/myrooms",
        Icon: roomview
    },
    // {
    //     title: "Finance",
    //     link: "/finance",
    //     Icon: Finances
    // },
    {
        title: "Assets",
        link: "/assets",
        Icon: Assets
    },
    {
        title: "Members",
        link: "/members",
        Icon: Users
    }
    // {
    //     title: "Study Material",
    //     link: "/study material",
    //     Icon: studymaterial
    // },
    // {
    //     title: "My Notes",
    //     link: "/notes",
    //     Icon: notes
    // },
]

export const adminLinks = [
    {
        title: "Dashboard",
        link: "/home",
        Icon: Dashboard
    },
    {
        title: "Users",
        link: "/users",
        Icon: Users
    },
    {
        title: "Members",
        link: "/members",
        Icon: Users
    },
    {
        title: "Models",
        link: "/mymodels",
        Icon: mymodels
    },
    {
        title: "Room Views",
        link: "/room-view",
        Icon: roomview
    },
    {
        title: "Models for QC",
        link: "/qc-check",
        Icon: mymodels
    },
    // {
    //     title: "Finance",
    //     link: "/finance",
    //     Icon: Finances
    // },
    {
        title: "Assets",
        link: "/assets",
        Icon: Assets
    },
    // {
    //     title: "Study Material",
    //     link: "/study material",
    //     Icon: studymaterial
    // },
    // {
    //     title: "My Notes",
    //     link: "/notes",
    //     Icon: notes
    // },
]

export const texture_artist_links = [
    {
        title: "Assets",
        link: "/assets",
        Icon: Assets
    },
    {
        title : "Texture_Assets",
        link : "/texture_artist",
        Icon : Assets
    },
    {
        title: "Dashboard",
        link: "/home",
        Icon: Dashboard
    },
    {
        title: "Members",
        link: "/members",
        Icon: Users
    },
]