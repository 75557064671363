import React from 'react';

function Pagination({ currentPage, totalPages, onPageChange }) {
  const pageNumbers = [];
  for (let i = 1; i <= totalPages; i++) {
    pageNumbers.push(i);
  }

  return (
    <div className='flex justify-end p-5' style={{ position: 'relative' }}>
    <nav>
      <ul className='pagination'>
        <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
          <button className='page-link' style={{ color: 'black' }} onClick={() => onPageChange(currentPage - 1)}>Prev</button>
        </li>
        {pageNumbers.map((number) => {
          if (number === 1 || number === totalPages || (number >= currentPage - 1 && number <= currentPage + 1)) {
            return (
              <li key={number} className={`page-item ${currentPage === number ? 'active' : ''}`}>
                <button className='page-link' style={{ color: 'black' }} onClick={() => onPageChange(number)}>{number}</button>
              </li>
            );
          } else if (number === currentPage - 2 || number === currentPage + 2) {
            return (
              <li key={number} className='page-item disabled'>
                <span className='page-link' style={{ color: 'black' }}>...</span>
              </li>
            );
          } else {
            return null;
          }
        })}
        <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
          <button className='page-link' style={{ color: 'black' }} onClick={() => onPageChange(currentPage + 1)}>Next</button>
        </li>
      </ul>
    </nav>
  </div>  
  );
}

export default Pagination;
