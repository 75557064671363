import React, { useEffect, useState } from "react";
import Sidebar from "../layouts/sidebar";
import Topbar from "../layouts/topbar";
import style from "./qcCheckPage.module.css";
import { Button } from "@material-ui/core";
import "@google/model-viewer";
import { supabase } from "../client";
import QcFail from "../services/qcFail";
import QcPass from "../services/qcPass";
import ThreeScene from "../services/threeScene";
import Carousel from "react-bootstrap/Carousel";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";

const QCcheckPage = ({ history }) => {
  const userData = localStorage.getItem("user");
  const role = localStorage.getItem("role");
  const [models, setModels] = useState(null);
  const [selectedModel, setSelectedModel] = useState(0);
  const [glb, setGlb] = useState(null);
  useEffect(() => {
    if (userData && role === "Admin") {
      const expiry = localStorage.getItem("expires_at");
      const now = new Date().getTime() / 1000;
      if (now > expiry) {
        alert("Session timed Out");
        localStorage.clear();
        history.push("/login");
      }
    } else {
      alert("You are not a admin");
      history.push("/login");
    }
  }, [history]);
  const modelid = models?.[selectedModel]?.pixelID;

  const fetchData = async () => {
    const data = await supabase
      .from("asset_data")
      .select("*")
      .eq("modelStatus", "submitted_for_qc")
      // .order("updated_at",{ascending: false});
    const modeldata = JSON.parse(JSON.stringify(data));
    // console.log(modeldata);
    setModels(modeldata["data"]);
    const key = modeldata["data"][0]?.glbLink.replace(
      `https://${process.env.REACT_APP_BLENDER_BUCKET}.s3.ap-south-1.amazonaws.com/`,
      ""
    );
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/model3d/${modelid}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ s3key: key }),
        }
      );
      const result = await response.json();
      setGlb(result["model_link"]);
    } catch (error) {
      console.error(error);
    }
  };
  // console.log(key)
  const getlink = async () => {
    const key = models?.[selectedModel]?.glbLink.replace(
      `https://${process.env.REACT_APP_BLENDER_BUCKET}.s3.ap-south-1.amazonaws.com/`,
      ""
    );
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/model3d/${modelid}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ s3key: key }),
        }
      );
      const result = await response.json();
      setGlb(result["model_link"]);
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);
  useEffect(() => {
    getlink();
  }, [selectedModel]);

  // console.log(models.length);

  return (
    <div className="flex flex-row bg-neutral-900 xl:overflow-hidden lg:overflow-hidden md:overflow-hidden">
      <div className="flex flex-row 2xl:w-screen 2xl:h-screen xl:h-screen lg:w-screen lg:h-screen md:w-screen md:h-screen xl:w-screen">
        <Sidebar />
        <div className="w-screen h-screen overflow-auto">
          <Topbar />
          <div className="pl-4 overflow-x-auto  justify-center grid 2xl:gap-4 xl:gap-6 lg:gap-4 md:gap-10 grid-flow-col grid-cols-12 xl:grid-cols-12 xl:grid-rows-1 lg:grid-cols-12 lg:grid-rows-1 md:grid-cols-6 md:grid-rows-2">
            {models?.map((model, index) => (
              <div className={style.imagelist}>
                <img
                  onClick={() => setSelectedModel(index)}
                  key={index}
                  className="w-[60px] h-[60px] bg-white rounded-sm hover:border-teal-500 hover:border-2"
                  src={model?.productImage[0]}
                  alt=""
                />
              </div>
            ))}
          </div>
          <div className="2xl:gap-8 xl:gap-6 2xl:flex 2xl:flex-row xl:flex xl:flex-row md:flex md:flex-row md:gap-4 pl-4 pt-4 pb-7 2xl:pr-16 xl:pr-8">
            <div className={style.qcbottomleft}>
              <div className={style.productimage}>
                <Carousel>
                  {models?.[selectedModel]?.productImage.map((image, index) => (
                    <Carousel.Item>
                      <TransformWrapper>
                        <TransformComponent>
                          <img
                            key={index}
                            className="object-contain flex 2xl:w-[750px] 2xl:h-[450px] xl:w-[450px] xl:h-[300px] lg:w-[400px] lg:h-[300px] bg-white"
                            src={image}
                            alt=""
                          />
                        </TransformComponent>
                      </TransformWrapper>
                    </Carousel.Item>
                  ))}
                </Carousel>
              </div>
              <div className="flex 2xl:h-auto xl:h-auto bg-neutral-700 rounded text-white">
                <div className="flex flex-col pl-2 gap-3">
                  <div className="2xl:flex 2xl:flex-row 2xl:items-center 2xl:gap-3 xl:flex xl:items-center xl:flex-row xl:gap-1">
                    <div className="2xl:text-lg xl:text-md">Product ID :</div>
                    <div className="2xl:text-3xl xl:text-xl items-center justify-center">
                      {models?.[selectedModel]?.pixelID}
                    </div>
                  </div>
                  <div className="2xl:flex 2xl:flex-row 2xl:items-center 2xl:gap-3 xl:flex xl:items-center xl:flex-row xl:gap-1">
                    <div className="2xl:text-lg xl:text-md">Dimension :</div>
                    <div className="2xl:text-3xl xl:text-xl items-center justify-center">
                      {/* {models?.[selectedModel].productDimensions}; */}
                      {models?.[selectedModel]?.productDimensions !== undefined
                        ? "L " +
                          (models?.[selectedModel]?.productDimensions)["l"] +
                          " " +
                          "X" +
                          " b " +
                          (models?.[selectedModel]?.productDimensions)["b"] +
                          " " +
                          "X" +
                          " h " +
                          (models?.[selectedModel]?.productDimensions)["h"] +
                          " " +
                          (models?.[selectedModel]?.productDimensions)["unit"]
                        : ""}
                    </div>
                  </div>
                  <div className="2xl:flex 2xl:flex-row 2xl:items-center 2xl:gap-3 xl:flex xl:flex-row xl:gap-1">
                    <div className="2xl:text-lg xl:text-md">Level :</div>
                    <div className="2xl:text-3xl xl:text-xl flex flex-row gap-2">
                      <img
                        style={{ width: "20px", height: "20px" }}
                        src={`https://gurtwepkenhsrmlkypvw.supabase.co/storage/v1/object/public/profileImages/${models?.[selectedModel]?.difficultyLevel}.svg`}
                        alt=""
                      />
                      <div>{models?.[selectedModel]?.difficultyLevel}</div>
                    </div>
                  </div>
                  <div className="2xl:flex 2xl:flex-row 2xl:items-center 2xl:gap-3 xl:flex xl:items-center xl:flex-row xl:gap-1">
                    <div className="2xl:text-lg xl:text-md">Color :</div>
                    <div className="2xl:text-3xl xl:text-xl items-center justify-center">
                      {models?.[selectedModel]?.productColor}
                    </div>
                  </div>
                  <div className="2xl:flex 2xl:flex-row 2xl:items-center 2xl:gap-3 xl:flex xl:items-center xl:flex-row xl:gap-1">
                    <div className="2xl:text-lg xl:text-md">Modeler Name :</div>
                    <div className="2xl:text-3xl xl:text-xl">
                      {models?.[selectedModel]?.modellerName}
                    </div>
                  </div>
                  <div className="2xl:flex 2xl:flex-row 2xl:items-center 2xl:gap-3 xl:flex xl:items-center xl:flex-row xl:gap-1">
                    <div className="2xl:text-lg xl:text-md">Reff link :</div>
                    <a
                      className="2xl:text-3xl xl:text-xl"
                      style={{
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                        textOverflow: "ellipsis",
                        width: "300px",
                      }}
                      href={models?.[selectedModel]?.reffLink}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {models?.[selectedModel]?.reffLink}
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className={style.qcbottomright}>
              <div
                id="viewport"
                className="flex 2xl:w-[850px] 2xl:h-[600px] xl:w-[600px] xl:h-[450px] bg-white"
              >
                {/* <model-viewer
                  style={{ width: "100%", height: "100%" }}
                  src={glb}
                  alt=""
                  shadow-intensity="1"
                  ar-scale="fixed"
                  enviroment-image="neutral"
                  camera-controls
                  touch-action="pan-y"
                  auto-rotate
                  ar-modes="webxr scene-viewer quick-look"
                  ar
                /> */}
                {glb !== null ? <ThreeScene link={glb} /> : ""}
                {/* <ThreeScene link={glb}/> */}
              </div>
              <div className={style.qcbuttons}>
                <div className={style.count}>
                  <div>Count</div>
                  <div>
                    {selectedModel + 1}/{models?.length}
                  </div>
                </div>
                <div className="flex flex-row 2xl:gap-8 xl:gap-4">
                  <QcFail
                    fileId={models?.[selectedModel]?.fileId}
                    files={models?.[selectedModel]?.blender_files}
                    id={models?.[selectedModel]?.id}
                    modellerid={models?.[selectedModel]?.modellerID}
                  />
                  {/* <Button
                    className={style.fail}
                    style={{
                      width: "120px",
                      height: "30px",
                      fontWeight: "700",
                      borderRadius: "50vh",
                      backgroundColor: "tomato",
                    }}
                  >
                    QC Failed
                  </Button> */}
                  <QcPass
                    fileId={models?.[selectedModel]?.fileId}
                    files={models?.[selectedModel]?.blender_files}
                    id={models?.[selectedModel]?.id}
                    modellerid={models?.[selectedModel]?.modellerID}
                    pixelid={models?.[selectedModel]?.pixelID}
                  />

                  {/* <Button
                    className={style.pass}
                    style={{
                      width: "120px",
                      height: "30px",
                      fontWeight: "700",
                      borderRadius: "50vh",
                      backgroundColor: "lightskyblue",
                    }}
                  >
                    QC Passed
                  </Button> */}
                </div>
              </div>
              <div id="textDiv">
                <table style={{ color: "white", width: "100px" }}>
                  <tr>
                    <td style={{ padding: "35px" }}>Length</td>
                    <td style={{ padding: "25px" }} id="bound-x">
                      1.2
                    </td>
                    <td style={{ padding: "35px" }}>Width</td>
                    <td style={{ padding: "25px" }} id="bound-y">
                      2
                    </td>
                    <td style={{ padding: "35px" }}>Height</td>
                    <td style={{ padding: "25px" }} id="bound-z">
                      2
                    </td>
                  </tr>
                  {/* <tr>
                    </tr>
                    <tr>
                    </tr> */}
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default QCcheckPage;
