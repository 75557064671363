import React, { useState } from 'react';
import {useHistory} from 'react-router-dom'
import style from './Sidebar.module.css';
import cn from "classnames";
import {ChevronDoubleLeftIcon, ChevronDoubleRightIcon} from '@heroicons/react/24/outline'
import { modelerLinks, adminLinks, texture_artist_links } from '../constants';
import Logo from '../public/lottie/Logo.svg';
const Sidebar = () => {
  const [collapsed, setCollapsed] = useState(false)
  const Icon = collapsed ? ChevronDoubleRightIcon : ChevronDoubleLeftIcon;
  const history = useHistory();
  const role = localStorage.getItem('role');
  return (
    <div className={cn({"bg-neutral-800 text-white z-20": true})}>
      <div className={cn({"flex flex-col w-full h-full justify-between": true})}>
        <div className={cn({
          "items-center w-full" : true,
          // "duration-500 transition-all ease-in-out": true,
          "justify-between 2xl:w-64 xl:w-56" : !collapsed,
          "justify-center w-28": collapsed
        })}>
          <>
            {!collapsed ? (
              <div className='flex flex-col'>
                <div className='flex flex-row px-5 2xl:py-14 py-7 gap-1' onClick={() => history.push('/dashboard')}>
                  <img className={style.logo_image} src={Logo} alt=''/>
                  <span style={{position:'relative', top:'18px', fontSize:'28px'}}>{" "}Studio</span>
                </div>
              {role && (role === "Modeler") && (
                 <div className="flex flex-col px-6 justify-center w-full py-10 items-start 2xl:gap-10 gap-6 font-normal 2xl:text-lg xl:text-base text-white">
                  {modelerLinks.map((modelerLink, index) => {
                    return(
                      <div key={index}
                        className="flex flex-row w-full items-center hover:bg-[#36A9DB] p-2 rounded-md"
                        onClick={() => history.push(`${modelerLink.link}`)}
                      >
                        <img
                          className="w-6 h-6"
                          src={modelerLink.Icon}
                          alt=""
                        />
                        <span
                          style={{ paddingLeft: "5px" }}
                          className={style.menu_items}
                        >
                          {modelerLink.title}
                        </span>
                      </div>
                    )
                  })}
                  </div>
                )}
                {role && role === "Admin" && (
                 <div className="flex flex-col px-6 justify-center w-full py-10 items-start 2xl:gap-10 gap-6 font-normal 2xl:text-lg xl:text-base text-white">
                  {adminLinks.map((adminLink) => {
                    return(
                      <div
                        className="flex flex-row w-full items-center  hover:bg-[#36A9DB] p-2 rounded-md"
                        onClick={() => history.push(`${adminLink.link}`)}
                      >
                        <img
                          className="w-6 h-6"
                          src={adminLink.Icon}
                          alt=""
                        />
                        <span
                          style={{ paddingLeft: "5px" }}
                          className={style.menu_items}
                        >
                          {adminLink.title}
                        </span>
                      </div>
                    )
                  })}
                  </div>
                )}
                {role && (role === "Texture-Artist" || role === "Visualizer") && (
                 <div className="flex flex-col px-6 justify-center w-full py-10 items-start 2xl:gap-10 gap-6 font-normal 2xl:text-lg xl:text-base text-white">
                  {texture_artist_links.map((texture_artist_Link) => {
                    return(
                      <div
                        className="flex flex-row w-full items-center  hover:bg-[#36A9DB] p-2 rounded-md"
                        onClick={() => history.push(`${texture_artist_Link.link}`)}
                      >
                        <img
                          className="w-6 h-6"
                          src={texture_artist_Link.Icon}
                          alt=""
                        />
                        <span
                          style={{ paddingLeft: "5px" }}
                          className={style.menu_items}
                        >
                          {texture_artist_Link.title}
                        </span>
                      </div>
                    )
                  })}
                  </div>
                )}
              </div>
            ) : (
              <div className="flex flex-col justify-center">
                <div
                  className="flex px-8 2xl:py-14 py-7"
                  onClick={() => history.push("/dashboard")}
                >
                  <img className="w-[50px] h-[50px] object-fill" src={Logo} alt="" />
                </div>
                {(role === "Modeler") && (
                  <div className="flex flex-col px-6 justify-center items-center w-full py-10 2xl:gap-10 gap-6 font-normal text-lg text-white">
                    {modelerLinks.map((modelerLink) => {
                      return (
                        <div
                          className="flex items-center cursor-pointer hover:bg-[#36A9DB] p-2 rounded-md"
                          onClick={() => history.push(`${modelerLink.link}`)}
                        >
                          <img
                            className="w-6 h-6"
                            src={modelerLink.Icon}
                            alt=""
                          />
                        </div>
                      );
                    })}
                  </div>
                )} 
                {role && role === "Admin" && (
                  <div className="flex flex-col px-6 justify-center items-center 2xl:py-10 text-lg xl:py-2 2xl:gap-10 gap-6 font-normal text-white">
                    {adminLinks.map((adminLink) => {
                      return (
                        <div
                          className="flex items-center cursor-pointer hover:bg-[#36A9DB] p-2 rounded-md"
                          onClick={() => history.push(`${adminLink.link}`)}
                        >
                          <img className="w-6 h-6" src={adminLink.Icon} alt="" />
                        </div>
                      );
                    })}
                  </div>
                )}
                {(role === "Texture-Artist" || role === "Visualizer") && (
                  <div className="flex flex-col px-6 items-center justify-center 2xl:py-10 text-lg xl:py-2 2xl:gap-10 gap-6 font-normal text-white">
                    {texture_artist_links.map((texture_artist_Link) => {
                      return (
                        <div
                          className="flex items-center cursor-pointer hover:bg-[#36A9DB] p-2 rounded-md"
                          onClick={() => history.push(`${texture_artist_Link.link}`)}
                        >
                          <img className="w-6 h-6" src={texture_artist_Link.Icon} alt="" />
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>
            )}
          </>
        </div>
        <button className='flex w-full relative bottom-4 justify-end' onClick={() => setCollapsed((prev) => !prev)}>
          <Icon className="2xl:w-8 2xl:h-8 xl:w-6 xl:h-6 relative rounded-sm"/>
        </button>
      </div>
    </div>
  )
}

export default Sidebar
