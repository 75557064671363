import React, { useEffect, useState } from "react";
import { supabase } from "../client";
import MemberCard from "../components/MemberCard";
import SideBar from "../layouts/sidebar";
import Topbar from '../layouts/topbar';

const Members = () => {
  const [userList, setUserList] = useState([]);
  const [assetData, setAssetData] = useState({});
  const [moodboardData, setMoodboardData] = useState({});
  const [roleList, setRoleList] = useState([])

  useEffect(() => {
    const Users = async () => {
      const { data: users } = await supabase.from("users").select("*");
      const distinctRoles = [...new Set(users.map((user) => user.role))];
      setRoleList(distinctRoles);
      setUserList(users);

      const assetCount = users.map(async (user) => {
        const { data: assetData } = await supabase
          .from("asset_data")
          .select("*")
          .eq("modellerID", user.id);
        return { userId: user.id, assetData: assetData };
      });
      const userAssetCount = await Promise.all(assetCount);
      const userAssetCountMap = userAssetCount.reduce((acc, item) => {
        acc[item.userId] = item.assetData.length;
        return acc;
      }, {});
      setAssetData(userAssetCountMap);

      const moodboardCount = users.map(async (user) => {
        const { data: moodBoardData } = await supabase
          .from("moodboards")
          .select("*")
          .eq("modellerid", user.id);
        return { userId: user.id, moodBoardData: moodBoardData };
      });
      const userMoodboardData = await Promise.all(moodboardCount);
      const userMoodboardDataMap = userMoodboardData.reduce((acc, item) => {
        acc[item.userId] = item.moodBoardData.length;
        return acc;
      }, {});
      setMoodboardData(userMoodboardDataMap);
    };
    Users();
  }, []);
  console.log(assetData, moodboardData);

  return (
    <div className="flex flex-row   bg-neutral-900 lg:overflow-hidden">
      <div className="flex flex-row 2xl:w-screen 2xl:h-screen xl:w-screen lg:h-screen">
        <SideBar />
        <div className="flex flex-col px-4 overflow-y-auto  2xl:h-screen xl:h-screen lg:w-screen lg:h-screen md:w-screen md:h-screen xl:w-screen">
          <Topbar/>
          <div className="flex w-[100%] border-1 border-neutral-400"></div>
          <div className="flex relative h-full pb-4 justify-center items-center 2xl:text-8xl text-6xl text-white font-bold">
            Meet our Team
          </div>
          {/* <div className="flex w-[100%] border-1 border-neutral-400"></div> */}
          {roleList && roleList.includes("Admin") && (
          <div className="flex flex-row gap-4 w-full p-4 text-white">
            <div className="flex flex-col w-1/2 gap-4">
              <div className="flex 2xl:text-8xl text-6xl font-semibold">Admin</div>
              <div className="flex 2xl:text-3xl text-2xl font-light">
                Meet our Admin who fosters a positive and innovative work
                environment, supporting the professional growth and skill
                development of AR modelers within the team.
              </div>
            </div>
            <div className="flex justify-center w-1/2 items-center">
              <div className="grid grid-flow-row">
                {userList &&
                  userList.map((user, index) =>
                    user.role === "Admin" ? (
                      <MemberCard
                        key={index}
                        index={index}
                        id={user.id}
                        name={user.name}
                        about={user.about}
                        image={user.profile_image}
                        role={user.role}
                        status={user.status}
                        assetCount={assetData[user.id]}
                        moodboardCount={moodboardData[user.id]}
                      />
                    ) : null
                  )}
              </div>
            </div>
          </div>
          )}
          {roleList && roleList.includes("Tech") && (
          <div className="flex flex-row gap-4 w-full p-4 text-white">
            <div className="flex flex-col w-1/2 gap-4">
              <div className="flex 2xl:text-8xl text-6xl font-semibold">Tech</div>
              {/* <div className="flex 2xl:text-3xl text-2xl font-light">
                Meet our Admin who fosters a positive and innovative work
                environment, supporting the professional growth and skill
                development of AR modelers within the team.
              </div> */}
            </div>
            <div className="flex justify-center w-1/2 items-center">
              <div className="grid grid-flow-row">
                {userList &&
                  userList.map((user, index) =>
                    user.role === "Tech" ? (
                      <MemberCard
                        key={index}
                        index={index}
                        id={user.id}
                        name={user.name}
                        about={user.about}
                        image={user.profile_image}
                        role={user.role}
                        status={user.status}
                        assetCount={assetData[user.id]}
                        moodboardCount={moodboardData[user.id]}
                      />
                    ) : null
                  )}
              </div>
            </div>
          </div>
          )}
          {roleList && roleList.includes("Modeler") && (
          <div>
            <div className="flex flex-col w-3/4 p-4 text-white gap-4">
              <div className="flex 2xl:text-8xl text-6xl font-semibold">Modelers</div>
              <div className="flex 2xl:text-3xl text-2xl font-light">
                Meet our creative bunch of 3D Modelers who create immersive
                Augmented Reality experiences by designing and integrating virtual
                elements into real world environments.
              </div>
            </div>
            <div className="grid grid-flow-row 2xl:grid-cols-4 xl:grid-cols-3 py-8 px-4 gap-8">
              {userList &&
                userList.map((user, index) =>
                  (user.role === "Modeler") ? (
                    <MemberCard
                      key={index}
                      index={index}
                      id={user.id}
                      name={user.name}
                      about={user.about}
                      image={user.profile_image}
                      role={user.role}
                      status={user.status}
                      assetCount={assetData[user.id]}
                      moodboardCount={moodboardData[user.id]}
                    />
                  ) : null
                )}
            </div>
          </div>
          )}
          {roleList && roleList.includes("texture_artist") && (
          <div>
            <div className="flex flex-col w-3/4 text-white gap-4 p-4">
              <div className="flex 2xl:text-8xl text-6xl font-semibold">Texture Artists</div>
              <div className="flex 2xl:text-3xl text-2xl font-light">
                Meet our texture artists who are specialized in designing and
                creating realistic and visually appealing textures that enhance
                the visual quality of AR models.
              </div>
            </div>
            <div className="grid grid-flow-row 2xl:grid-cols-4 xl:grid-cols-3 py-8 px-4 gap-8">
              {userList &&
                userList.map((user, index) =>
                  (user.role === "texture_artist" || user.role === "Visualizer") ? (
                    <MemberCard
                      key={index}
                      index={index}
                      id={user.id}
                      name={user.name}
                      about={user.about}
                      image={user.profile_image}
                      role={user.role}
                      status={user.status}
                      assetCount={assetData[user.id]}
                      moodboardCount={moodboardData[user.id]}
                    />
                  ) : null
                )}
            </div>
          </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Members;
