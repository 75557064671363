import React from "react";
import Lottie from "react-lottie";
import animationData from '../public/lottie/404.json'

const ErrorPage = () => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  return (
    <div className="flex flex-row w-full h-screen bg-neutral-900 xl:overflow-hidden lg:overflow-hidden md:overflow-hidden">
        <div className="flex flex-col w-[50%] h-screen m-auto">
            <div className="flex w-full h-64 justify-center items-center text-7xl text-white">Unauthorized Access</div>
            <Lottie style={{height:'60%'}} options={defaultOptions}/>
        </div>
    </div>
  );
};

export default ErrorPage;
