import React, { useEffect, useState } from "react";
import Sidebar from "../layouts/sidebar";
import Topbar from "../layouts/topbar";
import style from "./roomView.module.css";
import Search from "../public/lottie/chevron_right.svg";
import { Button } from "@material-ui/core";
import { supabase } from "../client";
import { Link, useHistory } from "react-router-dom";


const RoomView = ({history}) => {
  const [moodboards, setMoodboards] = useState();
  const userID = localStorage.getItem("user");
  //   console.log(userID)
  const boards = async () => {
    const data = await supabase
      .from("moodboards")
      .select("*")
      .eq("modellerid", "unassigned");
    setMoodboards(data.data);
  };
  const currentTime = new Date().getTime() / 1000;
  useEffect(() => {
    if (userID) {
      const expiry = localStorage.getItem("expires_at");
      if (currentTime < expiry) {
        // console.log(currentTime,expiry)
        boards();
      } else {
        alert("Session timed Out");
        localStorage.clear();
        history.push("/login");
      }
    } else {
      alert("Login to Continue");
      history.push("/login");
    }
  }, []);
  //   console.log(moodboards);

  const AddToSTudio = async (id) => {
    const check = await supabase
      .from("moodboards")
      .select("modellerid,roomstatus")
      .eq("id", id);

    if (check.data[0].modellerid === "unassigned") {
      await supabase
        .from("moodboards")
        .update({ modellerid: userID, roomstatus: "assigned" })
        .eq("id", id);
      boards();
    } else {
      alert("Sorry Already Picked");
      window.location.reload(false);
    }
  };
  return (
    <div className="flex flex-row bg-neutral-900 xl:overflow-hidden lg:overflow-hidden md:overflow-hidden">
      <div className="flex flex-row 2xl:w-screen 2xl:h-screen xl:h-screen lg:w-screen lg:h-screen md:w-screen md:h-screen sm:w-screen sm:h-screen xl:w-screen">
        <Sidebar />
        <div className="w-screen h-screen overflow-auto">
          <Topbar />
          <div className="flex float-right pr-8 pt-2">
            <div className="flex w-full rounded-md bg-gradient-to-r from-[#3dc1fb] via-[#ff00f5] to-[#0feffd] pb-0.5">
              <input
                className="focus:outline-none"
                type="search"
                placeholder="Search"
                name="search"
                id="search"
                style={{
                  color: "white",
                  backgroundColor: "#343434",
                }}
              />
            </div>
            <img src={Search} alt="" />
          </div>
          <div className="py-16">
            <div className="w-[95%] m-auto justify-center h-auto grid 2xl:grid-flow-row 2xl:grid-cols-4 2xl:gap-x-24 2xl:gap-y-20 xl:grid-flow-row xl:grid-cols-3 xl:gap-x-8 xl:gap-y-20 md:grid-cols-3 md:gap-x-16 md:gap-y-12 sm:grid-cols-2 sm:gap-x-16 sm:gap-y-12 pt-5">
              {moodboards && moodboards.map((mbds, index) => (
                <div key={index} className="flex flex-col text-white bg-neutral-800 pb-2 rounded-md items-center justify-center">
                  <div className="flex flex-col gap-0.5 w-full">
                    <Link to={`/room-viewdetail/${mbds.id}`} target="_blank" >
                    <div className="flex flex-row h-[220px] bg-white relative rounded-md">
                      <img className="flex w-full rounded-t-md bg-white" src={mbds.previewurl ? mbds.previewurl : ""} alt="" />
                      <div className="flex absolute top-0 left-0">
                        <div className="flex h-max px-2 py-1 bg-neutral-500 text-white opacity-70 rounded-br-md">Available</div>
                      </div>
                    </div>
                    </Link>
                    {mbds.name && mbds.name !== "" ? (
                      <div className="flex text-left text-lg px-2  relative">
                        {mbds.name}
                      </div>
                      ) : (
                        <div className="flex text-left px-2 text-lg relative">Undefined</div>
                      )}
                      {mbds.eliyaid && mbds.eliyaid !== "" ? (
                      <div className="flex text-left text-base px-2 text-[#f69eaa] relative">
                        {mbds.eliyaid}
                      </div>
                      ):(
                        <div className="flex text-left px-2 text-base text-[#f69eaa] relative">Undefined</div>
                      )}
                  </div>
                  {/* <Button
                    style={{
                      backgroundColor: "#F2D399",
                      borderRadius: "20px",
                      width: "70%",
                      fontWeight: "700",
                    }}
                    onClick={() => AddToSTudio(mbds.id)}
                  >
                    +Add to Studio
                  </Button> */}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RoomView;
