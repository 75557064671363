import React, { useState, useEffect } from "react";
import Sidebar from "../layouts/sidebar";
import Topbar from "../layouts/topbar";
import style from "./roomViewDetail.module.css";
import ThreeDot from "../public/lottie/ThreeDot.svg";
import { Button } from "@material-ui/core";
import "@google/model-viewer";
import { supabase } from "../client";
import { useParams, Link } from "react-router-dom";

const RoomViewDetail = ({ history }) => {
  const role = localStorage.getItem("role");
  const [moodboard, setMoodboard] = useState([]);
  const [mprice, setMprice] = useState("");
  const { id } = useParams();
  const [details, setDetails] = useState({});
  const [eliyaID, setELiyaID] = useState("");
  const userID = localStorage.getItem("user");
  const [Materials, setMaterials] = useState([]);
  // console.log(id)
  const getData = async () => {
    let moodBoardData = [];
    const moodboardData = await supabase
      .from("moodboards")
      .select("*")
      .eq("id", id);
    const price = moodboardData.data[0].price;
    const modeller = moodboardData.data[0].modellerid;
    if (moodboardData.data[0].materials) {
      const textures = await supabase
        .from("textureAssets")
        .select("id,textureName, textureImage, reffcode")
        .in("reffcode", moodboardData.data[0].materials);
      // console.log(textures)
      setMaterials(textures.data);
    }

    setELiyaID(moodboardData.data[0].eliyaid);
    const lakhs = price / 100000;
    const refined = lakhs.toFixed(1);
    setMprice(`${refined} lakhs`);
    if (moodboardData.data[0].products.length !== 0) {
      const products = await supabase
        .from("asset_data")
        .select(
          "id,product_db_id,pixelID,productBrand,productImage,productColor,modelStatus,productName,glbLink"
        )
        .in("product_db_id", moodboardData.data[0].products);
      moodBoardData.push({ moodboard: moodboardData, products: products });
    } else {
      moodBoardData.push({ moodboard: moodboardData, products: [] });
    }

    // console.log(products.data)
    setMoodboard(moodBoardData);
    setDetails(moodboardData.data[0]);
  };

  const currentTime = new Date().getTime() / 1000;

  useEffect(() => {
    if (userID) {
      const expiry = localStorage.getItem("expires_at");
      if (currentTime < expiry) {
        getData();
      } else {
        alert("Session timed Out");
        localStorage.clear();
        history.push("/login");
      }
    } else {
      alert("Login to Continue");
      history.push("/login");
    }
  }, []);

  const AddToSTudio = async (id) => {
    const check = await supabase
      .from("moodboards")
      .select("modellerid,roomstatus")
      .eq("id", id);

    if (check.data[0].modellerid === "unassigned") {
      await supabase
        .from("moodboards")
        .update({ modellerid: userID, roomstatus: "assigned" })
        .eq("id", id);
      history.push(`/mystudio/roomview/${id}`);
    } else {
      alert("Sorry Already Picked");
      window.location.reload(false);
    }
  };

  return (role !== "Admin") ? (
    <div className="flex flex-row bg-neutral-900 xl:overflow-hidden lg:overflow-hidden md:overflow-hidden">
      <div className="flex flex-row 2xl:w-screen 2xl:h-screen xl:h-screen lg:w-screen lg:h-screen md:w-screen md:h-screen sm:w-screen sm:h-screen xl:w-screen">
        <Sidebar />
        <div className="w-screen h-screen overflow-auto">
          <Topbar />
          <div className="flex flex-col pl-3 pt-4 pr-4 2xl:gap-14 xl:gap-8 lg:gap-6 md:gap-4">
            <div className="flex flex-row 2xl:gap-20 xl:gap-8 lg:gap-6 md:gap-2 text-white">
              <div className="flex 2xl:w-[1050px] 2xl:h-[620px] xl:w-[700px] xl:h-[500px] lg:w-[550px] lg:h-[620px] md:w-[500px] md:h-[620px] rounded-lg">
                <img className="w-full object-contain rounded-lg" src={details.previewurl} alt="" />
              </div>
              <div className="flex flex-col gap-10">
                <div
                  style={{
                    position: "relative",
                    display: "flex",
                    width: "100%",
                    justifyItems: "right",
                    justifyContent: "right",
                  }}
                >
                  {
                    <Button
                      style={{
                        color:"white",
                        backgroundColor: "teal",
                        fontWeight: "700",
                        borderRadius: "50vh",
                      }}
                      onClick={() => AddToSTudio(id)}
                    >
                      +Add to Studio
                    </Button>
                  }
                </div>
                <div className="flex flex-col gap-4 pb-2 2xl:px-5 xl:px-2 pt-2 2xl:text-lg xl:text-md lg:text-sm rounded-md justify-center bg-neutral-700">
                  <div className={style.roomname}>
                    <div>
                      Moodboard Name :
                      <span className="2xl:text-2xl xl:text-lg lg:text-xl md:text-lg justify-center items-center">
                        &nbsp;{details.name}
                      </span>
                    </div>
                  </div>
                  <div className={style.roomstyle}>
                    <div>
                      Style :
                      <span className="2xl:text-2xl xl:text-lg lg:text-lg md:text-lg">
                        &nbsp;{details.styletype}
                      </span>
                    </div>
                  </div>
                  <div className={style.roomtype}>
                    <div>
                      Room Type :
                      <span className="2xl:text-2xl xl:text-lg lg:text-lg md:text-lg">
                        &nbsp; {details.roomtype}
                      </span>
                    </div>
                  </div>
                </div>
                <div className={style.texturelist}>
                  <h3>Textures :</h3>
                  <div className="grid grid-flow-row 2xl:grid-cols-3 2xl:gap-8 xl:grid-cols-2 xl:gap-4 lg:grid-cols-2 lg:gap-4 md:grid-cols-2 md:gap-2">
                    {Materials &&
                      Materials.map((mat) => (
                        <div className="flex flex-col gap-2">
                          <img
                            className="flex w-[140px] h-[140px] border"
                            src={mat.textureImage[0]}
                            alt=""
                          />
                          <div className=" text-ellipsis w-[100px] h-[50px] overflow-hidden">
                            {mat.textureName}
                          </div>
                        </div>
                      ))}
                    {/* <div className="flex flex-col gap-2">
                      <img
                        className="flex w-[140px] h-[140px] border"
                        src=""
                        alt=""
                      />
                      <div>Leather_glass</div>
                    </div>
                    <div className="flex flex-col gap-2">
                      <img
                        className="flex w-[140px] h-[140px] border"
                        src=""
                        alt=""
                      />
                      <div>Leather_glass</div>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
            <div className="flex flex-col text-white gap-20">
              <div className="flex flex-row pr-4">
                <div className="w-max grid grid-flow-row 2xl:grid-cols-2 xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-1 2xl:gap-x-56 xl:gap-x-16 lg:gap-x-20 gap-y-10 pr-4">
                  {JSON.stringify(moodboard) !== "[]" &&  moodboard[0].products.data &&
                    moodboard[0].products.data.map((product) => (
                      <div className="w-full flex flex-row rounded-md bg-neutral-700 items-center 2xl:pl-5 xl:pl-3 lg:pl-3 md:pl-3 pr-6">
                        <div className="flex flex-row 2xl:gap-10 xl:gap-4 lg:gap-4 md:gap-4 items-center">
                          <Link to={`/product/${product.id}`} target="_blank">
                            <div className="flex w-[100px] h-[100px] relative top-[-20px] rounded-sm bg-white">
                              <img src={product.productImage[0]} alt="" />
                            </div>
                          </Link>
                          <div className="w-max flex flex-col gap-4 justify-center items-center">
                            <div> {product.productName}</div>
                            <div>{product.productBrand}</div>
                          </div>
                        </div>
                        <div className="w-full flex flex-row 2xl:gap-8 xl:gap-6 items-center justify-end">
                          <div className="flex text-lg border-4 px-12 py-1 rounded-full text-black font-bold border-orange-400 items-center justify-center bg-oran">
                            {product.modelStatus}
                          </div>
                          <div>
                            <img src={ThreeDot} alt="" />
                          </div>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <div className="flex flex-row bg-neutral-900 xl:overflow-hidden lg:overflow-hidden md:overflow-hidden">
      <div className="flex flex-row 2xl:w-screen 2xl:h-screen xl:h-screen lg:w-screen lg:h-screen md:w-screen md:h-screen sm:w-screen sm:h-screen xl:w-screen">
        <Sidebar />
        <div className={style.pagebody}>
          <Topbar />
          <div className="flex flex-col pl-5 pt-4 pr-4 2xl:gap-14 xl:gap-12 lg:gap-6 md:gap-4">
            <div className="flex flex-row 2xl:gap-20 xl:gap-8 lg:gap-6 md:gap-2 text-white">
              <div className="2xl:w-[1050px] 2xl:h-[620px] xl:w-[700px] xl:h-[550px] lg:w-[550px] lg:h-[620px] md:w-[500px] md:h-[620px] bg-white rounded-lg">
                <model-viewer
                  style={{ width: "100%", height: "100%" }}
                  alt="Neil Armstrong's Spacesuit from the Smithsonian Digitization Programs Office and National Air and Space Museum"
                  src="https://pixel-mortar.s3.ap-south-1.amazonaws.com/+mx3d-assests/PXM_ABS_020b9aee.glb"
                  shadow-intensity="1"
                  ar-scale="fixed"
                  enviroment-image="neutral"
                  camera-controls
                  touch-action="pan-y"
                  auto-rotate
                  ar-modes="webxr scene-viewer quick-look"
                  ar
                ></model-viewer>
              </div>
              <div className="flex flex-col gap-10">
                <div className="flex flex-col gap-4 pb-2 pr-5 pt-2 2xl:text-lg xl:text-md lg:text-sm rounded-md justify-center pl-5 bg-neutral-700">
                  <div className={style.roomname}>
                    <div>
                      Moodboard Name :
                      <span className="2xl:text-3xl xl:text-xl lg:text-xl md:text-lg">
                        &nbsp;Art Deco Living Room
                      </span>
                    </div>
                  </div>
                  <div className={style.roomstyle}>
                    <div>
                      Style :
                      <span className="2xl:text-3xl xl:text-2xl lg:text-xl md:text-lg">
                        &nbsp;Art Deco
                      </span>
                    </div>
                  </div>
                  <div className={style.roomtype}>
                    <div>
                      Room Type :
                      <span className="2xl:text-3xl xl:text-2xl lg:text-xl md:text-lg">
                        &nbsp;Living Room
                      </span>
                    </div>
                  </div>
                </div>
                <div className="flex flex-col gap-8">
                  <img
                    className="w-full 2xl:h-80 xl:h-72 bg-white"
                    src=""
                    alt=""
                  />
                  <div className="flex flex-row justify-between">
                    <button className="flex px-10 py-2 border-4 text-black text-md font-semibold rounded-full hover:bg-borderPink bg-buttonPink border-borderPink">
                      QC Failed
                    </button>
                    <button className="flex px-10 py-2 border-4 text-black text-md font-semibold rounded-full hover:bg-borderGrey bg-buttonGrey border-borderGrey">
                      QC Passed
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex flex-col text-white gap-20">
              <div className="flex 2xl:justify-center flex-row pr-4">
                <div className="2xl:items-center grid grid-flow-row 2xl:grid-cols-2 xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-1 2xl:gap-x-56 xl:gap-x-16 lg:gap-x-20 gap-y-10 pr-2">
                  <div className="w-max flex flex-row rounded-md bg-neutral-700 items-center 2xl:gap-28 xl:gap-10 lg:gap-6 md:gap-10 2xl:pl-5 xl:pl-3 lg:pl-3 md:pl-3 pr-2">
                    <div className="w-max flex flex-row 2xl:gap-10 xl:gap-4 lg:gap-4 md:gap-4 items-center">
                      <div className="flex w-[100px] h-[100px] relative top-[-20px] rounded-sm bg-white">
                        <img src="" alt="" />
                      </div>
                      <div className="w-max flex flex-col gap-4 justify-center items-center">
                        <div> Side Table Lamp</div>
                        <div>House of Rugs</div>
                      </div>
                    </div>
                    <div className="w-max flex flex-row justify-end gap-2 items-center pr-3">
                      <div className="flex text-lg border-4 px-12 py-1 rounded-full text-black font-bold border-orange-400 items-center justify-center bg-oran">
                        Ar Ready
                      </div>
                      <div className="hover:bg-buttonGrey w-4 h-10 flex items-center justify-center rounded-t-full rounded-b-full">
                        <img src={ThreeDot} alt="" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RoomViewDetail;
