import React, { useEffect, useState } from "react";
import style from "./modelsGrab.module.css";
import Sidebar from "../layouts/sidebar";
import Topbar from "../layouts/topbar";
import Search from "../public/lottie/chevron_right.svg";
import { Button } from "@material-ui/core";
import { Link, useHistory } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";
import "bootstrap/dist/css/bootstrap.min.css";
import { supabase } from "../client";
import Pagination from "../layouts/pagination";
import { v4 as uuidv4 } from "uuid";
import Lottie from "react-lottie";
import Loading from '../public/lottie/Loading.json'

const ModelsGrab = ({ history }) => {
  // const history1 = useHistory()
  const [user, setUser] = useState(null);
  const [models, setModels] = useState(null);
  const [Option, setOption] = useState("pixelID");
  const [brandList, setBrandList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const objectsPerPage = 50;
  const username = localStorage.getItem("username");
  const userID = localStorage.getItem("user");
  let priorities = [
    { id: 1, name: "low" },
    { id: 2, name: "medium" },
    { id: 3, name: "high" },
    { id: 4, name: "sprint" },
  ];
  let difficulties = [
    { id: 1, name: "L1" },
    { id: 2, name: "L2" },
    { id: 3, name: "L3" },
    { id: 4, name: "L4" },
  ];

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: Loading,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  useEffect(() => {
    if (userID) {
      const expiry = localStorage.getItem("expires_at");
      const now = new Date().getTime() / 1000;
      if (now < expiry) {
        setUser(userID);
        // console.log(now,expiry)
      } else {
        alert("Session timed Out");
        localStorage.clear();
        history.push("/login");
      }
    } else {
      alert("Login to Continue");
      history.push("/login");
    }
  },[userID, history]);

  const fetchBrands = async() => {
    const {data:items} = await supabase
      .from("asset_data")
      .select("productBrand, modellerName, project")
      .eq("modellerName", "Unassigned")
      .eq("project", "Internal")
    const distinctBrands = [...new Set(items.map((item) => item.productBrand))]
    setBrandList(distinctBrands)
  }

  useState(() => {
    fetchBrands()
  },[])

  const [filter1, setFilter1] = useState(null);
  const [filter2, setFilter2] = useState(null);
  const [filter3, setFilter3] = useState(null)
  const [searchTerm, setSearchTerm] = useState("");
  const handleSearchTermChange = (event) => {
    setSearchTerm(event.target.value);
  };
  useEffect(() => {
    fetchData();
  }, [filter1, filter2, filter3, searchTerm, Option]);

  const fetchData = async () => {
    let data;
    let query = supabase
      .from("asset_data")
      .select("id,productImage,pixelID,productPriority,difficultyLevel,productName")
      .eq("modellerName", "Unassigned")
      .eq("project", "Internal")

      if (filter1) {
        query = query.eq("productPriority", filter1);
        // countQuery = countQuery.eq("productPriority", filter2);
      }
      if (filter2) {
        query = query.eq("difficultyLevel", filter2);
        // countQuery = countQuery.eq("difficultyLevel", filter3);
      }
      if (filter3) {
        query = query.eq("productBrand", filter3);
        // countQuery = countQuery.eq("productBrand", filter4);
      }
      if (searchTerm) {
        query = query.ilike(`${Option}`, `%${searchTerm}%`)
        // countQuery = countQuery.ilike(`${Option}`, `%${searchTerm}%`);
      }
      data = await query;
      if(data.data.length > 0){
        setModels(data.data)
      } else if(data.data.length === 0){
        setModels([])
      }

    // if (!filter1 && !filter2 && !searchTerm) {
    //   const data = await supabase
    //     .from("asset_data")
    //     .select(
    //       "id,productImage,pixelID,productPriority,difficultyLevel,productName"
    //     )
    //     .eq("modellerName", "Unassigned")
    //     .eq("project", "Internal");
    //   const modeldata = JSON.parse(JSON.stringify(data));
    //   console.log(modeldata);
    //   setModels(modeldata.data);
    // } else if (filter1 && filter2) {
    //   const data = await supabase
    //     .from("asset_data")
    //     .select(
    //       "id,productImage,pixelID,productPriority,difficultyLevel,productName"
    //     )
    //     .eq("modellerName", "Unassigned")
    //     .eq("project", "Internal")
    //     .eq("difficultyLevel", filter2)
    //     .eq("productPriority", filter1)
    //     .ilike(`${Option}`, `%${searchTerm}%`);
    //   setModels(data.data);
    //   // const modeldata = JSON.parse(JSON.stringify(data))
    //   // const modeldata = data.data.filter(
    //   //   (element) =>
    //   //     element.pixelID.toLowerCase().includes(searchTerm.toLowerCase()) ||
    //   //     element.productName.toLowerCase().includes(searchTerm.toLowerCase())
    //   // );
    //   // setModels(modeldata);
    //   // console.log(data);
    // } else if (filter1) {
    //   const data = await supabase
    //     .from("asset_data")
    //     .select(
    //       "id,productImage,pixelID,productPriority,difficultyLevel,productName"
    //     )
    //     .eq("modellerName", "Unassigned")
    //     .eq("project", "Internal")
    //     .eq("productPriority", filter1)
    //     .ilike(`${Option}`, `%${searchTerm}%`);
    //   setModels(data.data);
    //   // const modeldata = data.data.filter(
    //   //   (element) =>
    //   //     element.pixelID.toLowerCase().includes(searchTerm.toLowerCase()) ||
    //   //     element.productName.toLowerCase().includes(searchTerm.toLowerCase())
    //   // );
    //   // setModels(modeldata);
    //   // console.log(data);
    // } else if (filter2) {
    //   const data = await supabase
    //     .from("asset_data")
    //     .select(
    //       "id,productImage,pixelID,productPriority,difficultyLevel,productName"
    //     )
    //     .eq("modellerName", "Unassigned")
    //     .eq("project", "Internal")
    //     .eq("difficultyLevel", filter2)
    //     .ilike(`${Option}`, `%${searchTerm}%`);
    //   setModels(data.data);
    //   // const modeldata = data.data.filter(
    //   //   (element) =>
    //   //     element.pixelID.toLowerCase().includes(searchTerm.toLowerCase()) ||
    //   //     element.productName.toLowerCase().includes(searchTerm.toLowerCase())
    //   // );
    //   // setModels(modeldata);
    //   // console.log(data);
    // } else if (searchTerm) {
    //   const data = await supabase
    //     .from("asset_data")
    //     .select(
    //       "id,productImage,pixelID,productPriority,difficultyLevel,productName"
    //     )
    //     .eq("modellerName", "Unassigned")
    //     .eq("project", "Internal")
    //     .ilike(`${Option}`, `%${searchTerm}%`);
    //   setModels(data.data);
    //   // const modeldata = data.data.filter(
    //   //   (element) =>
    //   //     element.pixelID.toLowerCase().includes(searchTerm.toLowerCase()) ||
    //   //     element.productName.toLowerCase().includes(searchTerm.toLowerCase())
    //   // );
    //   // console.log(data);
    // }
  };

  const handleFilter1Change = (event) => {
    setFilter1(event.target.value);
  };
  const handleFilter2Change = (event) => {
    setFilter2(event.target.value);
  };
  const handleFilter3Change = (event) => {
    setFilter3(event.target.value)
  }

  //   const model = JSON.parse(models);
  const totalPages = Math.ceil(models?.length / objectsPerPage);
  //   console.log(models)

  const startIndex = (currentPage - 1) * objectsPerPage;
  const endIndex = startIndex + objectsPerPage;
  const currentData = models?.slice(startIndex, endIndex);
  const address = JSON.parse(localStorage.getItem("address"));

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const [data, setData] = useState(null);

  const handleClick = async (item) => {
    const data1 = await supabase
      .from("asset_data")
      .select("modellerID,modelStatus")
      .eq("id", item);
    if (data1) {
      if (data1.data[0].modelStatus === "un_assigned") {
        // const add = await fetch("https://geolocation-db.com/json/", {
        //   method: "GET",
        // });
        // const resp = await add.json();
        // console.log(resp.IPv4 , resp.city)

        await supabase.from("logs").insert({
          id: uuidv4(),
          modellerId: userID,
          productId: item,
          action: "assigned",
          ipAdd: address.IPv4,
          location: address.city,
        });

        const { data, error } = await supabase
          .from("asset_data")
          .update({
            modellerName: username,
            modellerID: userID,
            modelStatus: "assigned",
          })
          .eq("id", item);
        fetchData();
      } else {
        alert("Sorry model has been picked");
        window.location.reload(false);
      }
    }
  };
  const handleOption = (event) => {
    setOption(event.target.value);
  };
  return (
    <>
      <div className="flex flex-row   bg-neutral-900 lg:overflow-hidden">
        <div className="flex flex-row 2xl:w-screen 2xl:h-screen xl:w-screen lg:h-screen">
          <Sidebar />
          <div className={style.body}>
            <Topbar />
            <div className="flex float-right pr-8">
              <div className="flex w-full rounded-md bg-gradient-to-r from-[#3dc1fb] via-[#ff00f5] to-[#0feffd] pb-0.5">
                <div className="relative">
                  <select
                    className="absolute focus:outline-none right-2 top-1 rounded-sm"
                    style={{ color: "black" }}
                    value={Option || "pixelID"}
                    onChange={handleOption}
                  >
                    <option style={{ color: "black" }} value={"pixelID"}>
                      PixelID
                    </option>
                    <option style={{ color: "black" }} value={"productName"}>
                      Name
                    </option>
                  </select>
                  <input
                    type="text"
                    value={searchTerm}
                    onChange={handleSearchTermChange}
                    placeholder="Search"
                    name="search"
                    id="search"
                    className="rounded-sm focus:outline-none"
                    style={{
                      color: "white",
                      backgroundColor: "#343434",
                    }}
                  />
                </div>
              </div>
              <img src={Search} alt="" />
            </div>
            <div className="flex flex-row pl-5">
              <div className="flex flex-row 2xl:gap-16 gap-8 2xl:w-5/6 xl:w-full">
                <div className="flex 2xl:w-1/6 w-1/6">
                  <div className="flex w-full">
                    <select
                      className="w-full focus:outline-none rounded-sm bg-neutral-500 xl:text-lg lg:text-md font-semibold items-center tracking-wider text-white 
                    text-center bg-gradient-to-br from-neutral-700 to-neutral-500 bg-contain"
                      style={{ color: "black" }}
                      value={filter1 || ""}
                      onChange={handleFilter1Change}
                    >
                      <option style={{ color: "black" }} value={""}>
                        Priority
                      </option>
                      {priorities.map((priority) => (
                        <option
                          style={{ color: "black" }}
                          key={priority.id}
                          value={priority.name}
                        >
                          {priority.name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="flex 2xl:w-1/6 w-1/6">
                  <div className="flex w-full">
                    <select
                      className="w-full focus:outline-none rounded-sm text-lg font-semibold tracking-wide bg-neutral-500 text-white 
                    text-center bg-gradient-to-br from-neutral-700 to-neutral-500 bg-contain"
                      style={{ color: "black" }}
                      value={filter2 || ""}
                      onChange={handleFilter2Change}
                    >
                      <option style={{ color: "black" }} value={""}>
                        Difficulty
                      </option>
                      {difficulties.map((difficulty) => (
                        <option
                          style={{ color: "black" }}
                          key={difficulty.id}
                          value={difficulty.name}
                        >
                          {difficulty.name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="flex 2xl:w-1/5 w-1/6">
                  <div className="flex w-full">
                    <select
                      className="w-full focus:outline-none rounded-sm text-lg font-semibold tracking-wide bg-neutral-500 text-white 
                    text-center bg-gradient-to-br from-neutral-700 to-neutral-500 bg-contain"
                    style={{color:'black'}}
                    value={filter3 || ""}
                    onChange={handleFilter3Change}
                    >
                      <option style={{ color: "black" }} value={""}>
                        Brand
                      </option>
                      {brandList && brandList.map((brand, index) => (
                        <option value={brand} key={index} style={{color:'black'}}>
                          {brand}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                {/* <div className='flex 2xl:w-1/6 w-1/6'>
                <div className='flex w-full'>
                  <select className="w-full rounded-sm text-lg font-semibold tracking-wide bg-neutral-500 text-white 
                    text-center bg-gradient-to-br from-neutral-700 to-neutral-500 bg-contain">
                    <option>Difficulty</option>
                  </select>
                </div>
              </div> */}
              </div>
            </div>
              {currentData?.length === 0 ? (
                <div style={{ color: "white", fontSize: "20px" }}>
                  {" "}
                  No products Available
                </div>
              ) : (
                currentData ? (
                  <div className="w-full justify-center pt-20 grid gap-y-24 grid-flow-row xl:grid-cols-3 2xl:grid-cols-5 2xl:gap-x-8 pl-35">
                  {currentData?.map((item, index) => (
                  <div key={index} className="flex w-full justify-center">
                    <div className="relative 2xl:w-[280px] xl:w-[300px] flex flex-col gap-2.5 rounded-md bg-[#343434] items-center">
                      <div className="relative w-full flex flex-col gap-2.5 pb-1 items-center">
                        <Link to={`/product/${item.id}`} target="_blank">
                          <div className="relative rounded-t-md bg-white p-5 w-max items-center justify-center">
                            {item.productImage && item.productImage[0] && (
                            <img
                              className="rounded-md w-[184px] h-[184px]"
                              style={{
                                borderRadius: "5px",
                                position: "relative",
                              }}
                              src={item.productImage[0]}
                              alt="product_image"
                            />
                            )}
                            <div className="absolute bg-[#1d1d1d] bg-opacity-50 rounded-bl-md py-1 px-2 flex flex-row gap-0.5 right-0 top-0 justify-end">
                              <div className="flex">
                                <img
                                  style={{ scale: "0.65" }}
                                  src={`https://gurtwepkenhsrmlkypvw.supabase.co/storage/v1/object/public/profileImages/${item.productPriority}.svg`}
                                  alt={`${item.productPriority}`}
                                />
                              </div>
                              <div className="text-white">{item.productPriority}</div>
                            </div>
                          </div>
                        </Link>
                        <div className="flex flex-row w-full justify-around px-2">
                          <div className="relative w-1/2 whitespace-nowrap overflow-hidden text-ellipsis text-white">
                            {item.productName}
                          </div>
                          <div className="flex flex-row w-1/2 justify-around">
                            <div className={style.priority}>
                              {(item.difficultyLevel === "not_available" || item.difficultyLevel === null) ? (
                                <div>{item.difficultyLevel}</div>
                                ):(
                                <img
                                  style={{ scale: "2" }}
                                  src={`https://gurtwepkenhsrmlkypvw.supabase.co/storage/v1/object/public/profileImages/${item.difficultyLevel}.svg`}
                                  alt={`${item.difficultyLevel}`}
                                />
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="flex w-full text-white px-2">
                          {item.pixelID}
                        </div>
                      </div>
                      {/* <Button
                        onClick={() => handleClick(item.id)}
                        // onClick={() => alert("Temporarily disabled")}
                        style={{
                          fontSize: "12px",
                          fontWeight: "bold",
                          top: "-20px",
                          backgroundColor: "#F2D399",
                          borderRadius: "50vh",
                          width: "140px",
                          height: "30px",
                        }}
                      >
                        + Add to Studio
                      </Button> */}
                    </div>
                  </div>
                ))}
                </div>
                ):(
                  <div className="flex w-full h-auto justify-center items-center">
                    <Lottie width={300} height={200} style={{height:'400px'}} options={defaultOptions}/>
                  </div>
                )
              )}
            <Pagination
              currentPage={currentPage}
              totalPages={totalPages}
              onPageChange={handlePageChange}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default ModelsGrab;
