import React, { useRef, useEffect, useState } from "react";
import Sidebar from "../layouts/sidebar";
import Carousel from "react-bootstrap/Carousel";
import Topbar from "../layouts/topbar";
import style from "./myModalProductView.module.css";
import { Button } from "@material-ui/core";
import _3DIcon from "../public/lottie/3DIcon.svg";
import ThreeDot from "../public/lottie/ThreeDot.svg";
import Dropdown from "react-bootstrap/Dropdown";
import "bootstrap/dist/css/bootstrap.min.css";
import { useParams, Link } from "react-router-dom";
import { supabase } from "../client";
import { v4 as uuidv4 } from "uuid";
import ModelUpload from "../services/modelupload";
import GlbUpload from "../services/glbUpload";
import ModelActions from "../services/modelActions";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";

const MyModalProductView = ({ history }) => {
  const fileInputRef = useRef();
  const Id = useParams();
  const userID = localStorage.getItem("user");
  const [model, setModel] = useState(null);
  const [images, setImages] = useState([]);
  const [product, setProduct] = useState([]);
  const [user, setUser] = useState(null);
  const [pixelid, setPixelid] = useState(null);
  const [noblendfile, setNoblend] = useState(null);
  const [blendfiles, setBlendfiles] = useState(null);
  const [modelId, setModelID] = useState(null);
  const blend_link = `https://${process.env.REACT_APP_BLENDER_BUCKET}.s3.ap-south-1.amazonaws.com`;
  const address = JSON.parse(localStorage.getItem("address"));
  const role = localStorage.getItem("role");

  useEffect(() => {
    supabase
      .from("asset_data")
      .select("*")
      .eq("id", Id.id)
      .then(({ data }) => {
        setModel(JSON.stringify(data));
        const product1 = JSON.parse(model);
        const modellerName = product1[0]["modellerName"]
        const modellerID = product1[0]["modellerID"];
        setModelID(product1[0]["pixelID"]);
        const blength = product1[0]["blender_files"].length;
        const images1 = product1[0]["productImage"];
        setBlendfiles(product1[0]["blender_files"]);
        if (modellerID === userID) {
          setImages(images1);
          setProduct(product1[0]);
          setNoblend(blength);
          setPixelid(product1[0].pixelID);
        } else if (role === "Admin") {
          setImages(images1);
          setProduct(product1[0]);
          setNoblend(blength);
          setPixelid(product1[0].pixelID);
        } else {
          alert("You are not authorized");
          history.push(`/product/${Id.id}`);
        }
      });
  }, [model]);

  const currentTime = new Date().getTime() / 1000;
  useEffect(() => {
    if (userID) {
      const expiry = localStorage.getItem("expires_at");
      if (currentTime < expiry) {
        setUser(userID);
        // console.log(currentTime,expiry)
      } else {
        alert("Session timed Out");
        localStorage.clear();
        history.push("/login");
      }
    } else {
      alert("Login to Continue");
      history.push("/login");
    }
  });

  const removeModel = async (id) => {
    const { data, error } = await supabase
      .from("asset_data")
      .update({
        modellerName: "unassigned",
        modellerID: "unassigned",
        modelStatus: "un_assigned",
      })
      .eq("id", id);
    // const add = await fetch("https://geolocation-db.com/json/", {
    //   method: "GET",
    // });
    // const resp = await add.json();
    // console.log(resp.IPv4 , resp.city)

    await supabase.from("logs").insert({
      id: uuidv4(),
      modellerId: userID,
      productId: id,
      action: "removed",
      ipAdd: address.IPv4,
      location: address.city,
    });
    if (error) {
      console.log(error);
    } else {
      alert("model has been removed");
      history.push(`/product/${id}`);
    }
  };
  const [variant, setVariant] = useState(null);
  useEffect(() => {
    supabase
      .from("asset_data")
      .select("*")
      .eq("id", Id.id)
      .then(({ data }) => {
        setModel(JSON.stringify(data));
        const product1 = JSON.parse(model);
        // console.log(product1[0]["productImage"])
        const images1 = product1[0]["productImage"];
        setImages(images1);
        setProduct(product1[0]);
        supabase
          .from("asset_data")
          .select(
            "id,modelStatus,productName,productBrand,productImage,pixelID"
          )
          .eq("productParentID", product1[0]["productParentID"])
          .then(({ data }) => {
            if (data.length > 1) {
              setVariant(data);
            } else {
              setVariant(null);
            }
          });
      });
  }, [model]);

  function FileSize(bytes) {
    return (bytes / 1048576).toFixed(2);
  }

  return (
    <div className="flex flex-row bg-neutral-900 xl:overflow-hidden lg:overflow-hidden md:overflow-hidden">
      <div className="flex flex-row 2xl:w-screen 2xl:h-screen xl:h-screen lg:w-screen lg:h-screen md:w-screen md:h-screen xl:w-screen">
        <Sidebar />
        <div className="w-screen h-screen overflow-auto">
          <Topbar />
          <div className="flex flex-row w-full py-10 text-white">
            <div className="flex flex-col 2xl:w-1/2 w-2/4 gap-6">
              <Carousel className="w-full px-10 pb-10 ">
                {images.map((image) => (
                  <Carousel.Item>
                    <div className="flex w-full">
                      <TransformWrapper>
                        <TransformComponent>
                          <img
                            src={image}
                            style={{
                              height: "100%",
                              width: "100%",
                            }}
                            alt=""
                          />
                        </TransformComponent>
                      </TransformWrapper>
                    </div>
                      <a className="absolute bottom-0 right-0 z-20" href={image} download>
                        <img src={`${process.env.REACT_APP_SUPABASE_URL}/storage/v1/object/public/profileImages/download.svg`} alt=""></img>
                      </a>
                    <div
                      style={{
                        display: "flex",
                        zIndex: "2",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                    </div>
                  </Carousel.Item>
                ))}
              </Carousel>
              {variant?.length > 0 ? (
                <>
                  <div className="flex flex-col pl-10">
                    <div className="flex flex-row gap-2">
                      <div className={style.pin}>G</div>
                      <div className={style.varianttext}>Varient Model</div>
                      <div></div>
                    </div>
                  </div>
                  <div className="flex w-full flex-col gap-12 pt-5 px-4">
                    {variant?.map((variant, index) => (
                      <Link
                        style={{ textDecoration: "none" }}
                        to={`/product/${variant.id}`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <div
                          key={index}
                          className="flex rounded-md bg-gradient-to-r from-neutral-800 to-neutral-600"
                        >
                          <div className="flex flex-row w-2/3 2xl:gap-4 gap-2 2xl:pl-4 pl-2 items-center">
                            <div className="relative -top-6">
                              <img
                                style={{
                                  height: "120px",
                                  width: "120px",
                                  borderRadius: "5px",
                                }}
                                src={variant.productImage[0]}
                                alt=""
                              />
                            </div>
                            <div className="flex flex-col gap-3 text-lg text-white w-1/2">
                              <div className="whitespace-nowrap overflow-hidden text-ellipsis">
                                {variant.productName}
                              </div>
                              <div className="flex capitalize">
                                {variant.productBrand}
                              </div>
                            </div>
                          </div>
                          <div className="flex flex-row gap-2 items-center justify-center w-1/3">
                            <div className="border-4 border-assignBorder bg-assignBg 2xl:px-4 py-1 px-2 text-lg font-semibold capitalize rounded-full text-black">
                              {variant.modelStatus}
                            </div>
                            <div className="flex h-10 w-4 justify-center items-center hover:bg-assignBorder rounded-t-full rounded-b-full">
                              <img src={ThreeDot} alt="" />
                            </div>
                          </div>
                        </div>
                      </Link>
                    ))}
                  </div>
                </>
              ) : (
                ""
              )}
            </div>
            <div className="flex flex-col 2xl:gap-10 xl:gap-4 w-1/2 xl:pr-6">
              <div className="flex w-full 2xl:px-8 flex-row 2xl:gap-20 xl:gap-8 items-center">
                <div className="flex flex-row gap-2 justify-center items-center">
                  <div className={style.badge}>
                    <img
                      style={{ scale: "1.65" }}
                      src={`https://gurtwepkenhsrmlkypvw.supabase.co/storage/v1/object/public/profileImages/${product.difficultyLevel}.svg`}
                      alt=""
                    />
                  </div>
                  <div className={style.level}> {product.difficultyLevel}</div>
                </div>
                <div className="flex flex-row gap-1">
                  <div className={style.variantimage}>D</div>
                  <div className={style.varianttype}>Unique Model</div>
                </div>
                <div className="flex -flex-row gap-1 justify-center items-center">
                  <div className={style.priorityimage}>
                    <img
                      style={{ scale: "0.65" }}
                      src={`https://gurtwepkenhsrmlkypvw.supabase.co/storage/v1/object/public/profileImages/${product.productPriority}.svg`}
                      alt=""
                    />
                  </div>
                  <div className={style.prioritytype}>
                    {product.productPriority}
                  </div>
                </div>
                <div
                  style={{
                    position: "relative",
                  }}
                  className="Button"
                >
                  <Dropdown>
                    <Dropdown.Toggle
                      style={{
                        width: "160px",
                        backgroundColor: "tomato",
                        borderColor: "tomato",
                        color: "black",
                        fontWeight: "700",
                      }}
                      variant="success"
                      id="dropdown-basic"
                    >
                      Assigned
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      {(role !== "Admin") ? (
                        <Dropdown.Item onClick={() => removeModel(Id.id)}>
                          Remove
                        </Dropdown.Item>
                      ) : product.modellerName === "gnanavel" ? (
                        <Dropdown.Item onClick={() => removeModel(Id.id)}>
                          Remove {product.modellerName}
                        </Dropdown.Item>
                      ) : (
                        <Dropdown.Item>{product.modellerName}</Dropdown.Item>
                      )}
                      <Dropdown.Item href="#/action-2">Esclate</Dropdown.Item>
                      <Dropdown.Item href="#/action-3">Help</Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>
              <div className="flex w-full">
                <div className="flex w-full flex-col gap-3 rounded-md bg-neutral-700 p-4">
                  <div className="flex flex-row gap-2 items-center">
                    <div className="2xl:text-lg text-md">Product Name :</div>
                    <div className="2xl:text-2xl text-lg overflow-hidden text-ellipsis w-[20rem] whitespace-nowrap">
                      {product.productName}
                    </div>
                  </div>
                  <div className="flex flex-row gap-2 items-center">
                    <div className="2xl:text-lg text-md">Brand :</div>
                    <div className="2xl:text-2xl text-lg capitalize">
                      {product.productBrand}
                    </div>
                  </div>
                  <div className="flex flex-row gap-2 items-center">
                    <div className="2xl:text-lg text-md">Pixel ID :</div>
                    <div className="2xl:text-2xl text-lg capitalize">
                      {product.pixelID}
                    </div>
                  </div>
                  <div className="flex flex-row gap-2 items-center">
                    <div className="2xl:text-lg text-md">Product ID :</div>
                    <div className="2xl:text-2xl text-lg capitalize">
                      {product.productSku}
                    </div>
                  </div>
                  <div className="flex flex-row gap-2 items-center">
                    <div className="2xl:text-lg text-md">Dimension :</div>
                    <div className="2xl:text-2xl text-lg capitalize">
                      {product.productDimensions !== undefined
                        ? "L " +
                          product.productDimensions["l"] +
                          " " +
                          "X" +
                          " b " +
                          product.productDimensions["b"] +
                          " " +
                          "X" +
                          " h " +
                          product.productDimensions["h"] +
                          " " +
                          product.productDimensions["unit"]
                        : ""}
                    </div>
                  </div>
                  <div className="flex flex-row gap-2 items-center">
                    <div className="2xl:text-lg text-md">Color :</div>
                    <div className="2xl:text-2xl text-lg capitalize">
                      {product.productColor}
                    </div>
                  </div>
                  {/* <div className={style.material}>
                          <div style={{fontSize:'16px'}}>Material :</div>
                          <div style={{fontSize:'20px'}}>{product.material}</div>
                      </div> */}
                  {/* <div className={style.Style}>
                          <div style={{fontSize:'16px'}}>Style :</div>
                          <div style={{fontSize:'20px'}}>{product.style}</div>
                      </div> */}
                  <div className="flex flex-row gap-2 items-center">
                    <div className="2xl:text-lg text-md">Reference :</div>
                    <div className="2xl:text-2xl text-lg whitespace-nowrap text-ellipsis overflow-hidden w-[20rem]">
                      <a
                        style={{ color: "lightskyblue" }}
                        target="_blank"
                        rel="noreferrer"
                        href={product.reffLink}
                      >
                        {product.reffLink}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="w-full px-4 bg-gradient-to-r from-neutral-800 to-neutral-700 rounded-md relative">
                <table className="w-full text-md text-left">
                  <thead className="text-md font-thin text-center text-white uppercase">
                    <tr>
                      <th scope="col" className="text-left py-3">
                        Name
                      </th>
                      <th scope="col" className="py-3">
                        Date
                      </th>
                      <th scope="col" className="py-3">
                        Size
                      </th>
                      <th scope="col" className="py-3">
                        Status
                      </th>
                      <th scope="col" className="py-3"></th>
                    </tr>
                  </thead>
                  <tbody>
                    {blendfiles?.map((file, index) => (
                      <tr key={index} className="text-center">
                        <td className="py-4 font-normal whitespace-nowrap">
                          <div className={style.file}>
                            <img
                              src={`https://gurtwepkenhsrmlkypvw.supabase.co/storage/v1/object/public/profileImages/${file.fileType}.svg`}
                              alt=""
                            />
                            <div
                              className="truncate hover:overflow-visible hover:text-white w-[150px]"
                              style={{ fontSize: "medium" }}
                            >
                              {file.fileName}
                            </div>
                          </div>
                        </td>
                        <td className="py-4">
                          <div style={{ fontSize: "medium" }}>
                            {file.modifiedDate.slice(0, 10)}
                          </div>
                        </td>
                        <td className="py-4">
                          <div style={{ fontSize: "medium" }}>
                            {file.fileSize}
                          </div>
                        </td>
                        <td className="pl-2 py-4">
                          <div style={{ fontSize: "medium" }}>
                            {file.status}
                          </div>
                        </td>
                        <td>
                          <div style={{ zIndex: "5" }}>
                            {/* { console.log(pixelid)} */}
                            <ModelActions
                              status={file.status}
                              s3key={file.s3key}
                              data={blendfiles}
                              fileID={file.id}
                              modelid={pixelid}
                            />
                          </div>
                          {/* <img
                            style={{ width: "15px", height: "15px" }}
                            src={ThreeDot}
                            alt=""
                          /> */}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              {(role !== "Admin") && (
                <>
                  To Upload Blend file
                  {noblendfile < 5 ? <ModelUpload modelid={modelId} /> : ""}
                  {/* {noblendfile < 5? (<div
                className={style.uploadbutton}
                onClick={() => {
                  fileInputRef.current.click();
                }}
              >
                Upload Files +
                <input
                  onChange={modelUpload}
                  // multiple={true}
                  ref={fileInputRef}
                  //   accept="application/pdf, image/*"
                  type="file"
                  style={{ display: "none" }}
                />
              </div>):('')} */}
                  <div
                    style={{
                      position: "relative",
                      display: "flex",
                      flexDirection: "row",
                    }}
                  ></div>
                  {}
                  <div>
                    To Upload Glb File
                    {noblendfile < 5 ? <GlbUpload modelid={modelId} /> : ""}
                    <div
                      style={{
                        position: "relative",
                        display: "flex",
                        flexDirection: "row",
                      }}
                    ></div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MyModalProductView;
