import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { supabase } from "../client";
import SocialLinks from "social-links";
import { Modal } from "react-bootstrap";
import { svgs } from "../utils/icons";
import { Toast, ToastContainer } from "react-bootstrap";
import { format, parseISO } from "date-fns";

const Profile = () => {
  const history = useHistory();
  const [image, setImage] = useState("");
  // const [about, setAbout] = useState("")
  const [userData, setUserData] = useState({});
  const [editedData, setEditedData] = useState({});
  const [showLabel, setShowLabel] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [linkList, setLinkList] = useState([]);
  const [link, setLink] = useState("");
  const [provider, setProvider] = useState("");
  const [modal, setModal] = useState(false);
  const [moodboardData, setMoodboardData] = useState([]);
  const [assetData, setAssetData] = useState([]);
  const [activeIndex, setActiveIndex] = useState(0);
  const [toast, setToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("")
  const [toastTimeout, setToastTimeout] = useState(null)

  const user = localStorage.getItem("user");
  const socialLinks = new SocialLinks();

  const fetchUser = async () => {
    try {
      const { data } = await supabase
        .from("users")
        .select("*")
        .eq("id", user);
      setUserData({ ...data[0] });
      setEditedData({ ...data[0] });
      if (data[0].profile_image !== "") {
        setImage({ ...data[0].profile_image });
      } else {
        setImage("");
      }
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    fetchUser();
  }, [user, linkList]);

  // const handleInputImage = async (e) => {
  //   const file = e.target.files[0];
  //   console.log(file);
  //   if (file) {
  //     const reader = new FileReader();
  //     reader.onloadend = async () => {
  //       const imageDataURL = reader.result;

  //       const { data: uploadedFile, error: uploadError } =
  //         await supabase.storage
  //           .from("userImages")
  //           .upload(`user_images/${userData.name}`, file);
  //       if (uploadError) {
  //         console.error(uploadError);
  //         return;
  //       }
  //       const imageUrl = `https://gurtwepkenhsrmlkypvw.supabase.co/storage/v1/object/public/userImages/${uploadedFile.path}`;
  //       try {
  //         const { data: updateData, error: updateError } = await supabase
  //           .from("users")
  //           .update({ profile_image: imageUrl })
  //           .eq("id", userData.id);

  //         if (updateError) {
  //           console.error(updateError);
  //         }
  //         setUserData((prevUserData) => ({
  //           ...prevUserData,
  //           profile_image: imageUrl,
  //         }));
  //         setImage(imageUrl);
  //       } catch (error) {
  //         console.error(error);
  //       }
  //     };
  //     reader.readAsDataURL(file);
  //   }
  // };

  const handleImageChange = async (e) => {
    const newFile = e.target.files[0];
    if (newFile) {
      try {
        const { data: newFileData, error: newError } = await supabase.storage
          .from("userImages")
          .upload(`user_images/${newFile.name}`, newFile);
        if (newError) {
          console.error(newError);
          return;
        }
        const newImageUrl = `${process.env.REACT_APP_SUPABASE_URL}/storage/v1/object/public/userImages/${newFileData.path}`
        setImage(newImageUrl)
        setEditedData({...editedData, profile_image:newImageUrl})
          // Fetch the updated user data
      } catch (error) {
        console.error(error);
        return false;
      }
    }
  };

  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleCancel = () => {
    setEditedData({ ...userData });
    setIsEditing(false);
  };

  const handleSave = async () => {
    const updatedUserData = {
      name: editedData.name,
      phone_number: editedData.phone_number,
      address: editedData.address,
      dob: editedData.dob,
      about: editedData.about,
      profile_image: editedData.profile_image
    };
    try{
      const { data, error } = await supabase
        .from("users")
        .update(updatedUserData)
        .eq("id", userData.id);
      if (error) {
        console.error(error);
        setToast(true)
        setToastMessage("Error in updating Profile")
      } else {
        setUserData(prevUserData => ({...prevUserData, ...editedData}));
        setIsEditing(false);
        setToast(true)
        setToastMessage("Profile updated Successfully")
      }
    } catch(error){
      console.log(error);
    }
  };

  useEffect(() => {
    if(toast){
      const timeout = setTimeout(() => {
        setToast(false)
      }, 2500)
      setToastTimeout(timeout)
    }
    return () => {
      if(toastTimeout){
        clearTimeout(toastTimeout)
      }
    }
  },[toast])

  const handleChange = (link) => {
    const provider = socialLinks.detectProfile(link);
    const valid = socialLinks.isValid(provider, link);
    if (valid) {
      setLink(link);
      setProvider(provider);
    }
  };

  const handleAdd = () => {
    setModal(true);
  };

  const handleClose = () => {
    setModal(false);
  };

  const handleAddLink = async () => {
    if (link && provider) {
      const updatedUserData = {
        social_links: [...linkList, { link, provider }],
      };
      try {
        const { data, error } = await supabase
          .from("users")
          .update(updatedUserData)
          .eq("id", userData.id);
        if (error) {
          console.error(error);
        } else {
          const newLinkList = (prevLinkList) => [
            ...prevLinkList,
            { link, provider },
          ];
          setLink("");
          setProvider("");
          setModal(false);
          setLinkList(newLinkList);
        }
      } catch (error) {
        console.error(error);
      }
    }
  };

  useEffect(() => {
    const assetCount = async () => {
      const { data } = await supabase
        .from("asset_data")
        .select("modelStatus", { count: "exact" })
        .eq("modellerID", user);
      const groupedData = data.reduce((acc, item) => {
        const modelStatus = item.modelStatus;
        acc[modelStatus] = (acc[modelStatus] || 0) + 1;
        return acc;
      }, {});
      setAssetData(groupedData);
    };
    assetCount();
  }, [user]);

  useEffect(() => {
    const moodboardCount = async () => {
      const { data } = await supabase
        .from("moodboards")
        .select("*")
        .eq("modellerid", user);

      const groupedData = data.reduce((acc, moodboard) => {
        const day = format(parseISO(moodboard.created_at), "yyyy-MM-dd");
        acc[day] = (acc[day] || 0) + 1;
        return acc;
      }, {});
      const formattedData = Object.entries(groupedData).map(([day, count]) => ({
        day,
        moodboardCount: count,
      }));
      formattedData.sort((a, b) => parseISO(a.day) - parseISO(b.day));
      setMoodboardData(formattedData);
    };
    moodboardCount();
  }, [user]);

  useEffect(() => {
    console.log(userData);
  }, [userData]);

  return (
    <>
      <ToastContainer position="top-center" style={{zIndex:1}} className="p-2">
        <Toast show={toast} delay={2000} animation={true}>
          <Toast.Body className="text-white bg-neutral-800 font-medium rounded-sm">{toastMessage}</Toast.Body>
        </Toast>
      </ToastContainer>
      <div className="flex flex-row bg-neutral-900 overflow-y-auto xl:overflow-x-hidden lg:overflow-x-hidden md:overflow-x-hidden ">
        <div className="flex flex-col p-4 2xl:w-[90%] m-auto 2xl:h-screen xl:h-screen lg:w-screen lg:h-screen md:w-screen md:h-screen xl:w-screen">
          <div className="flex text-white p-4">
            <div className="flex flex-row gap-1 text-4xl">
              <div
                onClick={() => history.push("/home")}
                className="flex cursor-pointer hover:underline"
              >
                Home
              </div>
              <div>&nbsp;&nbsp;/&nbsp;&nbsp;</div>
              <div>Profile</div>
            </div>
          </div>
          <div className="flex flex-row gap-4 w-full p-4">
            <div className="flex flex-col gap-4 w-1/4">
              <div className="flex p-4 text-xl w-full flex-col gap-4 bg-white rounded-md justify-center items-center">
                {image && (
                  <>
                    <div
                      className="relative"
                      onMouseEnter={() => setShowLabel(true)}
                      onMouseLeave={() => setShowLabel(false)}
                    >
                      <img
                        className="2xl:w-64 2xl:h-64 w-48 h-48 rounded-full object-cover"
                        src={ userData.profile_image !== null ? userData.profile_image : "https://gurtwepkenhsrmlkypvw.supabase.co/storage/v1/object/public/profileImages/profile.png" }
                        alt=""
                      />
                      {isEditing && showLabel && (
                        <div className="absolute flex flex-col hover:visible inset-0 items-center justify-center bg-opacity-50 transition-opacity hover:bg-neutral-400 hover:bg-opacity-40">
                          <label className="cursor-pointer justify-center items-center text-center font-semibold text-black text-2xl px-2  py-1 rounded-full z-20">
                            Change Image
                            <input
                              className="hidden"
                              onChange={handleImageChange}
                              type="file"
                              accept=".png, .jpg,. jpeg"
                            />
                          </label>
                          <span className="flex text-xs">{"(Upload a max of 1MB)"}</span>
                        </div>
                      )}
                    </div>
                  </>
                )}
                 {/* : (
                   <div className="flex 2xl:w-64 2xl:h-64 w-48 h-48 rounded-full border justify-center items-center">
                     <label className="w-full h-full cursor-pointer flex hover:underline justify-center text-center items-center">
                       Upload Image
                       <input
                         className="opacity-0 absolute bg-white rounded-full"
                         onChange={handleInputImage}
                         type="file"
                         accept=".png, .jpg, .jpeg"
                       />
                     </label>
                   </div>
                 )} */}
                <div className="flex text-2xl font-semibold capitalize">
                  {userData.name}
                </div>
                <div className="flex text-2xl font-semibold capitalize">
                  {userData.role}
                </div>
              </div>
              <div className="flex p-4 w-full flex-col gap-4 bg-white rounded-md justify-center">
                <h4>Social Links</h4>
                <div className="grid grid-flow-row grid-cols-2 gap-x-5 gap-y-5">
                  {userData["social_links"] &&
                    userData["social_links"].map((link, index) =>
                      svgs.map((svg, i) => {
                        if (svg.provider === link.provider) {
                          return (
                            <div
                              onClick={() => window.open(link.link)}
                              className="flex flex-row gap-2 items-center cursor-pointer"
                              key={index}
                            >
                              <div
                                dangerouslySetInnerHTML={{ __html: svg.svg }}
                              ></div>
                              <div className="capitalize text-lg">
                                {link.provider}
                              </div>
                            </div>
                          );
                        } else {
                          return null;
                        }
                      })
                    )}
                </div>
                {isEditing ? (
                <div
                  className="flex underline cursor-pointer"
                  onClick={handleAdd}
                >
                  Add Link
                </div>
                ):(
                  null
                )}
              </div>
            </div>
            <div className="flex flex-col gap-4 w-2/4">
              <div className="flex flex-col 2xl:gap-4 gap-4 w-full rounded-md 2xl:p-8 p-3 bg-white">
                <div className="flex flex-row gap-2">
                  {isEditing ? (
                    <>
                      <button
                        onClick={handleSave}
                        className="flex px-4 text-xl font-semibold py-1 bg-sky-600 hover:bg-sky-500 text-white rounded-md"
                      >
                        Save
                      </button>
                      <button
                        onClick={handleCancel}
                        className="flex px-4 text-xl font-semibold py-1 bg-red-600 hover:bg-red-500 text-white rounded-md"
                      >
                        Cancel
                      </button>
                    </>
                  ) : (
                    <button
                      onClick={handleEdit}
                      className="flex px-4 text-xl font-semibold py-1 bg-sky-600 hover:bg-sky-500 text-white rounded-md"
                    >
                      Edit
                    </button>
                  )}
                </div>
                <div className="flex flex-row w-full 2xl:text-2xl text-xl">
                  <div className="flex w-1/4">Full Name</div>
                  {isEditing ? (
                    <input
                      className="flex w-1/4 px-2 py-3 2xl:text-2xl text-xl border-b-2 focus:outline-none bg-white border-black"
                      type="text"
                      value={editedData.name}
                      onChange={(e) =>
                        setEditedData((prevUserData) => ({
                          ...prevUserData,
                          name: e.target.value,
                        }))
                      }
                    />
                  ) : (
                    <div className="flex w-1/4 2xl:text-2xl text-xl">{userData.name}</div>
                  )}
                </div>
                <div className="flex w-full border border-neutral-400"></div>
                <div className="flex flex-row w-full 2xl:text-2xl text-xl">
                  <div className="flex w-1/4">Email</div>
                  <div className="flex w-1/4">{userData.email}</div>
                </div>
                <div className="flex w-full border border-neutral-400"></div>
                <div className="flex flex-row w-full 2xl:text-2xl text-xl">
                  <div className="flex w-1/4">Mobile No</div>
                  {isEditing ? (
                    <input
                      className="flex w-1/4 px-2 py-3 2xl:text-2xl text-xl border-b-2 focus:outline-none bg-white border-black"
                      type="number"
                      value={editedData.phone_number}
                      onChange={(e) =>
                        setEditedData((prevUserData) => ({
                          ...prevUserData,
                          phone_number: e.target.value
                        }))
                      }
                    />
                  ) : (
                    <div className="flex w-1/4">{userData.phone_number}</div>
                  )}
                </div>
                <div className="flex w-full border border-neutral-400"></div>
                <div className="flex flex-row w-full 2xl:text-2xl text-xl">
                  <div className="flex w-1/4">Address</div>
                  {isEditing ? (
                    <input
                      className="flex w-3/4 px-2 py-3 2xl:text-2xl text-xl border-b-2 focus:outline-none focus:border-b-1 bg-white border-black"
                      type="text"
                      value={editedData.address || ""}
                      onChange={(e) => setEditedData((prevUserData) => ({
                        ...prevUserData,
                        address: e.target.value
                      }))
                    }
                    />
                  ) : (
                    <div className="flex w-3/4">{userData.address}</div>
                  )}
                </div>
                <div className="flex w-full border border-neutral-400"></div>
                <div className="flex flex-row w-full 2xl:text-2xl text-xl">
                  <div className="flex w-1/4">Date of Birth</div>
                  {isEditing ? (
                    <input
                      className="flex w-1/4 px-2 py-3 2xl:text-2xl text-xl border-b-2 focus:outline-none bg-white border-black"
                      type="date"
                      value={editedData.dob || ""}
                      onChange={(e) => setEditedData((prevUserData) => ({
                        ...prevUserData,
                        dob: e.target.value
                      })
                    )}
                    />
                  ) : (
                    <div className="flex w-1/4">{userData.dob}</div>
                  )}
                </div>
              </div>
            </div>
            <div className="flex flex-col w-1/4">
              <div className="flex flex-col gap-2 bg-white p-8 rounded-md">
                <h5>About</h5>
                  {isEditing ? (
                    <textarea
                      value={editedData.about || userData.about}
                      onChange={(e) => setEditedData((prevUserData) => ({...prevUserData, about: e.target.value}))}
                      placeholder="Click to add a small info about yourself" 
                      className="bg-white w-[100%] h-56 focus:outline-none overflow-hidden"
                      style={{whiteSpace:'pre-wrap', wordBreak:'break-word', resize:'none'}}
                    />
                  ) : (
                      <div className="flex w-full 2xl:text-xl text-lg h-auto overflow-hidden">{userData.about}</div>
                  )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal show={modal} onHide={handleClose} size="md" centered animation>
        <Modal.Header closeButton className="text-2xl text-black bg-white">
          Social Links
        </Modal.Header>
        <Modal.Body className="text-xl text-black bg-white">
          <div className="flex flex-col gap-4">
            <div className="flex flex-col gap-2">
              <div>Link</div>
              <input
                type="text"
                value={link}
                onChange={(e) => handleChange(e.target.value)}
                className="flex w-full bg-white border focus:outline-none"
              />
            </div>
            <div className="flex flex-col gap-2">
              <div>Provider</div>
              <input
                type="text"
                value={provider}
                onChange={(e) => setProvider(e.target.value)}
                className="flex w-full bg-white border focus:outline-none"
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="flex justify-end">
            <button
              onClick={handleAddLink}
              className="flex px-4 py-1 rounded-md bg-neutral-700 text-white"
            >
              Add
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Profile;
