import React, { useState, useRef, useEffect } from "react";
import Logo from "../public/lottie/Logo.svg";
import * as animationData from "../public/lottie/robot_3d.json";
import BIRDS from "vanta/dist/vanta.birds.min";
// import GLOBE from 'vanta/dist/vanta.globe.min'
import * as THREE from "three";
import { supabase } from "../client";
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useHistory , Link} from 'react-router-dom';


const Login = () => {
  // const userRef = useRef();
  const [vantaEffect, setVantaEffect] = useState(0);
  const vantaRef = useRef(null);
  const [email, setEmail] = useState();
  const [pass, setPass] = useState();
  // const [name, setName] = useState("");
  // const [role, setRole] = useState("");
  // const [success, setSuccess] = useState(false);
  const history = useHistory()
  const [showPassword, setShowPassword] = useState(false);

  const [formValues, setFormValues] = useState({});
  
  useEffect(()=>{
    (async ()=>{
      const add = await fetch("https://geolocation-db.com/json/", {
          method: "GET",
        });
        const resp = await add.json();
        localStorage.setItem("address",JSON.stringify(resp))
    })()
  },[])

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormValues((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleSubmit = async(event) => {
    event.preventDefault();
    // console.log(formValues); // This will log the form values as a dictionary
    supabase.auth.signInWithPassword(formValues)
    .then(async({ data, error }) => {
      if (error) {
        alert(error.message);
        return;
      }
      // localStorage.setItem('access_token',data.session.access_token)
      localStorage.setItem('user',data.user.id)
      localStorage.setItem('expires_at',data.session.expires_at)

      const user = localStorage.getItem('user');
      supabase.from("users").select("name,role,account_status, id, last_seen").eq("id",user).then(async({data})=>{
        if(data[0].account_status === "suspended"){
          alert('User Suspended')
        } else {
          if(data[0].account_status === null && data[0].last_seen === null){
            await supabase.from("users").update({account_status:"active"}).eq("id", data[0].id)
          }
          localStorage.setItem("username",data[0].name)
          localStorage.setItem('role', data[0].role)
          if (data[0].role === "Admin"){
            history.push('/qc-check')
          }else if(data[0].role === "Modeler"){
            history.push('/modelsgrab')
          } else if(data[0].role === "Texture-Artist" || data[0].role === "Visualizer") {
            history.push("/texture_artist")
          } else {
            history.push(`/profile/${data[0].name}`)
          }
        }
      })
      const userData = await supabase.auth.getUser()
      if(userData){
        await supabase.from("users").update({status:'online'}).eq('id', user)
      }
      // localStorage.setItem('session', JSON.stringify(data));
      // console.log(data);
      // You can access the properties of the `data` object here
    })
    .catch((error) => console.log(error));
  };
 
  // const sessionData = JSON.parse(localStorage.getItem('session'));
  // const access_token = localStorage.getItem('access_token');


  useEffect(() => {
    if (!vantaEffect) {
      setVantaEffect(
        BIRDS({
          el: vantaRef.current,
          THREE: THREE,
          color1: 0xe29525,
          color2: 0x964c55,
          backgroundColor: 0x171717,
          mouseControls: true,
          touchControls: true,
          gyroControls: false,
          scale: 1.0,
        })
      );
    }
    return () => {
      if (vantaEffect) vantaEffect.destroy();
    };
  }, [vantaEffect]);

  // const defaultOptions = {
  //   loop: true,
  //   autoplay: true,
  //   animationData: animationData,
  //   rendererSettings: {
  //     preserveAspectRatio: "xMidYMiD slice",
  //   },
  // };
  return (
    <>
      <div className="App" id="App" ref={vantaRef}>
        <div className="logo">
          <img style={{ width: "150px", height: "100px" }} src={Logo} alt="" />
        </div>
        <div className="welcome">
          <h2>Welcome to MX-3D Studio!</h2>
        </div>
      <form onSubmit={handleSubmit}>
        <div className="auth-form-container">
          <h2>Sign in</h2>
          <div className="loginform">
            <div className="login_form">
              <div className="email_input">
                <div className="email_text">
                  <label htmlFor="email">Email Id</label>
                </div>
                <input
                  value={email}
                  onChange={handleChange}
                  type="email"
                  placeholder="youremail@gmail.com"
                  style={{ color: "white" }}
                  id="email"
                  name="email"
                  required
                />
              </div>
              <div className="password_input">
                <div className="password_text">
                  <label htmlFor="password">Password</label>
                </div>
                <div className="relative">
                  <input
                    value={pass}
                    onChange={handleChange}
                    type={(!showPassword && "password") || "text"}
                    placeholder="********"
                    id="password"
                    name="password"
                    style={{ color: "white" }}
                    required
                  />
                  <div className="absolute inset-y-0 right-0 flex items-center pr-2">
                    {(!showPassword && (
                      <FontAwesomeIcon
                        icon={faEye}
                        onClick={() => {
                          setShowPassword(!showPassword);
                        }}
                      />
                    )) || (
                      <FontAwesomeIcon
                        icon={faEyeSlash}
                        onClick={() => {
                          setShowPassword(!showPassword);
                        }}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="login_button">
              <button className="button" style={{ cursor: "pointer" }}>
                Log In
              </button>
            </div>
            <Link to={'/forgot-password'} style={{textDecoration: 'none' , color:"white"}}>
            <div className="forgot_password">Forgot Password?</div>
            </Link>
          </div>
        </div>
      </form>
      </div>
    </>
  );
};

export default Login;
