/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Toast, ToastContainer } from "react-bootstrap";
import Sidebar from "../layouts/sidebar";
import Topbar from "../layouts/topbar";
import style from "./home.module.css";
import UserCard from "../components/UserCard";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { supabase, supabaseAdmin } from "../client";
import { Modal } from "react-bootstrap";
import Lottie from "react-lottie";
import Loading from '../public/lottie/Loading.json'
import Select from 'react-select'
import ErrorPage from "./404";

const Users = () => {
  const currentRole = localStorage.getItem("role")
  const [usersList, setUsersList] = useState([]);
  const [roleList, setRoleList] = useState([]);
  const [modal, setModal] = useState(false);
  const [email, setEmail] = useState();
  const [pass, setPass] = useState();
  const [name, setName] = useState();
  const [role, setRole] = useState();
  const [roleSelected, setRoleSelected] = useState(null);
  const [showPassword, setShowPassword] = useState(false);
  const [active, setActive] = useState(null);
  const [formValues, setFormValues] = useState({});
  const userName = localStorage.getItem("username");
  const [updatedData, setUpdatedData] = useState([]);
  const [toast, setToast] = useState(false)
  const [toastMessage, setToastMessage] = useState("")
  const [toastTimeout, setToastTimeout] = useState(null)

  const roleOptions = [
    {value:"Admin", label:"Admin"},
    {value:"Modeler", label:"Modeler"},
    {value:"Texture-Artist", label:"Texture-Artist"},
    {value:"Visualizer", label:"Visualizer"}
  ]

  const customStyles = {
    option:(provider, state) => ({
      ...provider,
      backgroundColor: state.isSelected ? 'grey':'white',
      color: state.isSelected ? 'black' : 'black',
    }),
    control:(provider) => ({
      ...provider,
      backgroundColor:'black',
      color:'white',
      border:'none',
      outline:'none',
      boxShadow:'none'
    }),
    singleValue: (provided) => ({
      ...provided,
      color: 'white',
    }),
  }

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: Loading,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormValues((prevState) => ({ ...prevState, [name]: value }));
    if (name === "email") setEmail(value);
    else if (name === "password") setPass(value);
    else if (name === "name") setName(value);
    // else if (name === "role") setRole(value);
  };

    const userList = async () => {
      try{
        const { data: users } = await supabase.from("users").select("*");
  
        const distinctRoles = [...new Set(users.map((user) => user.role))];
        setRoleList(distinctRoles);
        setUsersList(users);
        setUpdatedData(users);
      } catch(error) {
        console.error(error)
      }
    };

    useEffect(() =>{
      userList()
    },[modal])

  const handleSubmit = async (event) => { 
    event.preventDefault();
    try {
      let { data: user, error } = await supabase.auth.signUp({
        email: formValues.email,
        password: formValues.password,
      });
      console.log(user);
      if (error) {
        alert(error.message);
        return;
      }
      try {
        const { data, error } = await supabase.from("users").upsert(
          [
            {
              id: user.user.id,
              email: formValues.email,
              password: formValues.password,
              name: formValues.name,
              role: roleSelected?.value,
              account_status:"created",
              profile_image: "https://gurtwepkenhsrmlkypvw.supabase.co/storage/v1/object/public/profileImages/profile.png",
            },
          ],
          { headers: { apiKey: process.env.REACT_APP_SUPABASE_KEY } }
        );
        if (error) {
          setToastMessage("Unable to create User")
          return;
        }
        setModal(false);
        setFormValues({});
        setEmail("")
        setPass("")
        setName("")
        // setRole("")
        setRoleSelected(null)
        setToast(true)
        setToastMessage("User Created Successfully")
        userList()
      } catch (error) {
        console.error(error);
      }
      console.log(user);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if(toast){
      const timeout = setTimeout(() => {
        setToast(false)
      }, 2500)
      setToastTimeout(timeout)
    }
    return () => {
      if(toastTimeout){
        clearTimeout(toastTimeout)
      }
    }
  },[toast])

  const filterByRole = async (role) => {
    try {
      let updatedUsersList = [];
      if (role === active) {
        setActive(null);
        const { data } = await supabase.from("users").select("*");
        updatedUsersList = data;
      } else {
        const { data, error } = await supabase
          .from("users")
          .select("*")
          .eq("role", role);
        if (error) {
          console.error(error);
          return;
        }
        setActive(role);
        updatedUsersList = data;
      }
      setUsersList(updatedUsersList);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
    <ToastContainer position="top-center" style={{zIndex:1}} className="p-2">
      <Toast show={toast} delay={2000} animation={true}>
        <Toast.Body className="text-white bg-neutral-800 font-medium rounded-sm">{toastMessage}</Toast.Body>
      </Toast>
    </ToastContainer>
      <div className="flex flex-row bg-neutral-900 xl:overflow-hidden lg:overflow-hidden md:overflow-hidden">
        <div className="flex flex-row 2xl:w-screen 2xl:h-screen xl:h-screen lg:w-screen lg:h-screen md:w-screen md:h-screen sm:w-screen sm:h-screen xl:w-screen">
          <Sidebar />
          {currentRole && currentRole === "Admin" ? (
          <div className="flex flex-col float-right overflow-x-hidden overflow-y-auto h-auto w-full">
            <div className="flex items-center w-full whitespace-nowrap text-white 2xl:pl-20 pl-12">
              <div className="flex flex-col">
                <div className="flex 2xl:text-4xl text-2xl tracking-wider">
                  {`Welcome Back ${userName},`}
                </div>
                <div className="flex 2xl:text-xl text-lg tracking-wider">
                  Here is the Progress
                </div>
              </div>
              <div className="flex float-right w-full items-end justify-end">
                <Topbar className={style.top} />
              </div>
            </div>
            <div className="flex w-full h-0.5 bg-neutral-400 rounded-full opacity-10"></div>
            <div className="flex flex-col w-full p-4">
              <div
                className="flex w-max text-white hover:bg-neutral-700 bg-neutral-800 font-semibold rounded-sm text-xl px-3 py-1"
              >
                User Management
              </div>
              <div className="bg-neutral-900 rounded-md">
                <div
                  className="mt-3 text-white rounded-md"
                  style={{ backgroundColor: "#262626" }}
                >
                  {usersList && usersList.length !== 0 ? (
                  <div>
                    <div className="flex flex-col gap-4 w-full px-4 py-2">
                      <div className="flex flex-row w-full justify-between">
                        <div className="flex flex-col">
                          <div className="flex text-2xl">Manage Users</div>
                          <div className="flex text-sm">{`Showing ${usersList.length} Users`}</div>
                        </div>
                        <button
                          onClick={() => setModal(true)}
                          className="flex bg-cyan-800 justify-center items-center rounded-md font-semibold text-lg px-4"
                        >
                          Create User
                        </button>
                      </div>
                      <div className=" flex flex-row gap-4">
                        <div className="font-semibold text-lg">
                          Filter by Role
                        </div>
                        {roleList &&
                          roleList.map((role, index) => (
                            <button
                              key={index}
                              onClick={() => filterByRole(role)}
                              className={`flex ${
                                active === role
                                  ? "bg-green-700"
                                  : "bg-neutral-700"
                              } capitalize  px-4 justify-center items-center rounded-full`}
                            >
                              {role}
                            </button>
                          ))}
                      </div>
                      <UserCard data={usersList} setData={userList}/>
                    </div>
                  </div>
                  ):(
                    <div className="flex w-full h-auto justify-center items-center">
                      <Lottie width={300} height={200} style={{height:'400px'}} options={defaultOptions}/>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          ):(
            <div className="flex w-full ">
              <ErrorPage/>
            </div>
          )}
        </div>
      </div>
      <Modal centered show={modal} size="md">
        {/* <Modal.Header className="flex bg-neutral-800 text-lg font-semibold text-white">Add User</Modal.Header> */}
        <Modal.Body className="flex py-4 flex-col gap-4 rounded-sm text-white bg-neutral-800">
          <div className=" flex flex-row w-full justify-between">
            <div className="flex text-xl font-semibold items-center">
              Add User
            </div>
            <img
              className="flex z-20 cursor-pointer"
              onClick={() => setModal(false)}
              src="https://dev-monkxperience.s3.ap-south-1.amazonaws.com/eliya/close.svg"
              alt=""
            />
          </div>
          <div className="flex flex-col gap-2">
            <form onSubmit={handleSubmit}>
              <div className="flex flex-col gap-4">
                <div className="flex w-full flex-col gap-2">
                  <div className="text-lg">Email</div>
                  <input
                    className="w-full focus:outline-none"
                    value={email}
                    onChange={handleChange}
                    type="email"
                    placeholder="useremail@gmail.com"
                    style={{ color: "white" }}
                    id="email"
                    name="email"
                    required
                  />
                </div>
                <div className="flex w-full flex-col gap-2">
                  <div className="text-lg">Password</div>
                  <div className="relative">
                    <input
                      className="w-full"
                      value={pass}
                      onChange={handleChange}
                      type={(!showPassword && "password") || "text"}
                      placeholder="********"
                      id="password"
                      name="password"
                      style={{ color: "white" }}
                      required
                    />
                    <div className="absolute inset-y-0 right-0 flex items-center pr-2">
                      {(!showPassword && (
                        <FontAwesomeIcon
                          icon={faEye}
                          onClick={() => {
                            setShowPassword(!showPassword);
                          }}
                        />
                      )) || (
                        <FontAwesomeIcon
                          icon={faEyeSlash}
                          onClick={() => {
                            setShowPassword(!showPassword);
                          }}
                        />
                      )}
                    </div>
                  </div>
                </div>
                <div className="flex w-full flex-col gap-2">
                  <div className="text-lg">Name</div>
                  <input
                    className="w-full focus:outline-none"
                    value={name}
                    onChange={handleChange}
                    type="text"
                    placeholder="Enter User Name"
                    style={{ color: "white" }}
                    id="name"
                    name="name"
                    required
                  />
                </div>
                <div className="flex w-full flex-col gap-2">
                  <div className="text-lg">Role</div>
                  <Select
                    options={roleOptions}
                    value={roleSelected}
                    onChange={(selectedOption) => setRoleSelected(selectedOption)}
                    placeholder="Select a role"
                    styles={customStyles}
                  />
                  {/* <input
                    className="w-full focus:outline-none"
                    value={role}
                    onChange={handleChange}
                    type="text"
                    placeholder="Enter User role"
                    style={{ color: "white" }}
                    id="role"
                    name="role"
                    required
                  /> */}
                </div>
                <div className="flex w-full justify-center">
                  <button className="flex px-5 py-2 text-xl font-semibold rounded-full bg-neutral-900 hover:bg-neutral-700">
                    Add User
                  </button>
                </div>
              </div>
            </form>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Users;
