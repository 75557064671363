export const scorePoints = [
    {
        "L1":10,
    },
    {
        "L2":20,
    },
    {
        "L3":30,
    },
    {
        "L4":40,
    },
    {
        "L5":50,
    }
]
