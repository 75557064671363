import React, { useEffect, useState } from "react";
import Sidebar from "../layouts/sidebar";
import Topbar from "../layouts/topbar";
import style from "./myModals.module.css";
import Search from "../public/lottie/chevron_right.svg";
import { Button } from "@material-ui/core";
import { supabase } from "../client";
import { Link, useHistory } from "react-router-dom";
import Lottie from "react-lottie";
import Loading from '../public/lottie/Loading.json'
import AdminModals from "./AdminModals";

const Mymodals = ({ history }) => {
  const [models, setModels] = useState(null);
  const [brandList, setBrandList] = useState([]);
  const userData = localStorage.getItem("user");
  const role = localStorage.getItem("role");
  let priorities = [
    { id: 1, name: "low" },
    { id: 2, name: "medium" },
    { id: 3, name: "high" },
    { id: 4, name: "sprint" },
  ];
  let difficulties = [
    { id: 1, name: "L1" },
    { id: 2, name: "L2" },
    { id: 3, name: "L3" },
    { id: 4, name: "L4" },
  ];

  let statuses = [
    { id: 1, name: "in_conversion" },
    { id: 2, name: "conversion_fail" },
    { id: 3, name: "ar_ready" },
    { id: 4, name: "ar_fail" },
    { id: 5, name: "ar_pass" },
    { id: 5, name: "assigned" },
    { id: 6, name: "submitted_for_qc" },
  ];

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: Loading,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  useEffect(() => {
    if (userData) {
      const expiry = localStorage.getItem("expires_at");
      const now = new Date().getTime() / 1000;
      if (now > expiry) {
        alert("Session timed Out");
        localStorage.clear();
        history.push("/login");
      }
    } else {
      alert("Login to Continue");
      history.push("/login");
    }
  }, []);

  const fetchBrands = async() => {
    const {data:items} = await supabase.from("asset_data").select("productBrand")
    const distinctBrands = [...new Set(items.map((item) => item.productBrand))]
    setBrandList(distinctBrands)
  }

  useEffect(() => {
    fetchBrands()
  },[])

  const [filter, setFilter] = useState(null);
  const [filter2, setFilter2] = useState(null);
  const [filter3, setFilter3] = useState(null);
  const [filter4, setFilter4] = useState(null);
  const [Option, setOption] = useState("pixelID");
  const [searchTerm, setSearchTerm] = useState("");
  const handleSearchTermChange = (event) => {
    setSearchTerm(event.target.value);
  };
  const handleFilterChange = (event) => {
    setFilter(event.target.value);
  };
  const handleFilter2Change = (event) => {
    setFilter2(event.target.value);
  };
  const handleFilter3Change = (event) => {
    setFilter3(event.target.value);
  };
  const handleFilter4Change = (event) => {
    setFilter4(event.target.value)
  };

  const fetchData = async() => {
    try{
      if(role && role !== "Admin"){
        let data
        let query = supabase
          .from("asset_data")
          .select("*")
          .eq("modellerID", userData)

          if (filter) {
            query = query.eq("modelStatus", filter);
          }
          if (filter2) {
            query = query.eq("productPriority", filter2);
          }
          if (filter3) {
            query = query.eq("difficultyLevel", filter3);
          }
          if (filter4) {
            query = query.eq("productBrand", filter4);
          }
          if (searchTerm) {
            query = query.ilike(`${Option}`, `%${searchTerm}%`)
          }

          data = await query;
          if(data.data.length > 0){
            setModels(data.data);
          } else if(data.data.length === 0){
            setModels([])
          }
      }
    }catch(error){
      console.error(error)
    }
  }
  useEffect(() => {
    fetchData();
  }, [filter, filter2, filter3, filter4, searchTerm, Option]);

  const handleOption = (event) => {
    setOption(event.target.value);
  };
  return (role && role !== "Admin") ? (
    <>
      <div className="flex flex-row bg-neutral-900 xl:overflow-hidden lg:overflow-hidden md:overflow-hidden">
        <div className="flex flex-row 2xl:w-screen 2xl:h-screen xl:h-screen lg:w-screen lg:h-screen md:w-screen md:h-screen xl:w-screen">
          <Sidebar />
          <div className="w-screen h-screen overflow-auto">
            <Topbar />
            <div className="flex float-right pr-8">
              <div className="flex w-full rounded-md bg-gradient-to-r from-[#3dc1fb] via-[#ff00f5] to-[#0feffd] pb-0.5">
                <div className="relative">
                  <select
                    className="absolute right-2 top-1 rounded-sm focus:outline-none"
                    style={{ color: "black" }}
                    value={Option || "pixelID"}
                    onChange={handleOption}
                  >
                    <option style={{ color: "black" }} value={"pixelID"}>
                      PixelID
                    </option>
                    <option style={{ color: "black" }} value={"productName"}>
                      Name
                    </option>
                  </select>
                  <input
                    className="rounded-sm focus:outline-none"
                    type="text"
                    value={searchTerm}
                    onChange={handleSearchTermChange}
                    placeholder="Search"
                    name="search"
                    id="search"
                    style={{
                      color: "white",
                      backgroundColor: "#343434",
                    }}
                  />
                </div>
              </div>
              <img src={Search} alt="" />
            </div>
            <div className="flex flex-row  text-white pl-6">
              <div className="flex flex-row 2xl:gap-20 xl:gap-6 lg:gap-6 md:gap-2 2xl:w-5/6 w-full">
                <div className="flex 2xl:w-1/6 w-1/6">
                  <div className="flex w-full">
                    <select
                      className="w-full rounded-sm focus:outline-none bg-neutral-500 xl:text-lg lg:text-md font-semibold items-center tracking-wider text-white 
                    text-center bg-gradient-to-br from-neutral-700 to-neutral-500 bg-contain"
                      style={{ color: "black" }}
                      value={filter || ""}
                      onChange={handleFilterChange}
                    >
                      <option style={{ color: "black" }} value={""}>
                        Status
                      </option>
                      {statuses.map((status) => (
                        <option
                          style={{ color: "black" }}
                          key={status.id}
                          value={status.name}
                        >
                          {status.name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="flex 2xl:w-1/6 w-1/6">
                  <div className="flex w-full">
                    <select
                      className="w-full rounded-sm focus:outline-none bg-neutral-500 xl:text-lg lg:text-md font-semibold items-center tracking-wider text-white 
                    text-center bg-gradient-to-br from-neutral-700 to-neutral-500 bg-contain"
                      style={{ color: "black" }}
                      value={filter2 || ""}
                      onChange={handleFilter2Change}
                    >
                      <option style={{ color: "black" }} value={""}>
                        Priority
                      </option>
                      {priorities.map((priority) => (
                        <option
                          style={{ color: "black" }}
                          key={priority.id}
                          value={priority.name}
                        >
                          {priority.name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="flex 2xl:w-1/6 w-1/6">
                  <div className="flex w-full">
                    <select
                      className="w-full rounded-sm focus:outline-none bg-neutral-500 xl:text-lg lg:text-md font-semibold items-center tracking-wider text-white 
                    text-center bg-gradient-to-br from-neutral-700 to-neutral-500 bg-contain"
                      style={{ color: "black" }}
                      value={filter3 || ""}
                      onChange={handleFilter3Change}
                    >
                      <option style={{ color: "black" }} value={""}>
                        Difficulty
                      </option>
                      {difficulties.map((difficulty) => (
                        <option
                          style={{ color: "black" }}
                          key={difficulty.id}
                          value={difficulty.name}
                        >
                          {difficulty.name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="flex 2xl:w-1/5 w-1/6">
                  <div className="flex w-full">
                    <select
                      className="w-full rounded-sm focus:outline-none bg-neutral-500 xl:text-lg lg:text-md font-semibold items-center tracking-wider text-white 
                    text-center bg-gradient-to-br from-neutral-700 to-neutral-500 bg-contain"
                      style={{ color: "black" }}
                      value={filter4 || ""}
                      onChange={handleFilter4Change}
                    >
                      <option style={{ color: "black" }} value={""}>
                        Brand
                      </option>
                      {brandList && brandList.map((brand, index) => (
                        <option style={{color:'black'}} key={index} value={brand}>
                          {brand}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
            </div>
            {models ? (models?.map((item) => {
              const len = item.pixelID.split("_").length;

              return (
                <div className="pt-16 pl-6 pr-6">
                  <Link
                    style={{ textDecoration: "none", color: "white" }}
                    to={`/myproduct/${item.id}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <div className="flex basis-1/2 bg-neutral-700 pr-6">
                      <div className="w-full flex flex-row items-center gap-6 pl-4">
                        <div className="flex w-[100px] h-[100px] relative -top-5">
                          <img src={item.productImage[0]} alt="" />
                        </div>
                        <div className="flex flex-col gap-4 text-ellipsis">
                          <div className="whitespace-nowrap overflow-hidden 2xl:w-[450px] xl:w-[200px] lg:w-[200px] md:w-[150px] text-ellipsis">
                            {item.productName}
                          </div>
                          <div className={style.productBrand}>
                            {item.productBrand}
                          </div>
                        </div>
                      </div>
                      <div className="w-full flex items-center">
                        <div className="w-full flex flex-row items-center 2xl:gap-20 xl:gap-8">
                          <div className="flex flex-row gap-2 w-full justify-center">
                            <div className="w-6 h-6 flex items-center justify-center">
                              <img
                                className="w-10 h-10"
                                src={`https://gurtwepkenhsrmlkypvw.supabase.co/storage/v1/object/public/profileImages/${item.difficultyLevel}.svg`}
                                alt=""
                              />
                            </div>
                            <div className={style.level}>
                              {item.difficultyLevel}
                            </div>
                          </div>
                          <div className="flex flex-row gap-2 w-full justify-center">
                            <div className="w-4 h-6 flex items-center justify-center">
                              <img
                                className="w-10 h-10"
                                src={`https://gurtwepkenhsrmlkypvw.supabase.co/storage/v1/object/public/profileImages/${item.productPriority}.svg`}
                                alt=""
                              />
                            </div>
                            <div className={style.prioritylevel}>
                              {item.productPriority}
                            </div>
                          </div>
                          <div className="flex flex-row gap-2 items-center whitespace-nowrap">
                            <div className={style.variantImg}>A</div>
                            <div className={style.varientType}>
                              {len < 4 ? "Unique Model" : "Variant Model"}
                            </div>
                          </div>
                          <div className={style.modalstatus}>
                            <Button
                              className={style.button}
                              style={{
                                backgroundColor: "tomato",
                                borderRadius: "50vh",
                              }}
                            >
                              {item.modelStatus}
                            </Button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
              );
            })
            ):(
              <div className="flex w-full h-auto justify-center items-center">
                <Lottie width={300} height={200} style={{height:'400px'}} options={defaultOptions}/>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  ) : (
    <>
      <div className="flex pb-5">
        <AdminModals/>
      </div>
             {/* {models && models?.map((item) => {
                const len = item.pixelID.split("_").length;
                return (
                  <div className="pt-16 pl-6 pr-6">
                  <Link
                    style={{ textDecoration: "none", color: "white" }}
                    to={`/myproduct/${item.id}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <div className="flex basis-1/2 bg-neutral-700 pr-6">
                      <div className="w-full flex flex-row items-center gap-6 pl-4">
                        <div className="flex w-[100px] h-[100px] relative -top-5">
                          <img src={item.productImage[0]} alt="" />
                        </div>
                        <div className="flex flex-col gap-4 text-ellipsis">
                          <div className="whitespace-nowrap overflow-hidden 2xl:w-[450px] xl:w-[200px] lg:w-[200px] md:w-[150px] text-ellipsis">
                            {item.productName}
                          </div>
                          <div className={style.productBrand}>
                            {item.productBrand}
                          </div>
                        </div>
                      </div>
                      <div className="w-full flex items-center">
                        <div className="w-full flex flex-row items-center 2xl:gap-20 xl:gap-8">
                          <div className="flex flex-row gap-2 w-full justify-center">
                            <div className="w-6 h-6 flex items-center justify-center">
                              <img
                                className="w-10 h-10"
                                src={`https://gurtwepkenhsrmlkypvw.supabase.co/storage/v1/object/public/profileImages/${item.difficultyLevel}.svg`}
                                alt=""
                              />
                            </div>
                            <div className={style.level}>
                              {item.difficultyLevel}
                            </div>
                          </div>
                          <div className="flex flex-row gap-2 w-full justify-center">
                            <div className="w-4 h-6 flex items-center justify-center">
                              <img
                                className="w-10 h-10"
                                src={`https://gurtwepkenhsrmlkypvw.supabase.co/storage/v1/object/public/profileImages/${item.productPriority}.svg`}
                                alt=""
                              />
                            </div>
                            <div className={style.prioritylevel}>
                              {item.productPriority}
                            </div>
                          </div>
                          <div className="flex flex-row gap-2 items-center whitespace-nowrap">
                            <div className={style.variantImg}>A</div>
                            <div className={style.varientType}>
                              {len < 4 ? "Unique Model" : "Variant Model"}
                            </div>
                          </div>
                          <div className={style.modalstatus}>
                            <Button
                              className={style.button}
                              style={{
                                backgroundColor: "tomato",
                                borderRadius: "50vh",
                              }}
                            >
                              {item.modelStatus}
                            </Button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
                  <div className="pt-4 pl-6 pr-6">
                    <Link to={`/myproduct/${item.id}`} rel="noreferrer" target="_blank">
                      <div className="items-center justify-center flex">
                        <img
                          style={{
                            height: "190px",
                            width: "190px",
                            borderRadius: "5px",
                          }}
                          src={item?.productImage !== null ? item?.productImage[0] : ""}
                          alt="product_image"
                        ></img>
                      </div>
                    </Link>
                    <div className=" bg-gray-600 rounded-2xl text-white flex flex-row justify-between">
                      <div className=" pl-1">{item.productName}</div>
                      <div className="pr-1">{item.modellerName}</div>
                    </div>
                    <Link
                    style={{ textDecoration: "none", color: "white" }}
                    to={`/myproduct/${item.id}`}
                  >
                    <div className="flex basis-1/2 bg-neutral-700 pr-6">
                      <div className="w-full flex flex-row items-center gap-6 pl-4">
                        <div className="flex w-[100px] h-[100px] relative -top-5">
                          <img src={item.productImage[0]} alt="" />
                        </div>
                        <div className="flex flex-col gap-4 text-ellipsis">
                          <div className="whitespace-nowrap overflow-hidden 2xl:w-[450px] xl:w-[200px] lg:w-[200px] md:w-[150px] text-ellipsis">
                            {item.productName}
                          </div>
                          <div className={style.productBrand}>
                            {item.productBrand}
                          </div>
                        </div>
                      </div>
                      <div className="w-full flex items-center">
                        <div className="w-full flex flex-row items-center 2xl:gap-20 xl:gap-8">
                          <div className="flex flex-row gap-2 w-full justify-center">
                            <div className="w-6 h-6 flex items-center justify-center">
                              <img
                                className="w-10 h-10"
                                src={`https://gurtwepkenhsrmlkypvw.supabase.co/storage/v1/object/public/profileImages/${item.difficultyLevel}.svg`}
                                alt=""
                              />
                            </div>
                            <div className={style.level}>
                              {item.difficultyLevel}
                            </div>
                          </div>
                          <div className="flex flex-row gap-2 w-full justify-center">
                            <div className="w-4 h-6 flex items-center justify-center">
                              <img
                                className="w-10 h-10"
                                src={`https://gurtwepkenhsrmlkypvw.supabase.co/storage/v1/object/public/profileImages/${item.productPriority}.svg`}
                                alt=""
                              />
                            </div>
                            <div className={style.prioritylevel}>
                              {item.productPriority}
                            </div>
                          </div>
                          <div className="flex flex-row gap-2 items-center whitespace-nowrap">
                            <div className={style.variantImg}>A</div>
                            <div className={style.varientType}>
                              {len < 4 ? "Unique Model" : "Variant Model"}
                            </div>
                          </div>
                          <div className={style.modalstatus}>
                            <Button
                              className={style.button}
                              style={{
                                backgroundColor: "tomato",
                                borderRadius: "50vh",
                              }}
                            >
                              {item.modelStatus}
                            </Button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Link>
                  </div>
                );
              })} */}
            {/* </div> */}
          {/* </div>
        </div>
      </div> */}
    </>
  );
};

export default Mymodals;
