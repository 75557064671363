import React, { useEffect, useState, useRef } from "react";
import * as THREE from "three";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";

function ThreeScene(props) {
  const link = props.link;
  const Human =
    "https://pixel-mortar.s3.ap-south-1.amazonaws.com/+mx3d-assests/human.glb";
  const canvasRef = useRef(null);
  const [scene, setScene] = useState(null);
  const [camera, setCamera] = useState(null);
  const [renderer, setRenderer] = useState(null);
  // console.log(link);
  let boundBox = { x: 0, y: 0, z: 0 };
  useEffect(() => {
    // create a new scene
    const newScene = new THREE.Scene();
    newScene.background = new THREE.Color(0x303030);
    setScene(newScene);

    // create a new camera
    const container = document.getElementById("viewport");

    const newCamera = new THREE.PerspectiveCamera(
      75,
      container.offsetWidth / container.offsetHeight,
      0.1,
      1000
    );
    newCamera.position.set(-1, 0.9, 1.3);
    setCamera(newCamera);

    // create a new renderer
    const newRenderer = new THREE.WebGLRenderer({ antialias: true });
    newRenderer.setSize(container.offsetHeight, container.offsetWidth);
    setRenderer(newRenderer);

    const newSize = { width: 850, height: 600 };
    newRenderer.setSize(newSize.width, newSize.height);
    newRenderer.toneMapping = THREE.LinearToneMapping
    if (canvasRef.current) {
      canvasRef.current.appendChild(newRenderer.domElement);
    }

    return () => {
      if (canvasRef.current) {
        canvasRef.current.removeChild(newRenderer.domElement);
      }
    };
  }, [link]);

  useEffect(() => {
    if (!scene || !camera || !renderer) return;
    let human;

    // load the 3D model
    const loader = new GLTFLoader();
    loader.load(Human, (gltf) => {
      const model = gltf.scene;
      scene.add(model);
      const meshes = gltf.scene.children.filter((child) =>
        child.type.includes("Mesh")
      );

      human = meshes[0];
      human.position.setX(0.8);
    });
    loader.load(
      // "https://pixel-mortar.s3.ap-south-1.amazonaws.com/+mx3d-assests/PXM_LBD_0d0a1ba8_71b7.glb",
      link,
      function (gltf) {
        // console.log(link);
        scene.add(gltf.scene);

        // Add pivot
        const geometry = new THREE.SphereGeometry();
        const material = new THREE.MeshBasicMaterial({ color: "red" });

        material.depthTest = false;

        const sphere = new THREE.Mesh(geometry, material);
        sphere.name = "Pivot";
        sphere.scale.set(0.015, 0.015, 0.015);

        const meshes = gltf.scene.children.filter(
          (child) => child.type === "Mesh"
        );

        const pos = meshes[0].position;

        scene.add(sphere);
        sphere.position.set(pos.x, pos.y, pos.z);

        // Bounding box
        const box = new THREE.Box3();
        meshes[0].geometry.computeBoundingBox();
        box
          .copy(meshes[0].geometry.boundingBox)
          .applyMatrix4(meshes[0].matrixWorld);

        // const helper = new THREE.Box3Helper(box, HELPER_COLOR)
        // scene.add(helper)

        boundBox.x = box.max.x - box.min.x;
        boundBox.y = box.max.y - box.min.y;
        boundBox.z = box.max.z - box.min.z;

        const GLB = gltf;

        //   document.querySelector('#nas-address').textContent = currentModelPath
        //   document.querySelector('.ref-image').setAttribute('src', imagePaths[0])

        //   updateInterfaceTags()
        //   setLoading(false)
      },
      (xhr) => {
        console.log((xhr.loaded / xhr.total) * 100 + "% loaded");
      },
      (error) => {
        console.log(error);
      }
    );

    const dirLight = new THREE.DirectionalLight(0xffffff, 0.8);
    dirLight.position.set(3, 10, 10);
    scene.add(dirLight);

    const ambientLight = new THREE.AmbientLight(0xffffff);
    scene.add(ambientLight);

    const controls = new OrbitControls(camera, renderer.domElement);
    controls.update();

    // update the scene on each frame
    const animate = () => {
      requestAnimationFrame(animate);
      renderer.render(scene, camera);
      let boundX = document.querySelector("#bound-x");
      let boundY = document.querySelector("#bound-y");
      let boundZ = document.querySelector("#bound-z");

      boundX.textContent = `${Math.round(boundBox.x * 1000) } mm`;
      boundY.textContent = `${Math.round(boundBox.z * 1000) } mm`;
      boundZ.textContent = `${Math.round(boundBox.y * 1000) } mm`;
    };
    animate();
  }, [scene, camera, renderer, link]);

  return (
    <div
      id="viewport"
      ref={canvasRef}
      style={{ width: "100%", height: "100%" }}
    />
  );
}

export default ThreeScene;
