import React, { useState } from "react";
import { Button, Modal, Form } from "react-bootstrap";
import { supabase } from "../client";
import { v4 as uuidv4 } from "uuid";


function QcFail(props) {
  const fileID = props.fileId;
  const files = props.files;
  const [show, setShow] = useState(false);
  const id = props.id;
  const modellerID = props.modellerid
  const address = JSON.parse(localStorage.getItem('address'))
  // console.log(address.city)
  const handleClose = () => {
    setShow(false);
    setClickedButtons([]);
    setComments("");
    setError([]);
  };
  const handleShow = () => setShow(true);
  const [comments, setComments] = useState("");
  const [error, setError] = useState([]);
  const [clickedButtons, setClickedButtons] = useState([]);

  const handleCommentsChange = (e) => {
    setComments(e.target.value);
    setError([...clickedButtons, comments]);
    // Update the comments value as the user types
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    // console.log(error);
    fail();
    handleClose();
    setClickedButtons([]);
    setComments("");
    setError([]);
  };

  const handleButtonClick = (value) => {
    const index = clickedButtons.indexOf(value);
    if (index > -1) {
      setClickedButtons(clickedButtons.filter((item) => item !== value));
    } else {
      setClickedButtons([...clickedButtons, value]);
      if (comments === "") {
        setError([...clickedButtons, value]);
      } else {
        setError([...clickedButtons, value, comments]);
      }
    }
  };

  const fail = async () => {
    const modifiedfile = files.find((file) => file.id === fileID);
    // const keys = s3key.split("/")
    if (modifiedfile) {
      modifiedfile["status"] = "ar_fail";
      await supabase
        .from("asset_data")
        .update({
          blender_files: files,
          errorMsg: error,
          modelStatus: "ar_fail",
        })
        .match({ id: id });
      // const add = await fetch("https://geolocation-db.com/json/", {
      //   method: "GET",
      // });
      // const resp = await add.json();
      // console.log(resp.IPv4 , resp.city)

      await supabase
        .from("logs")
        .insert({
          id: uuidv4(),
          modellerId: modellerID,
          productId: id,
          action: "ar_fail",
          ipAdd: address.IPv4,
          location: address.city,
        });
    }
    window.location.reload(false);
  };
  return (
    <>
      <Button
        variant="primary"
        onClick={handleShow}
        style={{
          width: "120px",
          height: "30px",
          display: "flex",
          fontWeight: "700",
          color: "black",
          alignItems: "center",
          justifyContent: "center",
          borderRadius: "50vh",
          backgroundColor: "#D89DA4",
          borderColor: "#964C55",
          borderWidth: "2px",
        }}
      >
        QC FAIL
      </Button>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Feedback</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <button
              onClick={() => handleButtonClick("Scale")}
              className={clickedButtons.includes("Scale") ? "active" : ""}
            >
              Scale
            </button>
            <button
              onClick={() => handleButtonClick("Texture")}
              className={clickedButtons.includes("Texture") ? "active" : ""}
            >
              Texture
            </button>
            <button
              onClick={() => handleButtonClick("Base model")}
              className={clickedButtons.includes("Base model") ? "active" : ""}
            >
              Base model
            </button>
          </div>
          <br></br>
          {/* <div style={{}}>{clickedButtons}</div> */}
          <Form onSubmit={handleSubmit}>
            <Form.Group controlId="formComment">
              <Form.Label>Comments</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                onChange={handleCommentsChange}
                value={comments + clickedButtons}
              />
            </Form.Group>
            <Button variant="primary" type="submit">
              Submit
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default QcFail;
