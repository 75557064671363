import React, { useState } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import ThreeDot from "../public/lottie/ThreeDot.svg";
import { supabase } from "../client";
import { useParams } from "react-router";
import { Modal } from "react-bootstrap";
// import "@google/model-viewer";
import Viewer from "./viewer";
import { v4 as uuidv4 } from "uuid";

function ModelActions(props) {
  const stats = props.status;
  const fileID = props.fileID;
  const s3key = props.s3key;
  const modelid = props.modelid;
  const { id } = useParams();
  const userid = localStorage.getItem("user");
  const files = props.data;
  const [showModal, setShowModal] = useState(false);
  const [showModal2, setShowModal2] = useState(false);
  const [errormsg, setErrormsg] = useState([]);
  const [glbsrc, setGlbsrc] = useState(null);
  const address = JSON.parse(localStorage.getItem("address"));

  const removeFile = async (fileID, s3key) => {
    const keys = s3key.split("/");
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/delete/${keys[0]}/${keys[1]}`,
        {
          method: "POST",
        }
      );
      const result = await response.json();
      // console.log(result);
    } catch (error) {
      console.error(error);
    }
    const modifiedfile = files.find((file) => file.id === fileID);
    if (modifiedfile) {
      const type = modifiedfile["fileType"];
      const remove = files.indexOf(modifiedfile);
      files.splice(remove, 1);
      await supabase
        .from("asset_data")
        .update({ blender_files: files })
        .match({ id: id });
      // const add = await fetch("https://geolocation-db.com/json/", {
      //   method: "GET",
      // });
      // const resp = await add.json();
      // console.log(resp.IPv4 , resp.city)

      await supabase.from("logs").insert({
        id: uuidv4(),
        modellerId: userid,
        productId: id,
        action: `${type} file removed`,
        ipAdd: address.IPv4,
        location: address.city,
      });
      window.location.reload(false);
    }
  };

  const view3d = async (fileID) => {
    // if(fileID){

    setShowModal2(true);
    const modifiedfile = files.find((file) => file.id === fileID);
    // console.log(modifiedfile['glbLink'])
    const key = modifiedfile["glbLink"].replace(
      `https://${process.env.REACT_APP_BLENDER_BUCKET}.s3.ap-south-1.amazonaws.com/`,
      ""
    );
    const response = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/model3d/${modelid}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ s3key: key }),
      }
    );
    const result = await response.json();
    setGlbsrc(result["model_link"]);
  };

  const armodel = async (modelid) => {
    setShowModal2(true);
    // console.log(modelid)
    const key = `${modelid}.glb`;
    const response = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/armodel`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ s3key: key }),
      }
    );
    const result = await response.json();
    setGlbsrc(result["model_link"]);
  };

  const handleDownload = async (fileID) => {
    const modifiedfile = files.find((file) => file.id === fileID);
    // console.log(modifiedfile['glbLink'])
    const type = modifiedfile["fileType"];
    const key = modifiedfile["s3link"].replace(
      `https://${process.env.REACT_APP_BLENDER_BUCKET}.s3.ap-south-1.amazonaws.com/`,
      ""
      );
    const response = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/downloadfile`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ s3key: key }),
      }
    );
    const result = await response.json();
    // const add = await fetch("https://geolocation-db.com/json/", {
    //   method: "GET",
    // });
    // const resp = await add.json();
    // console.log(resp.IPv4 , resp.city)

    await supabase.from("logs").insert({
      id: uuidv4(),
      modellerId: userid,
      productId: id,
      action: `${type}_file downloaded`,
      ipAdd: address.IPv4,
      location: address.city,
    });
    window.open(result["model_link"], "_blank");
  };
  const handleDownload2 = async (fileID) => {
    const modifiedfile = files.find((file) => file.id === fileID);
    // console.log(modifiedfile['glbLink'])
    const key = `${modelid}/conversion_log.txt`;
    const response = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/downloadfile`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ s3key: key }),
      }
    );
    const result = await response.json();
    window.open(result["model_link"], "_blank");
  };

  const convert = async (fileID, s3key) => {
    const modifiedfile = files.find((file) => file.id === fileID);
    let count = files.reduce(function (acc, curr) {
      if (curr.status === "in_conversion") {
        acc++;
      }
      return acc;
    }, 0);
    if (modifiedfile && count < 1) {
      modifiedfile["status"] = "in_conversion";
      await supabase
        .from("asset_data")
        .update({ blender_files: files, modelStatus: "in_conversion" })
        .match({ id: id });
      // const add = await fetch("https://geolocation-db.com/json/", {
      //   method: "GET",
      // });
      // const resp = await add.json();
      // console.log(resp.IPv4 , resp.city)

      await supabase.from("logs").insert({
        id: uuidv4(),
        modellerId: userid,
        productId: id,
        action: "Conversion_submitted",
        ipAdd: address.IPv4,
        location: address.city,
      });
      window.location.reload(false);
    } else {
      alert("already one model has been submitted");
    }
  };

  const submit = async (fileID) => {
    const modifiedfile = files.find((file) => file.id === fileID);
    let count = files.reduce(function (acc, curr) {
      if (curr.status === "submitted_for_qc") {
        acc++;
      }
      return acc;
    }, 0);
    if (modifiedfile && count < 1) {
      modifiedfile["status"] = "submitted_for_qc";
      await supabase
        .from("asset_data")
        .update({
          blender_files: files,
          modelStatus: "submitted_for_qc",
          glbLink: modifiedfile["glbLink"],
          fileId: fileID,
        })
        .match({ id: id });
      // const add = await fetch("https://geolocation-db.com/json/", {
      //   method: "GET",
      // });
      // const resp = await add.json();

      await supabase.from("logs").insert({
        id: uuidv4(),
        modellerId: userid,
        productId: id,
        action: "submitted for qc",
        ipAdd: address.IPv4,
        location: address.city,
      });
      window.location.reload(false);
    } else {
      alert("already one model has been submitted");
    }
  };

  const viewError = async () => {
    setShowModal(true);
    const data = await supabase
      .from("asset_data")
      .select("errorMsg")
      .eq("id", id);
    //  console.log(data.data[0].errorMsg)
    if (data) {
      setErrormsg(data.data[0].errorMsg);
    }
  };

  if (stats === "uploaded") {
    return (
      <div style={{ zIndex: "5" }}>
        <Dropdown>
          <Dropdown.Toggle
            style={{
              backgroundColor: "#343434",
              width: "10px",
              justifyContent: "center",
              alignItems: "center",
              height: "20px",
              border: "none",
              display: "flex",
              flexDirection: "row",
            }}
          >
            {/* <img
              style={{ width: "5px", height: "15px" }}
              src={ThreeDot}
              alt=""
            /> */}
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item onClick={() => convert(fileID, s3key)}>
              {" "}
              Convert{" "}
            </Dropdown.Item>
            <Dropdown.Item onClick={() => handleDownload(fileID)}>
              {" "}
              Download File{" "}
            </Dropdown.Item>
            <Dropdown.Item onClick={() => removeFile(fileID, s3key)}>
              Delete
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>
    );
  } else if (stats === "conversion_fail") {
    return (
      <div>
        <Dropdown>
          <Dropdown.Toggle
            style={{
              backgroundColor: "#343434",
              width: "10px",
              justifyContent: "center",
              alignItems: "center",
              height: "20px",
              border: "none",
              display: "flex",
              flexDirection: "row",
            }}
          >
            <img
              style={{ width: "15px", height: "15px" }}
              src={ThreeDot}
              alt=""
            />
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item onClick={() => handleDownload(fileID)}>
              {" "}
              Download File{" "}
            </Dropdown.Item>
            <Dropdown.Item onClick={() => handleDownload2(fileID)}>
              {" "}
              Error File{" "}
            </Dropdown.Item>
            <Dropdown.Item onClick={() => removeFile(fileID, s3key)}>
              Delete
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>
    );
  } else if (stats === "ar_ready") {
    return (
      <div>
        <Dropdown>
          <Dropdown.Toggle
            style={{
              backgroundColor: "#343434",
              width: "10px",
              justifyContent: "center",
              alignItems: "center",
              height: "20px",
              border: "none",
              display: "flex",
              flexDirection: "row",
            }}
          >
            <img
              style={{ width: "15px", height: "15px" }}
              src={ThreeDot}
              alt=""
            />
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item onClick={() => submit(fileID, s3key)}>
              {" "}
              Submit{" "}
            </Dropdown.Item>
            <Dropdown.Item onClick={() => view3d(fileID)}>
              {" "}
              View 3D{" "}
            </Dropdown.Item>
            <Dropdown.Item onClick={() => handleDownload(fileID)}>
              {" "}
              Download File{" "}
            </Dropdown.Item>
            <Modal show={showModal2} onHide={() => setShowModal2(false)}>
              {/* <Modal.Body style={{ height: "600px"}} id="viewport">
                {glbsrc !== null?<Viewer link={glbsrc}/>:""} */}
              <div id="viewport" style={{ widht: "850px", height: "600px" }}>
                {/* {glbsrc !== null ? <Viewer link={glbsrc} /> : ""} */}
                <model-viewer
                  style={{ width: "100%", height: "100%" }}
                  src={glbsrc}
                  alt=""
                  shadow-intensity="1"
                  ar-scale="fixed"
                  environment-image="neutral"
                  camera-controls
                  touch-action="pan-y"
                  auto-rotate
                  ar-modes="webxr scene-viewer quick-look"
                  ar
                />{" "}
                {/* </Modal.Body> */}
              </div>
            </Modal>
            <Dropdown.Item onClick={() => removeFile(fileID, s3key)}>
              Delete
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>
    );
  } else if (stats === "ar_fail") {
    return (
      <div>
        <Dropdown>
          <Dropdown.Toggle
            style={{
              backgroundColor: "#343434",
              width: "10px",
              justifyContent: "center",
              alignItems: "center",
              height: "20px",
              border: "none",
              display: "flex",
              flexDirection: "row",
            }}
          >
            <img
              style={{ width: "15px", height: "15px" }}
              src={ThreeDot}
              alt=""
            />
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item onClick={() => handleDownload(fileID)}>
              {" "}
              Download File{" "}
            </Dropdown.Item>
            <Dropdown.Item onClick={viewError}> Error Message </Dropdown.Item>
            <Modal show={showModal} onHide={() => setShowModal(false)}>
              <Modal.Body>{errormsg}</Modal.Body>
            </Modal>
            <Dropdown.Item onClick={() => view3d(fileID)}>
              {" "}
              View 3D{" "}
            </Dropdown.Item>
            <Modal show={showModal2} onHide={() => setShowModal2(false)}>
              <div id="viewport" style={{ widht: "850px", height: "600px" }}>
                {/* {glbsrc !== null ? <Viewer link={glbsrc} /> : ""} */}
                {/* <Modal.Body style={{ height: "600px"}} id="viewport">
                {glbsrc !== null?<Viewer link={glbsrc}/>:""} */}
                <model-viewer
                  style={{ width: "100%", height: "100%" }}
                  src={glbsrc}
                  alt=""
                  shadow-intensity="1"
                  ar-scale="fixed"
                  environment-image="neutral"
                  camera-controls
                  touch-action="pan-y"
                  auto-rotate
                  ar-modes="webxr scene-viewer quick-look"
                  ar
                />{" "}
              </div>
              {/* </Modal.Body> */}
            </Modal>
            <Dropdown.Item onClick={() => removeFile(fileID, s3key)}>
              Delete
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>
    );
  } else if (stats === "ar_pass" || stats === "bundle_ready") {
    return (
      <div>
        <Dropdown>
          <Dropdown.Toggle
            style={{
              backgroundColor: "#343434",
              width: "10px",
              justifyContent: "center",
              alignItems: "center",
              height: "20px",
              border: "none",
              display: "flex",
              flexDirection: "row",
            }}
          >
            <img
              style={{ width: "15px", height: "15px" }}
              src={ThreeDot}
              alt=""
            />
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item onClick={() => armodel(modelid)}>
              {" "}
              View 3D{" "}
            </Dropdown.Item>
            <Modal
              show={showModal2}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                // width : "500px"
              }}
              onHide={() => setShowModal2(false)}
            >
              {/* <Modal.Body style={{ height: "630px"}} id="viewport"> */}
              <div id="viewport" style={{ height: "600px" }}>
                {/* {glbsrc !== null ? <Viewer link={glbsrc} /> : ""} */}
                <model-viewer
                  style={{ width: "100%", height: "100%" }}
                  src={glbsrc}
                  alt=""
                  shadow-intensity="1"
                  ar-scale="fixed"
                  environment-image="neutral"
                  camera-controls
                  touch-action="pan-y"
                  auto-rotate
                  ar-modes="webxr scene-viewer quick-look"
                  ar
                />{" "}
                {/* </Modal.Body> */}
              </div>
            </Modal>
            <Dropdown.Item onClick={() => handleDownload(fileID)}>
              {" "}
              Download File{" "}
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>
    );
  } else {
    return null;
  }
}

export default ModelActions;
