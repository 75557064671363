import React, { useRef, useEffect, useState } from "react";
import { supabase } from "../client";
import { v4 as uuidv4 } from "uuid";
import { useParams, Link } from "react-router-dom";
import { FileUploader } from "react-drag-drop-files";

function GlbUpload(props) {
  const fileInputRef = useRef();
  const { id } = useParams();
  const userid = localStorage.getItem('user')

  const modelId = props.modelid;

  async function handleFileUpload(file) {
    // // const file = event.target.files[0];
    // // const blendLink = "https://models--3d.s3.ap-south-1.amazonaws.com";
    // const filename = file.name.trim();
    const fileSize = (file.size / 1048576).toFixed(2); // Convert file size to MB
    const formData = new FormData();
    formData.append("file", file);
    const modDate = new Date(file.lastModified).toISOString();
    const newDate = new Date().toISOString();

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/uploadglb/${modelId}`,
        {
          method: "POST",
          body: formData,
        }
      );
      const result = await response.json();

      if (result.message === "Model uploaded successfully") {
        const { data, error } = await supabase
          .from("asset_data")
          .select("blender_files")
          .eq("id", id);

        if (data) {
          const list = data[0].blender_files || []; // Handle null list
          const newDetails = {
            id: uuidv4(),
            fileName: `${modelId}.glb`,
            fileType: "glb",
            fileSize: fileSize,
            createdDate: modDate,
            modifiedDate: newDate,
            glbLink: `https://${process.env.REACT_APP_BLENDER_BUCKET}.s3.ap-south-1.amazonaws.com/${modelId}/${modelId}.glb`,
            s3link: `https://${process.env.REACT_APP_BLENDER_BUCKET}.s3.ap-south-1.amazonaws.com/${modelId}/${modelId}.glb`,
            // s3link: `${blendLink}/${modelId}/${filename.replace(/\s+/g, "+")}`,
            s3key: `${modelId}/${modelId}.glb`,
            status: "ar_ready",
          };
          const updatedList = [...list, newDetails];

          await supabase
            .from("asset_data")
            .update({ blender_files: updatedList })
            .match({ id: id });


          const add = await fetch("https://geolocation-db.com/json/", {
            method: "GET",
          });
          const resp = await add.json();
          // console.log(resp.IPv4 , resp.city)

          await supabase.from("logs").insert({
            id: uuidv4(),
            modellerId: userid,
            productId: id,
            action: "glb file uploaded",
            ipAdd: resp.IPv4,
            location: resp.city,
          });

          alert(result.message);
          window.location.reload(false);
        }
      } else {
        alert("Model upload failed");
      }
    } catch (error) {
      console.error(error);
      alert("An error occurred during file upload");
    }
  }

  return (
    <div
      onClick={() => {
        fileInputRef.current.click();
      }}
    >
      <FileUploader handleChange={(file) => handleFileUpload(file)} />
    </div>
  );
}

export default GlbUpload;
