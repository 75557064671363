import React, { useState, useRef, useEffect } from "react";
import axios from "axios";
import { supabase } from "../client";

const HotspotImage = ({
  showModal,
  setShowModal,
  productlist,
  eliyaID,
  materials,
}) => {
  const [hotspotData, setHotspotData] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);
  const [fileInputKey, setFileInputKey] = useState(Date.now());
  const [imageList, setImageList] = useState([]);
  const [comment, setComment] = useState("");
  const [inputValues, setInputValues] = useState(null);
  const [x, setX] = useState(null);
  const [y, setY] = useState(null);
  const [scaleX, setScaleX] = useState(1);
  const [scaleY, setScaleY] = useState(1);
  const imageRef = useRef(null);
  const containerRef = useRef(null);
  const [comments, setComments] = useState([]);
  const [commentStates, setCommentStates] = useState([]);
  const [products, setProducts] = useState([]);
  const [Materials, setMaterials] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [selectedMaterial, setSelectedMaterial] = useState(null);
  const [activeImage, setActiveImage] = useState(0);
  const [activeProduct, setActiveProduct] = useState(null);
  const [activeMaterial, setActiveMaterial] = useState(null);
  const [fileName, setFileName] = useState("");
  const [imageType, setImageType] = useState({});
  const [allfiles, setAllfiles] = useState();

  const imageTypes = [
    "square_front",
    "square_back",
    "landscape_front",
    "landscape_back",
  ];

  const handleImageChange = (e) => {
    setProducts(productlist[0].products.data);
    setMaterials(materials);
    const files = e.target.files;
    setAllfiles(files);
    const newImageList = [];
    const newHotSpotData = [];
    const newInputValues = [];
    const newComments = [];
    const newCommentStates = [];
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      const reader = new FileReader();
      reader.onloadend = () => {
        newImageList.push(reader.result);
        newHotSpotData.push([]);
        newInputValues.push("");
        newComments.push([]);
        setTimeout(() => {
          newComments[i]["filename"] = file.name;
        }, 1000);
        newCommentStates.push(Array(newHotSpotData.length).fill(false));
        if (i === files.length - 1) {
          setSelectedImage(newImageList[0]);
          setFileName(files[0].name);
          setActiveImage(0);
          setImageList(newImageList);
          setHotspotData(newHotSpotData);
          setInputValues(newInputValues);
          setComments(newComments);
          setCommentStates(newCommentStates);
        }
      };
      reader.readAsDataURL(file);
    }
  };

  const handleImageType = (event) => {
    const selectedType = event.target.value;
    setImageType(selectedType);

    const updatedComments = [...comments];
    updatedComments[activeImage] = updatedComments[activeImage] || [];
    updatedComments[activeImage]["file_type"] = selectedType;
    setComments(updatedComments);
  };

  const handleImageSelect = (index) => {
    if (imageList[index]) {
      setActiveImage(index);
      setSelectedImage(imageList[index]);
      setFileName(comments[index].filename);
      // setInputValues(comments[index] || []);
      setActiveProduct(null);
      setActiveMaterial(null);
      setSelectedProduct(null);
      setSelectedMaterial(null);
      setImageType({});
    }
  };

  const handleSelectProduct = (index) => {
    if (products[index]) {
      setActiveProduct(index);
      setSelectedProduct(products[index]);
      setInputValues(products[index]);
      setActiveMaterial(null);
    }
  };

  const handleMaterialSelect = (index) => {
    if (materials[index]) {
      setActiveMaterial(index);
      setSelectedMaterial(materials[index]);
      setInputValues(materials[index]);
      setActiveProduct(null);
    }
    // console.log(inputValues);
  };

  const handleCancel = () => {
    setSelectedImage(null);
    setSelectedProduct(null);
    setSelectedMaterial(null);
    setImageList([]);
    setFileInputKey(Date.now());
    setHotspotData([]);
    setInputValues([""]);
    setComments([]);
    setActiveImage(null);
    setActiveProduct(null);
    setActiveMaterial(null);
    setFileName("");
    setImageType(null);
  };

  const handleAddComment = (imageIndex, hotspotIndex) => {
    const updatedComments = [...comments];
    if (!updatedComments[imageIndex]) {
      updatedComments[imageIndex] = [];
    }

    const hotspotComment = {
      hotspot: hotspotData[imageIndex][hotspotIndex],
      comment: inputValues || "",
    };
    updatedComments[imageIndex].push(hotspotComment);
    setComments(updatedComments);
    const newCommentStates = [...commentStates];
    newCommentStates[imageIndex][hotspotIndex] = true;

    setCommentStates(newCommentStates);
    // setInputValues([""]);
  };
  console.log(comments);

  const handleRemoveHotspot = (imageIndex, hotspotIndex) => {
    const updatedHotspots = [...hotspotData];
    updatedHotspots[imageIndex].splice(hotspotIndex, 1);
    setHotspotData(updatedHotspots);

    const updatedComments = [...comments];
    if (updatedComments[imageIndex]) {
      updatedComments[imageIndex].splice(hotspotIndex, 1);

      // const updatedInputValues = [...inputValues];
      // if (updatedInputValues[imageIndex]) {
      //   updatedInputValues[imageIndex].splice(hotspotIndex, 1);
      // }

      const newHotspotData = [...hotspotData];
      newHotspotData[imageIndex] = updatedHotspots;

      const newComments = [...comments];
      newComments[imageIndex] = updatedComments;

      // const newInputValues = [...inputValues];
      // newInputValues[imageIndex] = updatedInputValues;

      const newCommentStates = [...commentStates];
      newCommentStates[imageIndex].splice(hotspotIndex, 1);

      setComments(updatedComments);
      // setInputValues(updatedInputValues);
      setCommentStates(newCommentStates);
    }
  };

  const handleUpload = async () => {
    // const res = await axios.post("/renderImage/upload", {
    //   params: imageList,
    // });
    // if (res.status === 200) {
    //   console.log("Files uploaded successfully");
    // } else {
    //   console.log("Error in upoading Files");
    // }
    const hotspotdict = {};
    const files = {};
    comments.map((cmt) => {
      const temp = [];
      const tempdict = {};
      const filetype = cmt["file_type"];
      cmt.map((ct, index) => {
        // const htspt = {}
        if (ct.comment.product_db_id) {
          const htspt = { ...ct.hotspot, product: ct.comment.product_db_id };
          temp.push(htspt);
        } else {
          const htspt = {
            ...ct.hotspot,
            product: {
              reffcode: ct.comment.reffcode,
              name: ct.comment.textureName,
            },
          };
          temp.push(htspt);
        }
      });
      tempdict["hotspots"] = temp;
      tempdict[
        "imagelink"
      ] = `https://d22rpr938kmp9k.cloudfront.net/${eliyaID}/${filetype}.jpg`;
      hotspotdict[filetype] = tempdict;
      files[filetype] = cmt["filename"];
    });
    console.log(hotspotdict);
    const formData = new FormData();
    console.log(allfiles);
    for (const key in allfiles) {
      if (allfiles.hasOwnProperty(key)) {
        const file = allfiles[key];
        formData.append("file", file);
      }
    }
    formData.append("fileinfo", JSON.stringify(files));
    formData.append("eliyaID", eliyaID);

    const response = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/renderupload`,
      {
        method: "POST",
        body: formData,
      }
    );
    const result = await response.json();

    if (result["message"] === "Successfully uploaded") {
      const pagecomp = {
        square: `https://d22rpr938kmp9k.cloudfront.net/${eliyaID}/square_front.jpg`,
        landscape: `https://d22rpr938kmp9k.cloudfront.net/${eliyaID}/landscape_front.jpg`,
      };
      await supabase
        .from("moodboards")
        .update({ imagerenders: hotspotdict, roomstatus: "Done" , renderimg : pagecomp})
        .eq("eliyaid", eliyaID);
      setShowModal(false);
    } else {
      alert("Upload failed");
    }
  };

  const handleImageClick = (event, imageIndex) => {
    if (selectedProduct !== null || selectedMaterial !== null) {
      const rect = event.target.getBoundingClientRect();
      const xCoord = ((event.clientX - rect.left) / rect.width) * 100;
      const yCoord = ((event.clientY - rect.top) / rect.height) * 100;

      const updatedHotspots = [...hotspotData];
      updatedHotspots[imageIndex].push({ x: xCoord, y: yCoord });
      setHotspotData(updatedHotspots);
      setComment("");
      setX(xCoord);
      setY(yCoord);
    }
  };

  const calcuateScale = () => {
    if (imageRef.current && containerRef.current) {
      const imageWidth = imageRef.current.width;
      const imageHeight = imageRef.current.height;

      const containerWidth = containerRef.current.clientWidth;
      const containerHeight = containerRef.current.clientHeight;

      const scaleX = containerWidth / imageWidth;
      const scaleY = containerHeight / imageHeight;

      setScaleX(scaleX);
      setScaleY(scaleY);
    }
  };

  useEffect(() => {
    window.addEventListener("resize", calcuateScale);
    return () => {
      window.removeEventListener("resize", calcuateScale);
    };
  }, []);

  return (
    <>
      {showModal && (
        <div
          className={`fixed inset-0 flex items-center justify-center z-50 ${
            showModal ? "bg-black bg-opacity-50" : ""
          }`}
        >
          <div
            className={`flex flex-col ${
              selectedImage === null
                ? ""
                : "2xl:h-[800px] xl:h-[600px] 2xl:max-w-max xl:max-w-7xl"
            } bg-white rounded-md p-4`}
          >
            <div
              className={`${
                selectedImage === null ? "" : "flex flex-row gap-2"
              } w-full h-full`}
            >
              <div
                className={`flex flex-col gap-4 ${
                  selectedImage === null
                    ? "w-full"
                    : "w-2/3 h-full justify-around"
                }`}
              >
                {selectedImage === null && (
                  <div className="flex flex-row gap-4 items-center justify-center">
                    <input
                      className="h-16 text-white"
                      type="file"
                      name="file"
                      multiple
                      accept="*"
                      onChange={handleImageChange}
                    />
                    <div
                      className="cursor-pointer"
                      onClick={() => setShowModal(false)}
                    >
                      <img
                        className="w-6 h-6 cursor-pointer"
                        src="https://dev-monkxperience.s3.ap-south-1.amazonaws.com/eliya/close.svg"
                        alt=""
                      />
                    </div>
                  </div>
                )}
                {selectedImage && (
                  <>
                    <div className="w-full flex flex-row justify-around">
                      <div className="text-center text-xl font-semibold">
                        {fileName}
                      </div>
                      <select
                        className="flex p-1 focus:outline-none border-b-1 border-black"
                        value={imageType}
                        onChange={handleImageType}
                      >
                        <option>Image Type</option>
                        {imageTypes.map((imageType, index) => (
                          <option value={imageType} key={index}>
                            {imageType}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div
                      className="w-full h-4/5 relative"
                    >
                      <img
                        ref={(element) => {
                          containerRef.current = element;
                          imageRef.current = element;
                        }}
                        className="w-full h-full object-fit cursor-pointer"
                        src={selectedImage}
                        alt=""
                        onClick={(event) =>
                          handleImageClick(
                            event,
                            imageList.indexOf(selectedImage)
                          )
                        }
                      />
                      {(selectedProduct !== null ||
                        selectedMaterial !== null) &&
                        hotspotData[imageList.indexOf(selectedImage)].map(
                          (hotspot, index) => (
                            <div
                              key={index}
                              className="absolute w-4 h-4 bg-red-500 rounded-full cursor-pointer"
                              style={{
                                left: `${hotspot.x}%`,
                                top: `${hotspot.y}%`,
                              }}
                            >
                              <div className="relative left-1/2 w-full text-black rounded text-xs">
                                <div className="relative top-2 flex flex-row gap-2 px-2 text-black">
                                  {!commentStates[
                                    imageList.indexOf(selectedImage)
                                  ][index] ? (
                                    <button
                                      onClick={() => {
                                        handleAddComment(
                                          imageList.indexOf(selectedImage),
                                          index
                                        );
                                      }}
                                      className="flex px-2 py-0.5 font-medium bg-teal-300 rounded-sm"
                                    >
                                      Add+
                                    </button>
                                  ) : null}
                                  <button
                                    onClick={() =>
                                      handleRemoveHotspot(
                                        imageList.indexOf(selectedImage),
                                        index
                                      )
                                    }
                                    className="flex px-2 py-0.5 font-medium bg-red-300 rounded-sm"
                                  >
                                    Remove
                                  </button>
                                </div>
                              </div>
                            </div>
                          )
                        )}
                    </div>
                  </>
                )}
                {selectedImage && (
                  <div className="flex flex-row w-full justify-around">
                    <button
                      onClick={() => handleUpload()}
                      className="flex px-4 rounded-full py-2 bg-black text-white text-2xl justify-center items-center"
                    >
                      Upload
                    </button>
                    <button
                      onClick={() => handleCancel()}
                      className="flex px-4 py-2 text-2xl rounded-full bg-black text-white justify-center items-center"
                    >
                      Cancel
                    </button>
                  </div>
                )}
              </div>
              {selectedImage && (
                <div className="flex w-max flex-col gap-2 max-h-[700px] overflow-y-auto pr-2">
                  {products && products.map((product, index) => (
                    <div
                      onClick={() => handleSelectProduct(index)}
                      className={`flex p-1 cursor-pointer ${
                        index === activeProduct ? "border-2 border-black" : ""
                      }`}
                      key={index}
                    >
                      <img
                        className="w-48 h-40 object-contain"
                        src={product.productImage[0]}
                        alt=""
                      />
                    </div>
                  ))}
                </div>
              )}
              {selectedImage && (
                <div className="flex w-max flex-col gap-2 max-h-[700px] overflow-y-auto pr-2">
                  {Materials.map((material, index) => (
                    <div
                      className={`flex p-1 cursor-pointer ${
                        index === activeMaterial ? "border-2 border-black" : ""
                      }`}
                      key={index}
                      onClick={() => handleMaterialSelect(index)}
                    >
                      <img
                        className="w-48 h-40 object-contain"
                        src={material.textureImage[0]}
                        alt=""
                      />
                    </div>
                  ))}
                </div>
              )}
              {selectedImage && (
                <div className="flex border border-gray-400"></div>
              )}
              <div className="flex flex-col w-1/3 items-center gap-4 max-h-[700px] overflow-y-auto">
                {imageList.map((image, index) => (
                  <img
                    onClick={() => handleImageSelect(index)}
                    className={`h-64 cursor-pointer ${
                      index === activeImage ? "border-2 border-black" : ""
                    } object-contain`}
                    key={index}
                    src={image}
                    alt=""
                  />
                ))}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default HotspotImage;
