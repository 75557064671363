import React, { useState, useEffect, useRef } from "react";
import { supabase } from "../client";
import { Link, useHistory } from "react-router-dom";
import { Button, Step } from "@material-ui/core";
import style from "./myModals.module.css";
import Topbar from "../layouts/topbar";
import Sidebar from "../layouts/sidebar";
import Search from "../public/lottie/chevron_right.svg";
import { debounce } from "lodash";

const AdminModals = ({ history }) => {
  const [models, setModels] = useState([]);
  const [brandList, setBrandList] = useState([])
  const [modelerList, setModelerList] = useState([])
  const userData = localStorage.getItem("user");
  const role = localStorage.getItem("role");

  const lastItemRef = useRef();

  let priorities = [
    { id: 1, name: "low" },
    { id: 2, name: "medium" },
    { id: 3, name: "high" },
    { id: 4, name: "sprint" },
  ];
  let difficulties = [
    { id: 1, name: "L1" },
    { id: 2, name: "L2" },
    { id: 3, name: "L3" },
    { id: 4, name: "L4" },
  ];

  let statuses = [
    { id: 1, name: "in_conversion" },
    { id: 2, name: "conversion_fail" },
    { id: 3, name: "ar_ready" },
    { id: 4, name: "ar_fail" },
    { id: 5, name: "ar_pass" },
    { id: 6, name: "assigned" },
    { id: 7, name: "submitted_for_qc" },
  ];

  useEffect(() => {
    if (userData) {
      const expiry = localStorage.getItem("expires_at");
      const now = new Date().getTime() / 1000;
      if (now > expiry) {
        alert("Session timed Out");
        localStorage.clear();
        history.push("/login");
      }
    } else {
      alert("Login to Continue");
      history.push("/login");
    }
  }, []);

  const [filter, setFilter] = useState(null);
  const [filter2, setFilter2] = useState(null);
  const [filter3, setFilter3] = useState(null);
  const [filter4, setFilter4] = useState(null)
  const [filter5, setFilter5] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [Option, setOption] = useState("pixelID");
  const PAGE_SIZE = 20;
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);

  const handleSearchTermChange = (event) => {
    setSearchTerm(event.target.value);
  };
  const handleFilterChange = (event) => {
    setFilter(event.target.value);
    setPage(1);
  };
  const handleFilter2Change = (event) => {
    setFilter2(event.target.value);
    setPage(1);
  };
  const handleFilter3Change = (event) => {
    setFilter3(event.target.value);
    setPage(1);
  };
  const handleFilter4Change = (event) => {
    setFilter4(event.target.value);
    setPage(1);
  };
  const handleFilter5Change = (event) => {
    setFilter5(event.target.value);
    setPage(1);
  }

  const fetchBrands = async() => {
    const {data:items} = await supabase.from("asset_data").select("productBrand, modellerName")
    const distinctBrands = [...new Set(items.map((item) => item.productBrand))]
    const distinctUsers = [...new Set(items.map((item) => item.modellerName))]
    setBrandList(distinctBrands)
    setModelerList(distinctUsers)
  }
  useEffect(() => {
    fetchBrands()
  },[])

  useEffect(() => {
    fetchData();
  }, [filter, filter2, filter3, filter4,filter5, searchTerm, page, Option]);

  const fetchData = async () => {
    try {
      let data;
      let query = supabase
        .from("asset_data")
        .select(
          "id, pixelID, difficultyLevel, modellerName, modelStatus, productPriority, productImage, productName, productBrand, project"
        )
        .eq("project", "Internal")
        .range((page - 1) * PAGE_SIZE, page * PAGE_SIZE - 1);

        let countQuery = supabase
        .from("asset_data")
        .select("count", { count: "exact" })
        .eq("project", "Internal");

      if (filter) {
        query = query.eq("modelStatus", filter);
        countQuery = countQuery.eq("modelStatus", filter);
      }
      if (filter2) {
        query = query.eq("productPriority", filter2);
        countQuery = countQuery.eq("productPriority", filter2);
      }
      if (filter3) {
        query = query.eq("difficultyLevel", filter3);
        countQuery = countQuery.eq("difficultyLevel", filter3);
      }
      if (filter4) {
        query = query.eq("productBrand", filter4);
        countQuery = countQuery.eq("productBrand", filter4);
      }
      if (filter5) {
        query = query.eq("modellerName", filter5);
        countQuery = countQuery.eq("modellerName", filter5);
      }
      if (searchTerm) {
        query = query.ilike(`${Option}`, `%${searchTerm}%`)
        countQuery = countQuery.ilike(`${Option}`, `%${searchTerm}%`);
      }

      data = await query;
      const totalCount = await countQuery.single();

      if (data.data.length > 0 && totalCount.data.count > 0) {
        setModels(data.data);
        const calculatedTotalPages = Math.ceil(totalCount.count / PAGE_SIZE);
        setTotalPages(calculatedTotalPages);
      } else if(data.data.length === 0 && totalCount.data.count === 0){
        setModels([])
        const calculatedTotalPages = Math.ceil(totalCount.count / PAGE_SIZE);
        setTotalPages(calculatedTotalPages);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  const handleOption = (event) => {
    setOption(event.target.value);
  };

  return (
    <>
      <div className="flex flex-row bg-neutral-900 xl:overflow-hidden lg:overflow-hidden md:overflow-hidden">
        <div className="flex flex-row 2xl:w-screen 2xl:h-screen xl:h-screen lg:w-screen lg:h-screen md:w-screen md:h-screen xl:w-screen">
          <Sidebar />
          <div className="w-screen h-screen overflow-auto">
            <Topbar />
            <div className="flex float-right pr-8">
              <img src={Search} alt="" />
              <div className="relative">
                <select
                  className="absolute right-2 top-1 rounded-sm focus:outline-none"
                  style={{ color: "black" }}
                  value={Option || "pixelID"}
                  onChange={handleOption}
                >
                  <option style={{ color: "black" }} value={"pixelID"}>
                    PixelID
                  </option>
                  <option style={{ color: "black" }} value={"productName"}>
                    Name
                  </option>
                </select>
                <input
                    className="focus:outline-none"
                  type="text"
                  value={searchTerm}
                  onChange={handleSearchTermChange}
                  placeholder="Search"
                  name="search"
                  id="search"
                  style={{
                    color: "white",
                    backgroundColor: "#343434",
                  }}
                />
              </div>
            </div>
            <div className="flex flex-row text-white pl-6 pb-3">
              <div className="flex flex-row 2xl:gap-8 xl:gap-6 lg:gap-6 md:gap-2 w-full">
                <div className="flex 2xl:w-1/6 w-1/6">
                  <div className="flex w-full">
                    <select
                      className="w-full rounded-sm bg-neutral-500 xl:text-lg lg:text-md font-semibold items-center tracking-wider text-white 
                    text-center bg-gradient-to-br from-neutral-700 to-neutral-500 bg-contain focus:outline-none"
                      style={{ color: "black" }}
                      value={filter || ""}
                      onChange={handleFilterChange}
                    >
                      <option style={{ color: "black" }} value={""}>
                        Status
                      </option>
                      {statuses.map((status) => (
                        <option
                          style={{ color: "black" }}
                          key={status.id}
                          value={status.name}
                        >
                          {status.name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="flex 2xl:w-1/6 w-1/6">
                  <div className="flex w-full">
                    <select
                      className="w-full rounded-sm bg-neutral-500 xl:text-lg lg:text-md font-semibold items-center tracking-wider text-white 
                    text-center bg-gradient-to-br from-neutral-700 to-neutral-500 bg-contain focus:outline-none"
                      style={{ color: "black" }}
                      value={filter2 || ""}
                      onChange={handleFilter2Change}
                    >
                      <option style={{ color: "black" }} value={""}>
                        Priority
                      </option>
                      {priorities.map((priority) => (
                        <option
                          style={{ color: "black" }}
                          key={priority.id}
                          value={priority.name}
                        >
                          {priority.name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="flex 2xl:w-1/6 w-1/6">
                  <div className="flex w-full">
                    <select
                      className="w-full rounded-sm bg-neutral-500 xl:text-lg lg:text-md font-semibold items-center tracking-wider text-white 
                    text-center bg-gradient-to-br from-neutral-700 to-neutral-500 bg-contain focus:outline-none"
                      style={{ color: "black" }}
                      value={filter3 || ""}
                      onChange={handleFilter3Change}
                    >
                      <option style={{ color: "black" }} value={""}>
                        Difficulty
                      </option>
                      {difficulties.map((difficulty) => (
                        <option
                          style={{ color: "black" }}
                          key={difficulty.id}
                          value={difficulty.name}
                        >
                          {difficulty.name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="flex 2xl:w-1/6 w-1/6">
                  <div className="flex w-full">
                    <select
                      className="w-full rounded-sm bg-neutral-500 xl:text-lg lg:text-md font-semibold items-center tracking-wider text-white 
                    text-center bg-gradient-to-br from-neutral-700 to-neutral-500 bg-contain focus:outline-none"
                      style={{ color: "black" }}
                      value={filter4 || ""}
                      onChange={handleFilter4Change}
                    >
                      <option style={{ color: "black" }} value={""}>
                        Brand
                      </option>
                      {brandList && brandList.map((brand,index) => (
                        <option value={brand} key={index} style={{color:"black"}}>
                          {brand}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="flex 2xl:w-1/6 w-1/6">
                  <div className="flex w-full">
                    <select
                      className="w-full rounded-sm bg-neutral-500 xl:text-lg lg:text-md font-semibold items-center tracking-wider text-white 
                    text-center bg-gradient-to-br from-neutral-700 to-neutral-500 bg-contain focus:outline-none"
                      style={{ color: "black" }}
                      value={filter5 || ""}
                      onChange={handleFilter5Change}
                    >
                      <option style={{ color: "black" }} value={""}>
                        Modeller
                      </option>
                      {modelerList && modelerList.map((modeler, index) => (
                        <option key={index} value={modeler} style={{color:'black'}}>
                          {modeler}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
            </div>
            {models &&
              models?.map((item) => {
                const len = item.pixelID.split("_").length;
                return (
                  <div className="pt-4 pl-6 pr-6 pb-6">
                    <Link
                    style={{ textDecoration: "none", color: "white" }}
                    to={`/myproduct/${item.id}`}
                  >
                    <div className="flex basis-1/2 bg-neutral-700 pr-6">
                      <div className="w-full flex flex-row items-center gap-6 pl-4">
                        <div className="flex w-[100px] h-[100px] relative -top-5">
                          <img src={item.productImage[0]} alt="" />
                        </div>
                        <div className="flex flex-col gap-4 text-ellipsis">
                          <div className="whitespace-nowrap overflow-hidden 2xl:w-[450px] xl:w-[200px] lg:w-[200px] md:w-[150px] text-ellipsis">
                            {item.productName}
                          </div>
                          <div className={style.productBrand}>
                            {item.productBrand}
                          </div>
                        </div>
                      </div>
                      <div className="w-full flex items-center">
                        <div className="w-full flex flex-row items-center 2xl:gap-20 xl:gap-8">
                          <div className="flex flex-row gap-2 w-full justify-center">
                            <div className="w-6 h-6 flex items-center justify-center">
                              <img
                                className="w-10 h-10"
                                src={`https://gurtwepkenhsrmlkypvw.supabase.co/storage/v1/object/public/profileImages/${item.difficultyLevel}.svg`}
                                alt=""
                              />
                            </div>
                            <div className={style.level}>
                              {item.difficultyLevel}
                            </div>
                          </div>
                          <div className="flex flex-row gap-2 w-full justify-center">
                            <div className="w-4 h-6 flex items-center justify-center">
                              <img
                                className="w-10 h-10"
                                src={`https://gurtwepkenhsrmlkypvw.supabase.co/storage/v1/object/public/profileImages/${item.productPriority}.svg`}
                                alt=""
                              />
                            </div>
                            <div className={style.prioritylevel}>
                              {item.productPriority}
                            </div>
                          </div>
                          <div className="flex flex-row gap-2 items-center whitespace-nowrap">
                            <div className={style.variantImg}>A</div>
                            <div className={style.varientType}>
                              {len < 4 ? "Unique Model" : "Variant Model"}
                            </div>
                          </div>
                          <div className={style.modalstatus}>
                            <Button
                              className={style.button}
                              style={{
                                backgroundColor: "tomato",
                                borderRadius: "50vh",
                              }}
                            >
                              {item.modelStatus}
                            </Button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Link>
                  </div>
                );
              })}
              {totalPages && totalPages > 1 ? (
              <div className="flex justify-center py-4">
                <button
                    className="bg-neutral-700 hover:bg-neutral-500 text-white flex justify-center items-center font-bold py-1 px-3 mx-2 rounded"
                    onClick={() => handlePageChange(page - 1)}
                    disabled={page === 1}
                >
                    Prev
                </button>
                <div className="flex justify-center items-center text-black bg-white border px-3 py-2 rounded-md font-bold">{page}&nbsp;/&nbsp;{totalPages}</div>
                <button
                    className="bg-neutral-700 hover:bg-neutral-500 flex justify-center items-center text-white font-bold py-1 px-3 mx-2 rounded"
                    onClick={() => handlePageChange(page + 1)}
                    disabled={models.length < PAGE_SIZE}
                >
                    Next
                </button>
              </div>
              ):(
                <div className="flex w-full text-white p-4 justify-center text-2xl">
                  No Products to display
                </div>
              )}
          </div>
        </div>
      </div>
    </>
  );
};

export default AdminModals;
