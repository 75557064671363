import React, {useState, useRef, useEffect} from "react";
import style from './forgotpassword.module.css'
import * as THREE from 'three';
import Logo from '../public/lottie/Logo.svg';
import { Button } from '@material-ui/core'
import BIRDS from 'vanta/dist/vanta.birds.min'
import GLOBE from 'vanta/dist/vanta.globe.min'
import { supabase } from "../client";
import { useHistory } from "react-router";

const ForgotPassword = () => { 
  const history = useHistory()
    const [email, setEmail] = useState();
    const [vantaEffect, setVantaEffect] = useState(0)
    const vantaRef = useRef(null)
    const [formValues, setFormValues] = useState({});
  
  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormValues((prevState) => ({ ...prevState, [name]: value }));
  };

    const Sendlink = (event) =>{
        event.preventDefault();

        // console.log(formValues["email"])
        supabase.auth.resetPasswordForEmail(formValues["email"]).then(()=>{
            alert("Password reset mail has been sent to your mail")
        })
    }

    useEffect(() => {
        supabase.auth.onAuthStateChange(async (event, session) => {
          if (event === "PASSWORD_RECOVERY") {
            const newPassword = prompt("What would you like your new password to be?");
            const { data, error } = await supabase.auth
              .updateUser({ password: newPassword })
     
            if (data) alert("Password updated successfully!")
            if (error) alert("There was an error updating your password.")
          }
        })
      },[]) 

    useEffect(() => {
        if(!vantaEffect){
          setVantaEffect(
            BIRDS({
              el: vantaRef.current,
              THREE: THREE,
              color1:0xE29525,
              color2: 0x964C55,
              backgroundColor: 0x171717,
              mouseControls: true,
              touchControls: true,
              gyroControls: false,
              scale: 1.0
            })
          )
        }
        return () => {
          if(vantaEffect) vantaEffect.destroy();
        }
      },[vantaEffect])
      
    return(
    <>
        <div className={style.App} id="App" ref={vantaRef}>
            <div>
                <img className={style.logo} style={{width:'175px', height:'125px'}} src={Logo} alt="" />
            </div>
            <div className={style.container}>
                <div className={style.heading}>Welcome to MX-3D Studio!</div>
                <div className={style.glasscontainer}>
                    <h2 style={{paddingTop:'20px', fontWeight:'400'}}>Forgot Password</h2>
                    <form className={style.form} onSubmit={Sendlink} >
                        <div className={style.email}>Email Id</div>
                        <div className={style.emailinput}>
                            <input
                            style={{height:'40px', width:'100%', borderRadius:'10px'}}
                            required
                            name="email"
                            value={email}
                            onChange={handleChange}
                            type="email"
                            placeholder="Enter your Email"
                            />
                        </div>
                        <br></br>
                        <Button 
                            type="submit"
                            style={{
                                width:'100%',
                                backgroundColor:'#A0CDDA',
                                borderRadius:'30px',
                                fontWeight:'700'
                            }}
                        >
                            Request Link
                        </Button>
                    </form>
                    <div className="hover:underline cursor-pointer text-md tracking-wide" onClick={() => history.push('/login')}>Continue to Login</div>
                </div>
            </div>
        </div>
    </>
    )
}

export default ForgotPassword