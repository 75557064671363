import React, { useState, useEffect } from "react";
import Sidebar from "../../layouts/sidebar";
import Topbar from "../../layouts/topbar";
import Search from "../../public/lottie/chevron_right.svg";
import { supabase } from "../../client";
import Pagination from "../../layouts/pagination";
import { v4 as uuidv4 } from "uuid";

const Texture_request = ({ history }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [count, setCount] = useState(null);
  const [textures, setTextures] = useState(null);
  const [show, setShow] = useState("NO REQUEST");
  const [temp, setTemp] = useState(null);
  const objectsPerPage = 9;
  const startIndex = (currentPage - 1) * objectsPerPage;
  const endIndex = startIndex + objectsPerPage;
  const totalPages = Math.ceil(count / objectsPerPage);
  const user = localStorage.getItem("user");
  const username = localStorage.getItem("username");
  //   console.log(startIndex, endIndex);
  const role = localStorage.getItem("role");
  useEffect(() => {
    if (user && role === "Modeler") {
      const expiry = localStorage.getItem("expires_at");
      const now = new Date().getTime() / 1000;
      if (now > expiry) {
        alert("Session timed Out");
        localStorage.clear();
        history.push("/login");
      }
    } else {
      alert("Login to continue");
      history.push("/login");
    }
  }, [history]);

  const fetchTextureRequests = async () => {
    const data = await supabase
      .from("textureRequests")
      .select("*")
      .eq("requestedBy", user)
      .range(0, endIndex - 1);
    setTextures(data.data);
  };
  // console.log(textures);
  const handleRequest = async () => {
    setShow("REQUEST");
  };
  const handlePageChange = async (pageNumber) => {
    setCurrentPage(pageNumber);
    const data = await supabase
      .from("textureRequests")
      .select("*")
      .eq("requestedBy", user)
      .range(
        (pageNumber - 1) * objectsPerPage,
        pageNumber * objectsPerPage - 1
      );
    setTextures(data.data);
  };
  // console.log(show);
  const [artists, setArtists] = useState([]);
  useEffect(() => {
    (async () => {
      const count = await supabase
        .from("textureRequests")
        .select("*", { count: "exact" });
      setCount(count.count);
      await fetchTextureRequests();

      const artist = await supabase
        .from("users")
        .select("id,name")
        // .eq("role", "admin");
      setArtists(artist.data);
    })();
  }, []);
  // console.log(artists);

  function getNameById(id) {
    const artist = artists.find((artist) => artist.id === id);
    return artist ? artist.name : "no name";
  }
  const [imageUrls, setImageUrls] = useState([]);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [description, setDescription] = useState("");
  const handleDescriptionChange = (event) => {
    setDescription(event.target.value);
  };
  const [name, setName] = useState("");
  const handleNameChange = (event) => {
    setName(event.target.value);
  };
  const [material, setMaterial] = useState("");
  const handleMaterialChange = (event) => {
    setMaterial(event.target.value);
  };
  const [comment, setComment] = useState("");
  const handleCommentChange = (event) => {
    setComment(event.target.value);
  };
  const [chat, setChat] = useState(null);
  const handleFileChange = (event) => {
    const files = event.target.files;
    const urls = [];
    const files2 = Array.from(event.target.files);
    setSelectedFiles(files2);
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      const url = URL.createObjectURL(file);
      urls.push(url);
    }

    setImageUrls(urls);
  };
  // console.log(imageUrls)
  const handleUpload = async () => {
    const formData = new FormData();

    selectedFiles.forEach((file) => {
      formData.append("file", file); //,file.name);
      // console.log(file)
    });
    formData.append("name", name);

    const response = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/requestasset`,
      {
        method: "POST",
        body: formData,
      }
    );
    const result = await response.json();
    // console.log(result["filenames"])
    if (result["message"] === "Model uploaded successfully") {
      await supabase.from("textureRequests").insert({
        id: uuidv4(),
        description: description,
        name: name,
        reffImages: result["filenames"],
        requestedBy: user,
        status: "requested",
        material: material,
      });
      alert("Request submitted successfully");
      window.location.reload(false);
    }
  };
  const reset = () => {
    setImageUrls([]);
    setName("");
    setDescription("");
    setMaterial("");
    setComment("");
    setShow("NO REQUEST")
  };

  const sendComments = async (id) => {
    const { data, error } = await supabase
      .from("textureRequests")
      .select("chat")
      .eq("id", id);
    if (data) {
      const prev = data[0].chat || [];
      const next = {
        [username]: comment,
      };
      const update = [...prev, next];
      await supabase
        .from("textureRequests")
        .update({
          chat: update,
        })
        .eq("id", id);
      setComment("");
      setChat(update);
    }
  };
  const downloadfile = async (id) => {
    const data = await supabase
      .from("textureAssets")
      .select("textureLink")
      .eq("id", id);
    const link = data.data[0].textureLink;
    const key = link.replace(
      `https://${process.env.REACT_APP_3D_BUCKET}.s3.ap-south-1.amazonaws.com/`,
      ""
    );
    const response = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/assetdownload`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ s3key: key }),
      }
    );
    const result = await response.json();
    window.open(result["model_link"], "_blank");
  };

  const requestChange = async (textureID) => {
    await supabase
      .from("textureRequests")
      .update({ status: "update" })
      .eq("textureID", textureID);
    alert("Update request Submitted");
  };
  const details = async (texture) => {
    setShow("NO REQUEST");
    setTemp(texture);
    setChat(texture.chat);
    setComment("");
  };
  const [assetlink, setAssetlink] = useState(null);
  const textureImages = async (id) => {
    setAssetlink(null);
    const img = await supabase
      .from("textureAssets")
      .select("textureImage")
      .eq("id", temp.textureID);
    setAssetlink(img.data[0].textureImage[0]);
  };
  useEffect(() => {
    textureImages(temp);
  }, [temp]);
  return (
    <div className="flex flex-row bg-neutral-900 xl:overflow-hidden lg:overflow-hidden md:overflow-hidden ">
      <div className="flex flex-row 2xl:w-screen 2xl:h-screen xl:h-screen lg:w-screen lg:h-screen md:w-screen md:h-screen xl:w-screen">
        <Sidebar />
        <div className="w-screen h-screen overflow-auto scrollbar-hide">
          <Topbar />
          <div className="flex flex-row gap-2">
            <div className="flex flex-col gap-4 w-full">
              <div className="flex flex-row px-2">
                <div className="flex pr-4 w-4/6">
                <div className="flex rounded-md bg-gradient-to-r from-[#3dc1fb] via-[#ff00f5] to-[#0feffd] pb-0.5">
                  <input
                    className="focus:outline-none"
                    type="search"
                    placeholder="Search"
                    name="search"
                    id="search"
                    style={{
                      color: "white",
                      backgroundColor: "#343434",
                    }}
                  />
                  </div>
                  <img src={Search} alt="" />
                </div>
                {show === "NO REQUEST" ? (
                  <div className="flex px-2 w-2/6 justify-end text-white">
                    <button
                      onClick={() => handleRequest()}
                      className="bg-neutral-700 px-4 rounded-md"
                    >
                      Request +
                    </button>
                  </div>
                ) : null}
              </div>
              <div className="flex flex-row w-full gap-3 pl-2 pr-4">
                <div className="flex pt-4 w-full text-white text-md bg-neutral-800 px-4 rounded-md">
                  <div className="w-full h-max grid 2xl:grid-flow-row 2xl:grid-cols-2 2xl:gap-x-10 2xl:gap-y-5 xl:grid-flow-row xl:grid-cols-1 lg:gap-y-8 lg:grid-cols-1 lg:grid-flow-row lg:gap-10 md:grid-flow-row md:grid-cols-2 md:gap-x-16 md:gap-y-10">
                    {textures?.map((texture, index) => (
                      <div
                        key={index}
                        className="w-full h-max flex flex-row items-center bg-neutral-500 rounded-md"
                      >
                        <div className="w-3/4 flex">
                          <div
                            onClick={() => details(texture)}
                            className="flex w-full pl-4 py-1 rounded-md object-contain"
                          >
                            <img
                              className="flex w-40 h-36 hover:scale-90 rounded-md"
                              src={texture.reffImages[0]}
                              alt=""
                            />
                          </div>
                          <div className="flex w-full items-center justify-center">
                            <div className="flex flex-col gap-2">
                              <div className="text-lg w-full items-center flex justify-center capitalize tracking-wide">
                                {texture.name}
                              </div>
                              <div className="flex px-3 py-1 items-center justify-center">
                                {texture.textureArtistID !== null
                                ? getNameById(texture.textureArtistID)
                                : "Not assigned"}
                              </div>
                              <div className="bg-doneBg flex px-3 py-1 rounded-full justify-center items-center text-sm">
                                {texture.status.toUpperCase()}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
                {show === "NO REQUEST" ? (
                  <div className="flex">
                    <div className="w-96 h-max pb-8 bg-neutral-800 text-white rounded-md">
                      <div className="flex flex-col gap-4 pr-2">
                        {assetlink ? (
                          <div className="flex justify-center pt-4 items-center">
                            <img
                              className="rounded-md"
                              src={assetlink}
                              style={{ width: "200px", height: "200px" }}
                              alt=""
                            />
                          </div>
                        ) : (
                          ""
                        )}
                        <div className="flex w-full overflow-auto justify-center pt-4 items-center">
                          <div className="flex flex-row gap-4 px-2 py-1">
                          {temp?.reffImages.map((images, index) => (
                              <img
                              key={index}
                              className="rounded-md"
                              src={images}
                              style={{ width: "100px", height: "100px" }}
                              alt=""
                            />
                            ))}
                            </div>
                        </div>
                        <div className="flex flex-row gap-8 items-center justify-center">
                          <div className="flex flex-col items-center gap-1">
                            <div>Submit Date :</div>
                            <div className="flex bg-white text-black px-2 items-center justify-center">
                              {temp?.created_at
                                ? temp?.created_at.slice(0, 10)
                                : ""}
                            </div>
                          </div>
                          <div className="flex flex-col items-center gap-1">
                            <div>Finish Date :</div>
                            <div className="flex bg-white text-black px-2 items-center justify-center">
                              {temp?.completed_at
                                ? temp?.completed_at.slice(0, 10)
                                : ""}
                            </div>
                          </div>
                        </div>
                        <div className="flex flex-col text-lg gap-6 pl-4">
                          <div className="flex flex-col gap-1 tracking-wider">
                            <div className="font-thin">Name :</div>
                            <div className="text-xl">{temp?.name}</div>
                          </div>
                          <div className="flex flex-col gap-1 tracking-wider">
                            <div className="font-thin">Tags :</div>
                            <div className="text-xl">{temp?.material}</div>
                          </div>
                          <div className="flex flex-row items-center gap-4">
                            {temp?.textureID ? (
                              <>
                                <button
                                  className="flex px-4 py-1 font-semibold rounded-full bg-downloadBg text-black"
                                  onClick={() => downloadfile(temp?.textureID)}
                                >
                                  Download
                                </button>
                                <button
                                  className="flex px-4 py-1 font-semibold rounded-full bg-requestChange text-black"
                                  onClick={() => requestChange(temp?.textureID)}
                                >
                                  Request Change
                                </button>
                              </>
                            ) : (
                              ""
                            )}
                          </div>
                          <div className="flex flex-col gap-4">
                            <div className="flex flex-col gap-1 text-white">
                              <div>Comment :</div>
                              <div className="flex flex-row relative gap-1">
                                <input
                                  className="text-black w-full bg-zinc-700 py-3"
                                  placeholder="Add a Comment"
                                  onChange={handleCommentChange}
                                  value={comment}
                                />
                                <button
                                  onClick={() => sendComments(temp?.id)}
                                  className="absolute inset-y-0 right-0 font-semibold text-black px-4 flex items-center pr-2"
                                >
                                  Send
                                </button>
                              </div>
                            </div>
                            <div className="flex flex-col h-[200px] overflow-auto ">
                              {chat && chat.length > 0
                                ? chat.map((ct) => (
                                    <>
                                      <div className="flex flex-row gap-2 items-center font-thin text-md">
                                        <div>
                                          <img
                                            className="w-10 h-10"
                                            src=""
                                            alt=""
                                          />
                                        </div>
                                        <div className="flex flex-col gap-1">
                                          <div className="flex flex-row gap-2">
                                            <div>{Object.keys(ct)}</div>
                                            <div>5 hours ago</div>
                                          </div>
                                          <div className="flex flex-row gap-2">
                                            <div className="bg-neutral-700 px-2 rounded-full">
                                              @nelson
                                            </div>
                                            <div>{ct[Object.keys(ct)]}</div>
                                          </div>
                                        </div>
                                      </div>
                                    </>
                                  ))
                                : ""}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="flex">
                    <div className="w-96 h-max pb-8 bg-neutral-800 text-white rounded-md">
                      <div className="flex flex-col gap-8 pr-2">
                        <div className="flex flex-col pl-4 text-lg gap-3">
                          <div>Name :</div>
                          <input
                            className="text-black w-full bg-white py-3"
                            onChange={handleNameChange}
                            value={name}
                          />
                          <div>Material :</div>
                          <input
                            className="text-black w-full bg-white py-3"
                            onChange={handleMaterialChange}
                            value={material}
                          />
                          <div className="flex flex-col gap-2 text-lg">
                            <div>Description :</div>
                            <textarea
                              className="bg-white text-black h-16 pl-1 w-full"
                              onChange={handleDescriptionChange}
                              type="text"
                              value={description}
                              placeholder="Add a Description"
                            />
                          </div>
                          <div className="flex pt-4">
                            <input
                              type="file"
                              id="file"
                              hidden
                              multiple
                              onChange={handleFileChange}
                            />
                            <label
                              className="underline bg-neutral-600 px-4 text-lg rounded-md cursor-pointer"
                              for="file"
                            >
                              Attach
                            </label>
                          </div>
                          <div>Attachments :</div>
                          <div className="flex fex-row gap-2">
                            {imageUrls?.map((url, index) => (
                              <img
                                key={index}
                                className="w-28 h-28"
                                src={url}
                                alt=""
                              />
                            ))}
                          </div>
                          <div className="flex flex-row items-center gap-8 justify-center">
                            <button
                              className="bg-neutral-700 px-4 rounded-md text-lg"
                              onClick={() => reset()}
                            >
                              Cancel
                            </button>
                            <button
                              className="bg-assignBorder text-black px-4 rounded-md text-lg"
                              onClick={() => handleUpload()}
                            >
                              Send
                            </button>
                          </div>
                        </div>
                        {/* <div className="flex flex-col text-lg gap-10 pl-4">
                          <div className="flex flex-col gap-4">
                            <div className="flex text-white">Comment :</div>
                            <div className="flex flex-row relative gap-1">
                              <input
                                className="text-black w-full bg-zinc-700 py-3"
                                placeholder="Add a Comment"
                              />
                              <button className="absolute inset-y-0 right-0 font-semibold text-black px-4 flex items-center pr-2">
                                Send
                              </button>
                            </div>
                            <div className="flex flex-row gap-2 items-center font-thin text-md">
                              <div>
                                <img className="w-10 h-10" src="" alt="" />
                              </div>
                              <div className="flex flex-col gap-1">
                                <div className="flex flex-row gap-2">
                                  <div>JohnDoe</div>
                                  <div>5 hours ago</div>
                                </div>
                                <div className="flex flex-row gap-2">
                                  <div className="bg-neutral-700 px-2 rounded-full">
                                    @nelson
                                  </div>
                                  <div>Hii</div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div> */}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={handlePageChange}
        />
        </div>
      </div>
    </div>
  );
};

export default Texture_request;
