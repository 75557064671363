import React, { useEffect, useState } from "react";
import Sidebar from "../layouts/sidebar";
import Topbar from "../layouts/topbar";
import style from "./roomView.module.css";
import Search from "../public/lottie/chevron_right.svg";
import { Button } from "@material-ui/core";
import { supabase } from "../client";
import { Link, useHistory } from "react-router-dom";

const MyRooms = ({ history }) => {
  const [moodboards, setMoodboards] = useState();
  const userID = localStorage.getItem("user");
  //   console.log(userID)
  const boards = async () => {
    const data = await supabase
      .from("moodboards")
      .select("*")
      .eq("modellerid", userID);
    setMoodboards(data.data);
  };
  const currentTime = new Date().getTime() / 1000;

  useEffect(() => {
    if (userID) {
      const expiry = localStorage.getItem("expires_at");
      if (currentTime < expiry) {
        boards();
      } else {
        alert("Session timed Out");
        localStorage.clear();
        history.push("/login");
      }
    } else {
      alert("Login to Continue");
      history.push("/login");
    }
  }, []);
  //   console.log(moodboards);

  return (
    <div className="flex flex-row bg-neutral-900 xl:overflow-hidden lg:overflow-hidden md:overflow-hidden">
      <div className="flex flex-row 2xl:w-screen 2xl:h-screen xl:h-screen lg:w-screen lg:h-screen md:w-screen md:h-screen sm:w-screen sm:h-screen xl:w-screen">
        <Sidebar />
        <div className="w-screen h-screen overflow-auto">
          <Topbar />
          <div className="flex float-right pr-8 pt-2">
            <div className="flex w-full rounded-md bg-gradient-to-r from-[#3dc1fb] via-[#ff00f5] to-[#0feffd] pb-0.5">
              <input
                type="search"
                placeholder="Search"
                name="search"
                id="search"
                style={{
                  color: "white",
                  backgroundColor: "#343434",
                }}
                className="focus:outline-none"
                />
              </div>
            <img src={Search} alt="" />
          </div>
          <div className="py-16 pl-6">
            <div className="w-full h-auto grid 2xl:grid-flow-row 2xl:grid-cols-4 xl:gap-16 2xl:gap-x-20 2xl:gap-y-20 xl:grid-flow-row xl:grid-cols-3 xl:gap-x-8 xl:gap-y-14 md:grid-cols-3 md:gap-x-16 md:gap-y-12 sm:grid-cols-2 sm:gap-x-16 sm:gap-y-12 pt-5">
              {moodboards &&
                moodboards.map((mbds, index) => (
                  <div
                    key={index}
                    className="2xl:w-[325px] xl:w-[325px] lg:w-[250px] md:w-[250px] sm:w-[325px] h-auto flex flex-col text-white bg-neutral-800 pb-2 rounded-md"
                  >
                    <div className="flex flex-col gap-0.5 w-full">
                      <Link
                        to={`/mystudio/roomview/${mbds.id}`}
                        target="_blank"
                      >
                        <div className="flex flex-row h-[220px] bg-white relative rounded-md">
                          <img className="flex w-full rounded-t-md bg-white" src={mbds.previewurl} alt="" />
                        </div>
                      </Link>
                      {mbds.name && mbds.name !== "" ? (
                      <div className="w-full relative whitespace-nowrap overflow-hidden text-ellipsis text-lg px-2">
                        {mbds.name}
                      </div>
                      ) : (
                        <div className="flex text-left relative text-lg px-2">Undefined</div>
                      )}
                      {mbds.eliyaid && mbds.eliyaid !== "" ? (
                      <div className="flex text-left relative text-base text-[#f69eaa] px-2">
                        {mbds.eliyaid}
                      </div>
                      ):(
                        <div className="flex text-left relative text-[#f69eaa] text-base px-2">Undefined</div>
                      )}
                    </div>
                    {/* <Button
                      style={{
                        backgroundColor: "#F2D399",
                        borderRadius: "20px",
                        width: "70%",
                        fontWeight: "700",
                      }}
                    >
                      {mbds.roomstatus}
                    </Button> */}
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MyRooms;
